<template>
    <div>
    <alertMessage :DialogMsg="alertMessage.alertMessageShow" :DialogImgColor="alertMessage.alertImgColor" :DialogImg="alertMessage.alertImg" :DialogHeaderText="alertMessage.alertHeaderText" :DialogText="alertMessage.alertText"></alertMessage>
    <v-dialog v-model="dialog" persistent  :max-width="maxWidth">
        <v-card>
            <v-card-title>
                <span class="headline" style="color:#1976d2">{{ formTitle + ' для ' + this.$store.getters.UserSettingsName }}</span>
            </v-card-title>
            <v-alert
                    v-model="alertDialog"
                    color="error"
                    icon="warning"
                    outlined
                    dismissible
            >
                {{ alertText }}
            </v-alert>
            <hr />
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout row wrap >
                        <v-flex>
                            <br>
                        <v-combobox class="pa-0 pr-2 ma-0"
                            v-model="selectedItemProduct"
                            :items="ProductReturn"
                            item-text="name"
                            item-value="id"
                            dense
                            label="Выберите продукт"
                            :loading = "loadingF"
                            :value = "selectedItemProduct"
                        ></v-combobox>
                        </v-flex>
                        <v-flex :class="field.sizeField" sm v-for="(field, index) in dialogFields" :key="index" >
                            <v-menu class="pr-2 ma-0"
                                v-if="drawComponent && field.typeField=='date1' && field.dialog.indexOf(formTitle)!=-1"
                                v-model="menu2"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="computedDateFormatted"
                                    :label="field.label"
                                    persistent-hint
                                    prepend-icon="event"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker :first-day-of-week="1" locale="ru-ru" v-model="dateStart" no-title @input="menu2 = false"></v-date-picker>
                            </v-menu>
                            <v-menu class="pr-2 ma-0"
                                v-if="drawComponent && field.typeField=='date2' && field.dialog.indexOf(formTitle)!=-1"
                                v-model="menu3"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="computedDateFormatted1"
                                    :label="field.label"
                                    persistent-hint
                                    prepend-icon="event"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker :first-day-of-week="1" locale="ru-ru" v-model="dateEnd" no-title @input="menu3 = false"></v-date-picker>
                            </v-menu>
                            <v-text-field :ref="editedItem[field.model]" :rules="field.rulesField" :counter="field.counterField" :mask="field.maskField"
                                          :readonly="field.readonly" v-model="editedItem[field.model]" :label="field.label" v-if="drawComponent && field.typeField=='text' &&field.dialog.indexOf(formTitle)!=-1"></v-text-field>
                            <v-text-field :readonly="field.readonly" v-model="editedItem[field.model]" :label="field.label" type="password" v-if="field.typeField=='pass' && field.dialog.indexOf(formTitle)!=-1"></v-text-field>
                            <v-text-field :readonly="field.readonly" v-model="editedItem[field.model]" :label="field.label" :rules="field.rulesField"  :counter="field.counterField" :mask="field.maskField" type="number" v-if="field.typeField=='number' && field.dialog.indexOf(formTitle)!=-1"></v-text-field>
                            <v-text-field :readonly="field.readonly" v-model="editedItem[field.model]" :label="field.label" :rules="emailRules"  :counter="field.counterField" :mask="field.maskField" type="text" v-if="field.typeField=='email' && field.dialog.indexOf(formTitle)!=-1"></v-text-field>
                            <v-checkbox color="info" :label="field.label" v-model="boolDateEndFull" v-if="field.typeField=='check' && field.dialog.indexOf(formTitle)!=-1"></v-checkbox>
                            <dadata :FullAddressIN = "addressSelected" v-if="drawComponent && field.typeField=='dadata' && field.dialog.indexOf(formTitle)!=-1"></dadata>
                            <bnkseek :BnkId="bnkID" v-if="drawComponent && field.typeField=='bnkseek' && field.dialog.indexOf(formTitle)!=-1"></bnkseek>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small dark color="primary" @click.native="close">Отмена</v-btn>
                <v-btn small dark color="primary" @click.native="save">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-alert
            v-model="alert"
            color="error"
            icon="priority_high"
            outlined
            dismissible
    >
        {{ alertText }}
    </v-alert>

    <v-dialog v-model="dialogDel" persistent  max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline" style="color:#1976d2">Удаление</span>
            </v-card-title>
            <v-alert
                    v-model="alertDialog"
                    color="error"
                    icon="warning"
                    outlined
                    dismissible
            >
                {{ alertText }}
            </v-alert>
            <hr />
            <v-card-text>
                <span >Удалить запись - {{ typeof selected !== "undefined" ? selected.strName + ', ' + selected.numPrice : '' }}?</span>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click.native="close">Отмена</v-btn>
                <v-btn small color="primary" @click.native="doDelete">Удалить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
              
    <v-container class="mainGridConteiner" :style="maxGridWidth">
        <v-layout justify-center >
            <v-card class="pa-3">
            <dx-data-grid :ref="gridRefName"
                            id="gridContainer"
                            :data-source="DataReturn"
                            :show-borders="true"
                            :hover-state-enabled="true"
                            :allow-column-reordering="allowColumnReordering"
                            :allow-column-resizing="allowColumnResizing"
                            :column-auto-width="columnAutoWidth"
                            :column-hiding-enabled="columnHidingEnabled"
                            @toolbar-preparing="onToolbarPreparing($event)"
                            key="id"
                            @selection-changed="onSelectionChanged"
                >

                <dx-paging :page-size="pageSize"/>
                <dx-pager
                    :show-page-size-selector="showPageSizeSelector"
                    :allowed-page-sizes="allowedPageSizes"
                    :show-info="true"
                />

                <dx-selection mode="single" />
                <dx-group-panel :visible="groupPanelVisible" />
                <dx-load-panel :enabled="false" :visible="false" />
                <dx-filter-row :visible="filterRowVisible" />
                <dx-header-filter :visible="headerFilterVisible" />
                <dx-search-panel :visible="searchVisible" :width="searchWidth" placeholder="Поиск..." />

                <dx-column v-for="(col, index) in columnSettingsIN" :key="index+'-column'" :width="col.colWidth" :data-field="col.colDataField" :caption="col.colCaption" :sort-order="col.sortOrder" :visible.sync="col.visible" :data-type="col.datatype" :format="col.format"/>
                

                <dx-summary v-if="summaryVisible" >
                    <dx-group-item v-for="(group, indx) in groupSummarySettingsIN" :key="indx + '-group'"
                                    :show-in-group-footer="group.showInGroupFooter"
                                    :align-by-column="group.alignByColumn"
                                    :column="group.columnGroup"
                                    :summary-type="group.summaryType"
                                    :display-format="group.displayFormat"
                                    :value-format="group.valueFormat"
                    />
                </dx-summary>
                <dx-sort-by-group-summary-info summary-item="count" />
                <div
                                slot="vProgressVuetify"
                                
                        >
                            <div>
                                <p style="margin: 0 4.5em 0em 0em;"></p>
                                <v-progress-circular
                                  :size="40"
                                  color="indigo"
                                  indeterminate
                                  v-if="loadingF"
                                ></v-progress-circular>
                            </div>
                        </div>
                        <div
                                slot="titleGroupVuetify"
                                
                        >
                            <div>
                                <v-chip outlined color="primary">
                                    <v-icon left>{{headerIcon}}</v-icon>{{headerText}}
                                </v-chip>
                                <div>
                                <p style="padding: 0 15em 0em 0;"></p>
                                </div>
                            </div>
                        </div>
                        <div
                                slot="GroupButtonVuetify"
                                
                        >
                            <div>
                                    <v-tooltip bottom class="pa-4">
                                         <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="refreshData()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="btnRefresh"
                                                    v-on="on"
                                                    
                                            >
                                                <v-icon>refresh</v-icon>
                                            </v-btn>
                                         </template>
                                         <span>Обновить</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="addItem()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="BtnAdd"
                                                    v-on="on"
                                                    class="mx-2"
                                            >
                                                <v-icon>add</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Добавить</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="editItem()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="BtnEdit"
                                                    v-on="on"
                                                    
                                            >
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Редактировать</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="deleteItem()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="BtnDelete"
                                                    v-on="on"
                                                    class="mx-2"
                                            >
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Удалить</span>
                                    </v-tooltip>
                                    
                            </div>
                        </div>
            </dx-data-grid >
             </v-card>
        </v-layout>
    </v-container>
</div>
</template>

<script>
    import Vue from 'vue'
    //import axios from 'axios'
    
    import Vuetify from 'vuetify'
    import 'material-design-icons-iconfont/dist/material-design-icons.css'
    import 'vuetify/dist/vuetify.min.css'
    import ru from 'vuetify/es5/locale/ru'
    import alertMessage from "@/components/alertMessage.vue"
    import moment from 'moment';

    Vue.use(Vuetify, {
        lang: {
            locales: { 'ru': ru },
            current: 'ru'
        }
    })

   import {
    DxDataGrid,
    DxColumn,
    DxSelection,
    DxSummary,
    DxGroupItem,
    DxGroupPanel,
    DxHeaderFilter,
    DxSearchPanel,
    DxSortByGroupSummaryInfo,
    DxFilterRow,
    DxLoadPanel,
    DxPaging,
    DxPager
} from 'devextreme-vue/data-grid';


import 'devextreme/dist/css/dx.common.css';


    export default {
        name: "sample",
        components: {
        DxDataGrid,
        DxColumn,
        DxSelection,
        DxSummary,
        DxGroupItem,
        DxGroupPanel,
        DxHeaderFilter,
        DxSortByGroupSummaryInfo,
        DxSearchPanel,
        DxFilterRow,
        DxLoadPanel,
        DxPaging,
        DxPager,
        alertMessage
    },
    props: {
        GridRefName: {
            default () {
                return 'dataGrid'
            }
        },
        AllowColumnReordering: {
            default () {
                return true
            }
        },
        AllowColumnResizing: {
            default () {
                return true
            }
        },
        ColumnAutoWidth: {
            default () {
                return true
            }
        },
        ColumnHidingEnabled: {
            default () {
                return true
            }
        },
        PageSize: {
            default () {
                return 5
            }
        },
        ShowPageSizeSelector: {
            default () {
                return false
            }
        },
        AllowedPageSizes: {
            default () {
                return [ 5 ]
            }
        },
        HeaderText: {
            default () {
                return 'Вам нужно передать заголовок через Props'
            }
        },
        HeaderIcon: {
            default () {
                return ''
            }
        },
        ColumnSettingsIN: {
            default () {
                return []
            }
        },
        SummaryVisible: {
            default () {
                return false
            }
        },
        GroupSummarySettingsIN: {
            default () {
                return []
            }
        },
        GroupPanelVisible: {
            default () {
                return false
            }
        },
        FilterRowVisible: {
            default () {
                return false
            }
        },
        HeaderFilterVisible: {
            default () {
                return false
            }
        },
        SearchVisible: {
            default () {
                return false
            }
        },
        SearchWidth: {
            default () {
                return 300
            }
        },
        BtnRefresh: {
            default () {
                return false
            }
        },
        BtnAdd: {
            default () {
                return false
            }
        },
        BtnEdit: {
            default () {
                return false
            }
        },
        BtnDelete: {
            default () {
                return false
            }
        },
        BtnShipper: {
            default () {
                return false
            }
        },
        MaxWidth: {
            default () {
                return "600px"
            }
        },
        MaxGridWidth: {
            default () {
                return ""
            }
        },
        EditedItem: {
            default () {
                return [{
                    id: ''
                }]
            }
        },
        DefaultItem: {
            default () {
                return [{
                    id: ''
                }]
            }
        },
        EditedFields: {
            default () {
                return [{
                    model: ''
                }]
            }
        },
        AddFields: {
            default () {
                return [{
                    model: ''
                }]
            }
        },
        EndPoint: {
            default () {
                return ''
            }
        },
        ParentParams: {
            default () {
                return null
            }
        }
    },
        data() {
            return {
            selectedItemProduct: null,
            maxGridWidth: '',
            allowColumnReordering: this.AllowColumnReordering,
            allowColumnResizing: this.AllowColumnResizing,
            columnAutoWidth: this.ColumnAutoWidth,
            columnHidingEnabled: this.ColumnHidingEnabled,
            pageSize: this.PageSize,
            showPageSizeSelector: this.ShowPageSizeSelector,
            allowedPageSizes: this.AllowedPageSizes,
            drawComponent: true,
            gridRefName: this.GridRefName,
            expanded: true,
            alert: false,
            alertDialog: false,
            alertText: '',
            dialog: false,
            dialogDel: false,
            dialogSetShipper: false,
            headerText: this.HeaderText,
            headerIcon: this.HeaderIcon,
            columnSettingsIN: this.ColumnSettingsIN,
            summaryVisible: this.SummaryVisible,
            groupSummarySettingsIN: this.GroupSummarySettingsIN,
            groupPanelVisible: this.GroupPanelVisible,
            filterRowVisible: this.FilterRowVisible,
            headerFilterVisible: this.HeaderFilterVisible,
            searchVisible: this.SearchVisible,
            searchWidth: this.SearchWidth,
            btnRefresh: this.BtnRefresh,
            btnAdd: this.BtnAdd,
            btnEdit: this.BtnEdit,
            btnDelete: this.BtnDelete,
            btnShipper: this.BtnShipper,
            loading: false,
            selected: '',
            dataItems: '',
            editedIndex: -1,
            editedItem: {},
            defaultItem: {},
            editedFields: [],
            addFields: [],
            dialogFields: [],
            endPoint: '',
            parentParams: this.ParentParams,
            maxWidth: "",
            menu2: false,
            menu3: false,
            dateStart: '',
            dateEnd: '',
            dateStartSel: '',
            dateEndSel: '',
            boolDateEndFull: false,
            bnkID: '',
            addressSelected: '',
            alertMessage: {alertMessageShow: false, alertImgColor: "error", alertImg: "error_outline", alertHeaderText: 'Ошибка', alertText: 'Ошибка'},
            shipperMailShow: true,
            emailRules: [ 
                            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail введен не верно'
                        ]
        }
    },
    created() {

    },
    beforeMount() {
        this.gridRefName = this.GridRefName
        this.allowColumnReordering = this.AllowColumnReordering
        this.allowColumnResizing = this.AllowColumnResizing
        this.columnAutoWidth = this.ColumnAutoWidth
        this.columnHidingEnabled = this.ColumnHidingEnabled
        this.pageSize = this.PageSize
        this.showPageSizeSelector = this.ShowPageSizeSelector
        this.allowedPageSizes = this.AllowedPageSizes
        this.headerText = this.HeaderText
        this.headerIcon = this.HeaderIcon
        this.columnSettingsIN = this.ColumnSettingsIN
        this.groupSummarySettingsIN = this.GroupSummarySettingsIN
        this.groupPanelVisible = this.GroupPanelVisible
        this.filterRowVisible = this.FilterRowVisible
        this.headerFilterVisible = this.HeaderFilterVisible
        this.searchVisible = this.SearchVisible
        this.searchWidth = this.SearchWidth
        this.summaryVisible = this.SummaryVisible
        this.editedItem = this.EditedItem
        this.defaultItem = this.DefaultItem
        this.editedFields = this.EditedFields
        this.addFields = this.AddFields
        this.endPoint = this.EndPoint
        this.parentParams = this.ParentParams
        this.btnRefresh = this.BtnRefresh
        this.btnAdd = this.BtnAdd
        this.btnEdit = this.BtnEdit
        this.btnDelete = this.BtnDelete
        this.btnShipper = this.BtnShipper
        this.maxWidth = this.MaxWidth
        this.maxGridWidth = this.MaxGridWidth

        var d = new Date();
        
        //console.log(m)
        //var dateGet = tmpDate.getFullYear() + '-' + m0 + '-01'
        //console.log(dateGet)
        
        
        //console.log(this.dateStart + ' ' + this.dateEnd)
        //console.log(firstDay + ' - ' + lastDay)
        //console.log(this.$store.getters.dateStart )
        if(this.selected!=''){
            this.dateStart = moment(this.$store.getters.dateStart).utcOffset(0, true).format().substr(0,10)
        } else {
            this.dateStart = moment(d).utcOffset(0, true).format().substr(0,10)
        }
        if(this.selected!=''){
            //moment(this.dateStart).utcOffset(0, true).format().substr(0,10)
            this.dateEnd = moment(this.$store.getters.dateEnd).utcOffset(0, true).format().substr(0,10)
        } else {
            this.dateEnd = moment(d).utcOffset(0, true).format().substr(0,10)
        }
       
    },
    methods: {
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },
        reset: function() {
            var vm = this;
            vm.drawComponent = false;
            Vue.nextTick(function() {
                vm.drawComponent = true;
            });
        },
        doAlertMessage(Show, ImgColor, Img, HeaderText, MsgText) {
            this.alertMessage.alertMessageShow = Show
            if(ImgColor != ''){ this.alertMessage.alertImgColor = ImgColor }
            if(Img != ''){ this.alertMessage.alertImg = Img }
            if(HeaderText != ''){ this.alertMessage.alertHeaderText = HeaderText }
            if(MsgText != ''){ this.alertMessage.alertText = MsgText }
        },
        authUsersGet() {
            this.$store.dispatch('loadAuthUsers')
        },
        onToolbarPreparing(e) {
            e.toolbarOptions.items.unshift({
                    location: 'after',
                    template: 'vProgressVuetify'
                }, {
                location: 'after',
                template: 'GroupButtonVuetify'
                }, {
                location: 'before',
                template: 'titleGroupVuetify'
            });
        },
        refreshData(){
            this.$store.dispatch('loadProductSettings',this.$store.getters.UserSettingsId)
            //this.dataItems = this.$store.getters.settings
            
        },
        addItem(){
            //
            if(this.$store.getters.UserSettingsId!=null) {
                this.dialog=true
            }
            else
            {
                //console.log(this.alert)
                this.doAlertMessage(true,'','','Внимание','Нужно выбрать Предприятие к которому будет выполнена настройка процентов!')
                //this.alertText = 'Для редактирования, нужно выбрать запись в таблице.'
                //this.alert = true
            }
            
                   
        },
        onSelectionChanged({ selectedRowsData }) {
            this.selected = selectedRowsData[0]
            if(this.selected !== undefined){
                this.dateStartSel = moment(this.selected.dtDateStart).utcOffset(0, true).format().substr(0,10)
                this.dateEndSel = moment(this.selected.dtDateEnd).utcOffset(0, true).format().substr(0,10)
            }
            //console.log(this.selected)
            
        },
        close () {
            this.dialog = false
            this.dialogDel = false
            this.dialogChangePass = false
            this.alertDialog = false
            this.dialogSetShipper = false
            this.reset()
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            this.dialogFields = this.addFields
            this.menu2 = false
            this.menu3 = false
            //this.bnkID = 0
            //this.addressSelected = ''

        },
        deleteItem () {
            if(this.selected!='') {
                this.editedIndex = this.dataItems.indexOf(this.selected)
                this.dialogDel = true
            }
            else
            {
                //console.log(this.alert)
                this.doAlertMessage(true,'','','Внимание','Для удаления, нужно выбрать запись в таблице.')
                //this.alertText = 'Для удаления, нужно выбрать запись в таблице.'
                //this.alert = true
            }
            //confirm('Удалить запись - ' + this.selected.name + '?') && this.doDelete(index)
        },
        async doDelete(){
            var Info = this.selected.id
            await this.$store.dispatch('deleteProductSettings', Info)
                .then(async() => {
                    //console.log('OK')
                    this.dialogDel = false
                    this.alertDialog = false
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                    this.refreshData()
                    this.dialogFields = this.addFields
                    //this.refreshData()
                })
                .catch((error) => {
                    //console.log('Error')
                    this.errorRu = error.response.data
                    if(error.message == 'The email address is already in use by another account.')
                    {
                        this.errorRu = 'Этот Email уже используется для другого пользователя.'
                    }
                    this.errorMsg = this.errorRu
                    try {
                        this.doAlertMessage(true,'','','Ошибка',this.errorMsg)
                        //this.alertText = response.response.data.error
                    }
                    catch (e) {
                        this.doAlertMessage(true,'','','Ошибка',e.messageerror)
                        //this.alertText = e.messageerror
                    }
                }) 

        },
        editItem () {
            //console.log(this.selected)
            var ProductFilter = []
            if(this.selected!='') {
                //console.log(this.addressSelected)
                //this.$root.$emit('setSelectBnkID', this.selected.bank)
                this.editedIndex = this.DataReturn.indexOf(this.selected)
                this.editedItem = Object.assign({}, this.selected)
                //console.log(this.ProductReturn)
                this.dateStart = moment(this.editedItem.dtDateStart).utcOffset(0, true).format().substr(0,10)
                this.dateEnd = moment(this.editedItem.dtDateEnd).utcOffset(0, true).format().substr(0,10)
                if(this.editedItem.dtDateEnd == '2222-12-12'){
                    this.boolDateEndFull = true
                }
                ProductFilter = this.ProductReturn.find(o => o.name === this.editedItem.strName);
                //console.log(ProductFilter)
                this.selectedItemProduct = ProductFilter
                //console.log(this.editedItem)
                this.dialogFields = this.editedFields
                this.dateStart = moment(this.editedItem.dtDateStart).utcOffset(0, true).format().substr(0,10)
                this.dateEnd = moment(this.editedItem.dtDateEnd).utcOffset(0, true).format().substr(0,10)
                
                this.dialog = true
            }
            else
            {
                //console.log(this.alert)
                this.doAlertMessage(true,'','','Внимание','Для редактирования, нужно выбрать запись в таблице.')
                //this.alertText = 'Для редактирования, нужно выбрать запись в таблице.'
                //this.alert = true
            }
        },
        async save () {
           
            //console.log(this.editedItem)
            //console.log(this.selectedItemProduct)
            if (this.editedIndex > -1) {
                //console.log(this.editedItem)
                
                const setting = {
                    IDSettings: this.$store.getters.UserSettingsId,
                    strName: this.selectedItemProduct.name,
                    numPrice: this.editedItem.numPrice.replace(',','.'),
                    dtDateStart: this.dateStart,
                    dtDateEnd: this.dateEnd,
                    id: this.editedItem.id
                }
                //console.log(setting)
                await this.$store.dispatch('editProductSettings', setting)
                .then(() => {
                    //console.log('OK')
                    this.dialog = false
                    this.alertDialog = false
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                    this.selected=''
                    //this.reset()
                    this.dialogFields = this.addFields
                    this.menu2 = false
                    this.menu3 = false
                    //this.refreshData()
                })
                .catch((error) => {
                    //console.log('Error')
                    this.errorRu = error.response.data
                    if(error.message == 'The email address is already in use by another account.')
                    {
                        this.errorRu = 'Этот Email уже используется для другого пользователя.'
                    }
                    this.errorMsg = this.errorRu
                    try {
                        this.doAlertMessage(true,'','','Ошибка',this.errorMsg)
                        //this.alertText = response.response.data.error
                    }
                    catch (e) {
                        this.doAlertMessage(true,'','','Ошибка',e.messageerror)
                        //this.alertText = e.messageerror
                    }
                }) 
                
            } else {
        
                const setting = {
                    IDSettings: this.$store.getters.UserSettingsId,
                    strName: this.selectedItemProduct.name,
                    numPrice: this.editedItem.numPrice.replace(',','.'),
                    dtDateStart: this.dateStart,
                    dtDateEnd: this.dateEnd
                }
                //console.log(setting)
                
                await this.$store.dispatch('newProductSettings', setting)
                .then(() => {
                    //console.log('OK')
                    this.dialog = false
                    this.alertDialog = false
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                    this.selected=''
                    this.menu2 = false
                    this.menu3 = false
                    this.reset()
                    this.refreshData()
                })
                .catch((error) => {
                    //console.log('Error')
                    this.errorRu = error.response.data
                    if(error.message == 'The email address is already in use by another account.')
                    {
                        this.errorRu = 'Этот Email уже используется для другого пользователя.'
                    }
                    this.errorMsg = this.errorRu
                    try {
                        this.doAlertMessage(true,'','','Ошибка',this.errorMsg)
                        //this.alertText = response.response.data.error
                    }
                    catch (e) {
                        this.doAlertMessage(true,'','','Ошибка',e.messageerror)
                        //this.alertText = e.messageerror
                    }
                })
                        
                        
                
            } 
        }
    },
    mounted() {
        
        //this.dataItems = this.$store.getters.settings
        this.$root.$on('closeAlertMessage', (text) => {
            this.alertMessage.alertMessageShow = text
        })
        this.dialogFields = this.addFields
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Добавить': 'Редактировать'
        },
        DataReturn() {
            return this.$store.getters.productSettings
        },
        loadingF(){
            return this.$store.getters.loading2
        },
        computedDateFormatted () {
            //console.log(this.dateStart)
            //this.$store.commit('SetDateStart', this.dateStart)
            //console.log(this.$store.getters.dateStart)
            return this.formatDate(moment(this.dateStart).utcOffset(0, true).format().substr(0,10))
        },
        computedDateFormatted1 () {
            //this.$store.commit('SetDateEnd', this.dateEnd)
            return this.formatDate(moment(this.dateEnd).utcOffset(0, true).format().substr(0,10))
        },
        ProductReturn(){
            //console.log(this.$store.getters.productTypeList)
            return this.$store.getters.ProductList
        },
    },
    watch: {
        boolDateEndFull () {
            if(this.boolDateEndFull === true) {
                var d = new Date('2222-12-12');
                this.dateEnd =  moment(d).utcOffset(0, true).format().substr(0,10);
                this.reset()
            }
            if(this.boolDateEndFull === false) {
                var d1 = new Date();
                this.dateEnd =  moment(d1).utcOffset(0, true).format().substr(0,10);
                this.reset()
            }
            //console.log(this.dateEnd)
        }
    }
  }
</script>
<style>

</style>