export default class CardsLimit {
    constructor (
        id,
        card_id,
        group_id,
        contract_id,
        amount,
        amount_value,
        amount_used,
        amount_unit,
        productType,
        productGroup,
        sum,
        sum_value,
        sum_used,
        sum_currency,
        term,
        term_days,
        term_type,
        term_time,
        term_time_from,
        term_time_to,
        transactions,
        transactions_count,
        transactions_occured,
        time,
        time_number,
        time_type,
        date,
        day_about,
        position,
        period,
        value,
        used,
        rest
    ) {
      this.id = id
      this.card_id = card_id
      this.group_id = group_id
      this.contract_id = contract_id
      this.amount = amount
      this.amount_value = amount_value
      this.amount_used = amount_used
      this.amount_unit = amount_unit
      this.productType = productType
      this.productGroup = productGroup
      this.sum = sum
      this.sum_value = sum_value
      this.sum_used = sum_used
      this.sum_currency = sum_currency
      this.term = term
      this.term_days = term_days
      this.term_type = term_type
      this.term_time = term_time
      this.term_time_from = term_time_from
      this.term_time_to = term_time_to
      this.transactions = transactions
      this.transactions_count = transactions_count
      this.transactions_occured = transactions_occured
      this.time = time
      this.time_number = time_number
      this.time_type = time_type
      this.date = date
      this.day_about = day_about
      this.position = position
      this.period = period
      this.value = value
      this.used = used
      this.rest = rest
    }
  }