export default class UnitList {
    constructor (
        id,
        value,
        last_update,
        udt_flag,
        deleted
    ) {
      this.id = id
      this.value = value
      this.last_update = last_update
      this.udt_flag = udt_flag
      this.deleted = deleted
    }
  }