<template>
    <div>
    <v-container class="mainGridConteiner" :style="'max-width: 80%'">
        <v-layout justify-center >
            <v-card class="pa-3">
                <v-chip outlined color="primary">
                    <v-icon left>mdi-account-cog</v-icon>Управление пользователем
                </v-chip>
                <div>
                <p style="padding: 0 15em 0em 0;"></p>
                </div>
                <div>
                    <v-row class="pa-0 ma-0">
                        <v-col cols="12" sm="4" class="pa-0 ma-0">
                            <v-text-field class="pa-0 pr-2 ma-0"
                                label="Старый пароль"
                                clearable
                                v-model=oldPass
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                        <v-col cols="12" sm="4" class="pa-0 ma-0">
                            <v-text-field class="pa-0 pr-2 ma-0"
                                label="Новый пароль"
                                clearable
                                v-model=newPass
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                        <v-col cols="12" sm="4" class="pa-0 ma-0" >
                            <div class="my-2">
                                <v-btn small color="primary" @click="ChangePassword()">Изменить пароль</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                        <v-col cols="12" sm="12" class="pa-0 ma-0" >
                            <v-alert dense  text   type="success"  dismissible v-model="alert1" class="pa-1 ma-1">
                                Пароль изменен!
                            </v-alert>
                            <v-alert dense  text   type="warning"  dismissible v-model="alert2" class="pa-1 ma-1">
                                Пароль не изменен! Возможно некорректно введен старый пароль.
                            </v-alert>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
       </v-layout>
     </v-container>
    </div>
</template>

<script>
    //import axios from 'axios'
    
    import axios from 'axios'
    import crypto from 'crypto'

    export default {
        name: "catalogForm",
        data() {
            return {
                clientID: this.$store.getters.UserId,
                oldPass: '',
                newPass: '',
                alert1: false,
                alert2: false
            }
        },
        methods: {
            ChangePassword() {
                
                    let email = this.$store.getters.UserMail
                   

                    let dataTr = [
                        {"proc":'updateDictionary_userPasswordSettingsPG', "uid": this.$store.getters.userUID, "uid2": this.$store.getters.user.id, 
                        "un": email, "uop": crypto.createHash('md5').update(this.oldPass).digest("hex"), "unp": crypto.createHash('md5').update(this.newPass).digest("hex")}]
                    //console.log(dataTr)
                    axios({
                        method: 'POST',
                        headers: {
                            'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                            'Content-Type': 'application/json'
                        },
                        url: this.$store.state.url24sr,
                        //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                        data: dataTr

                    })
                    .then(() => {
                        //console.log(res)
                        // Send mutation
                        this.alert1 = true
                        this.alert2 = false
                        //resolve();
                    })
                    .catch(() => {
                        this.alert2 = true
                        this.alert1 = false
                        //reject(response)
                        //throw error
                    })
             
            }
            
        },
        mounted() {

        },
        components:{
            
        },
        computed: {
            
        }
    }
</script>


