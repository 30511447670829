<template>
<div>
    <v-container class="mainGridConteiner" :style="'max-width: 80%'" >
        <v-layout justify-center>
            
            <v-simple-table >
                <template v-slot:default>
                    <thead>
                        <th class="text-center subtitle-1 ">Поступления в текущем месяце</th>
                        <th class="text-center subtitle-1 ">Расход в текущем месяце</th>
                        <th class="text-center subtitle-1 ">Итог в текущем месяце</th>
                    </thead>
                    <tbody>
                    <tr>
                        <td class="text-center font-weight-bold headline blue-grey lighten-5">{{ MoneyP }}</td>
                        <td class="text-center font-weight-bold headline blue-grey lighten-5">{{ MoneyM }}</td>
                        <td class="text-center font-weight-bold headline blue-grey lighten-5">{{ Itogo }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
           
            <v-row  class="mb-6" no-gutters>
            </v-row>
            <v-row  class="mb-6" no-gutters>
            <v-col  sm="5" md="6">
                
            <DxPieChart
                v-if="showChart"
                id="pie"
                :data-source="DataReturn"
                type="doughnut"
                title="Количество в текущем месяце"
                palette="Soft Pastel"
            >
                <DxSeries argument-field="product" value-field="amount">
                <DxLabel
                    :visible="true"
                    format=",###,###,###,##0.00"
                >
                    <DxConnector :visible="true"/>
                </DxLabel>
                </DxSeries>
                <DxExport :enabled="true"/>
                <DxLegend
                :margin="0"
                horizontal-alignment="right"
                vertical-alignment="top"
                />
                <DxTooltip
                :enabled="true"
                :customize-tooltip="customizeTooltip"
                >
                
                </DxTooltip>
            </DxPieChart>
                <v-alert
                    class="pa-2 ma-2"
                    border="bottom"
                    colored-border
                    type="warning"
                    elevation="2"
                    v-if="showAlert"
                >
                    В текущем месяце нет данных для вывода графика...
                </v-alert>
            </v-col>
            <v-col sm="5" md="6">
            <DxPieChart
                v-if="showChart"
                id="pie"
                :data-source="DataReturn"
                type="doughnut"
                title="Сумма в текущем месяце"
                palette="Soft Pastel"
            >
                <DxSeries argument-field="product" value-field="discount_cost">
                <DxLabel
                    :visible="true"
                    format=",###,###,###,##0.00"
                >
                    <DxConnector :visible="true"/>
                </DxLabel>
                </DxSeries>
                <DxExport :enabled="true"/>
                <DxLegend
                :margin="0"
                horizontal-alignment="right"
                vertical-alignment="top"
                />
                <DxTooltip
                :enabled="true"
                :customize-tooltip="customizeTooltip"
                >
                
                </DxTooltip>
            </DxPieChart>
                <v-alert
                    class="pa-2 ma-2"
                    border="bottom"
                    colored-border
                    type="warning"
                    elevation="2"
                    v-if="showAlert"
                >
                    В текущем месяце нет данных для вывода графика...
                </v-alert>
            </v-col>
            </v-row>
            <v-row  class="mb-6" no-gutters>
            <v-col  sm="5" md="6">
                
            <DxPieChart
                v-if="showChartLast"
                id="pie"
                :data-source="DataReturnLast"
                type="doughnut"
                title="Количество в предыдущем месяце"
                palette="Soft Pastel"
            >
                <DxSeries argument-field="product" value-field="amount">
                <DxLabel
                    :visible="true"
                    format=",###,###,###,##0.00"
                >
                    <DxConnector :visible="true"/>
                </DxLabel>
                </DxSeries>
                <DxExport :enabled="true"/>
                <DxLegend
                :margin="0"
                horizontal-alignment="right"
                vertical-alignment="top"
                />
                <DxTooltip
                :enabled="true"
                :customize-tooltip="customizeTooltip"
                >
                
                </DxTooltip>
            </DxPieChart>
                <v-alert
                    class="pa-2 ma-2"
                    border="bottom"
                    colored-border
                    type="warning"
                    elevation="2"
                    v-if="showAlertLast"
                >
                    В предыдущем месяце нет данных для вывода графика...
                </v-alert>
            </v-col>
            <v-col sm="5" md="6">
            <DxPieChart
                v-if="showChartLast"
                id="pie"
                :data-source="DataReturnLast"
                type="doughnut"
                title="Сумма в предыдущем месяце"
                palette="Soft Pastel"
            >
                <DxSeries argument-field="product" value-field="discount_cost">
                <DxLabel
                    :visible="true"
                    format=",###,###,###,##0.00"
                >
                    <DxConnector :visible="true"/>
                </DxLabel>
                </DxSeries>
                <DxExport :enabled="true"/>
                <DxLegend
                :margin="0"
                horizontal-alignment="right"
                vertical-alignment="top"
                />
                <DxTooltip
                :enabled="true"
                :customize-tooltip="customizeTooltip"
                >
                
                </DxTooltip>
            </DxPieChart>
                <v-alert
                    class="pa-2 ma-2"
                    border="bottom"
                    colored-border
                    type="warning"
                    elevation="2"
                    v-if="showAlertLast"
                >
                    В предыдущем месяце нет данных для вывода графика...
                </v-alert>
            </v-col>
            </v-row>
        </v-layout>
    </v-container>
</div>
</template>
<script>

import DxPieChart, {
  DxLegend,
  DxSeries,
  DxTooltip,
  DxLabel,
  DxConnector,
  DxExport
} from 'devextreme-vue/pie-chart';
import StringMask from 'string-mask'


export default {
  components: {
    DxPieChart,
    DxLegend,
    DxSeries,
    DxTooltip,
    DxLabel,
    DxConnector,
    DxExport
  },
  data() {
    return {
        dateStart: '',
        dateEnd: '',
        showAlert: false,
        showChart: false,
        dateStartLast: '',
        dateEndLast: '',
        showAlertLast: false,
        showChartLast: false,
        moneyPlus: this.$store.getters.InputFinance,
        moneyMinus: this.$store.getters.CostAllF
        
    };
  },
  methods: {
    customizeTooltip({ valueText, percent }) {
      return {
        text: `${valueText} - ${(percent * 100).toFixed(2)}%`
      };
    }
  },
    computed: {
        DataReturn() {
            //console.log(this.$store.getters.TransactionsListFAnalitica)

            return this.$store.getters.TransactionsListFAnalitica
            
        },
        DataReturnLast() {
            //console.log(this.$store.getters.TransactionsListFAnalitica)

            return this.$store.getters.TransactionsListFAnaliticaLast
            
        },
        Itogo() {
            //(parseFloat(this.moneyPlus.replace(/\s/g, '')) - parseFloat(this.moneyMinus.replace(/\s/g, ''))).toFixed(2)
            var tt = this.$store.getters.InputFinance.replace(/\s/g, '')
            var ff = this.$store.getters.CostAllF.replace(/\s/g, '')
            var Start = parseFloat(tt)
            var End  = parseFloat(ff)
            //console.log(Start - End)
            var ItogoF = (Start-End).toFixed(2)
            var stringFormat = ''
            if(ItogoF > 0){
                stringFormat = ''
            } else {
                stringFormat = '- '
            }
            //console.log(stringFormat)
            var formatter = new StringMask('# ##0.00', {reverse: true});
            //console.log(state.inputFinance.toString().replace('.',''))
            var s = ItogoF.toString()
            s = s.replace('.','') 
            //console.log(s)
            var result = stringFormat + formatter.apply(s); // 1.001.234,56
            return result
        },
        MoneyP(){
            /* const setting1 = {
            inn: this.$store.getters.UserINN,
            }
            //console.log(setting)
            this.$store.dispatch('loadFinanceByInnDB', setting1) */
            //console.log(1)
            return this.$store.getters.InputFinance

        },
        MoneyM(){
            /* const setting1 = {
            inn: this.$store.getters.UserINN,
            }
            //console.log(setting)
            this.$store.dispatch('loadFinanceByInnDB', setting1) */
            //console.log(2)
            return this.$store.getters.CostAllF
        }

    },
    watch: {
        DataReturn() {
            if(this.$store.getters.TransactionsListFAnalitica.length == 0) {
                this.showAlert = true
                this.showChart = false
            } else {
                this.showAlert = false
                this.showChart = true
            }
        },
        DataReturnLast() {
            if(this.$store.getters.TransactionsListFAnaliticaLast.length == 0) {
                this.showAlertLast = true
                this.showChartLast = false
            } else {
                this.showAlertLast = false
                this.showChartLast = true
            }
        }
    },
    async beforeMount() {
        await this.$store.dispatch('loadUserProcSettings',this.$store.getters.idSettings)
        .then(async () => {
            //console.log(this.selectedItemC)
            var d = new Date();
            //console.log(d)
            var tmpDate = new Date(d.getTime());
            //console.log(tmpDate)
            var y = tmpDate.getFullYear();
            //console.log(y)
            var m = tmpDate.getMonth();
            var m0
            if(m <= 8)
            {
                m0 = '0' + (m+1)
            }
            if(m >= 9)
            {
                m0 = (m + 1)
            }
            
            
            this.dateStart = new Date(tmpDate.getFullYear() + '-' + m0 + '-01').toISOString().substr(0, 10);
            //this.dateStart = this.$store.getters.dateStart
            //console.log(m0)
            
            
            this.dateEnd = new Date(y, m + 1, 1).toISOString().substr(0, 10);
            const setting = {
                cardid: this.$store.getters.cardsList,
                dateStart: this.dateStart,
                dateEnd: this.dateEnd
            }
            //console.log(setting)
            //this.$store.dispatch('loadCardGroupFromSettings')
            await this.$store.dispatch('loadTransactionsListFAllAnalitica', setting)
            .then(async () => {
                //console.log(this.$store.getters.TransactionsListFAnalitica)
                if(this.$store.getters.TransactionsListFAnalitica.length == 0) {
                    this.showAlert = true
                    this.showChart = false
                } else {
                    this.showAlert = false
                    this.showChart = true
                }
                var dLast = new Date();
                dLast.setMonth(dLast.getMonth() - 1);
                //console.log(d)
                var tmpDateLast = new Date(dLast.getTime());
                //console.log(tmpDate)
                var yLast = tmpDateLast.getFullYear();
                //console.log(y)
                var mLast = tmpDateLast.getMonth();
                var m0Last
                if(mLast <= 8)
                {
                    m0Last = '0' + (mLast+1)
                }
                if(mLast >= 9)
                {
                    m0Last = (mLast + 1)
                }
                
                
                this.dateStartLast = new Date(tmpDateLast.getFullYear() + '-' + m0Last + '-01').toISOString().substr(0, 10);
                //this.dateStart = this.$store.getters.dateStart
                //console.log(m0)
                
                
                this.dateEndLast = new Date(yLast, mLast + 1, 1).toISOString().substr(0, 10);
                const settingLast = {
                    cardid: this.$store.getters.cardsList,
                    dateStart: this.dateStartLast,
                    dateEnd: this.dateEndLast
                }
                //console.log(setting)
                //this.$store.dispatch('loadCardGroupFromSettings')
                await this.$store.dispatch('loadTransactionsListFAllAnaliticaLast', settingLast)
                .then(() => {
                    //console.log(this.$store.getters.TransactionsListFAnalitica)
                    if(this.$store.getters.TransactionsListFAnaliticaLast.length == 0) {
                        this.showAlertLast = true
                        this.showChartLast = false
                    } else {
                        this.showAlertLast = false
                        this.showChartLast = true
                    }
                })
            })
            const setting1 = {
                inn: this.$store.getters.UserINN,
            }
            //console.log(setting)
            this.$store.dispatch('loadFinanceByInnDB', setting1)
        })
        
    },
    async mounted() {
        //console.log(this.dateStart)
        //console.log(this.dateEnd)
        


    }

};
</script>
<style>
.centered-input input {
  text-align: center
}

</style>