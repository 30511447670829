<template>
<div>

    <v-card
        class="d-flex justify-center mb-0 ma-2 pa-2"
        flat
        tile
      >
        <v-card
          class="elevation-0 pa-0"
          tile
        >
        <v-row class="pa-0 ma-0">
            <v-col cols="5"  class="pa-0 ma-0">
                <v-combobox class="pr-2 ma-0"
                    v-model="selectedItemC"
                    :items="CardReturn"
                    item-text="number"
                    item-value="id"
                    label="Выберите карту"
                    :loading = "loadingF"
                    :value = "selectedItemC"
                    prepend-icon="mdi-credit-card-multiple-outline"
                ></v-combobox>              
            </v-col>
            <v-col cols="3" class="pa-0 ma-0">
                <v-menu class="pr-2 ma-0"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="computedDateFormatted"
                        label="Дата начала периода"
                        persistent-hint
                        prepend-icon="event"
                        readonly
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker :first-day-of-week="1" locale="ru-ru" v-model="dateStart" no-title @input="menu2 = false"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="3"  class="pa-0 ma-0">
                <v-menu class="pr-2 ma-0"
                    v-model="menu3"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="computedDateFormatted1"
                        label="Дата окончания периода"
                        persistent-hint
                        prepend-icon="event"
                        readonly
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker :first-day-of-week="1" locale="ru-ru" v-model="dateEnd" no-title @input="menu3 = false"></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        </v-card>
    </v-card>
    <v-tabs  centered>
    <v-tab href="#tab-1" class="vtabsvuetyfy">
        Транзакции
    </v-tab>
    <v-tab href="#tab-2" class="vtabsvuetyfy">
        Общая информация
    </v-tab>
    <v-tab href="#tab-3" class="vtabsvuetyfy">
        Лимиты
    </v-tab>
    <v-tab href="#tab-4" class="vtabsvuetyfy">
        Товарные ограничения
    </v-tab>
    <v-tab href="#tab-5" class="vtabsvuetyfy">
        Географические ограничители
    </v-tab>

    <v-tab-item value="tab-1">
    <div>
        
        <dxDataGrid
                :PageSize = "25"
                :ShowPageSizeSelector = "true"
                :AllowedPageSizes = "[ 25, 50, 100 ]"
                :ColumnSettingsIN = "[
                        { colDataField: 'id', colCaption: 'ID операции', sortOrder: undefined, visible: false, datatype: '', format: '', hidingpriority: -1, groupindex: -1},
                        { colDataField: 'time', colCaption: 'Дата', sortOrder: 'asc', datatype: 'date', format: 'dd.MM.yyyy HH:mm:ss', hidingpriority: -1, groupindex: -1 },
                        { colDataField: 'product', colCaption: 'Продукт', sortOrder: undefined, datatype: '', format: '', hidingpriority: -1, groupindex: -1 },
                        { colDataField: 'service_center', colCaption: 'Точка', sortOrder: undefined, datatype: '', format: '', hidingpriority: -1, groupindex: -1 },
                        { colDataField: 'type', colCaption: 'Тип', sortOrder: undefined, datatype: '', format: '', hidingpriority: 0, groupindex: -1 },
                        //{ colDataField: 'price', colCaption: 'Цена на АЗС', sortOrder: undefined, datatype: 'number', format: ',###,###,###,##0.00', hidingpriority: -1, groupindex: -1 },
                        { colDataField: 'amount', colCaption: 'Кол-во', sortOrder: undefined, datatype: 'number', format: ',###,###,###,##0.00', hidingpriority: -1, groupindex: -1 },
                        //{ colDataField: 'cost', colCaption: 'Стоимость на АЗС', sortOrder: undefined, datatype: 'number', format: ',###,###,###,##0.00', hidingpriority: -1, groupindex: -1},
                        { colDataField: 'discount_price', colCaption: 'Цена для Клиента', sortOrder: undefined, datatype: 'number', format: ',###,###,###,##0.00', hidingpriority: -1, groupindex: -1 },
                        { colDataField: 'discount_cost', colCaption: 'Стоимость для Клиента', sortOrder: undefined, datatype: 'number', format: ',###,###,###,##0.00', hidingpriority: -1, groupindex: -1 },
                        { colDataField: 'card_number', colCaption: '№ карты', sortOrder: undefined, visible: false, datatype: '', format: '', hidingpriority: 1, groupindex: 0 }
                ]"

                HeaderText = "Транзакции"
                GridRefName = "TransactionsGrid"
                HeaderIcon = "mdi-transfer"
                :EditedItem = "{ }"
                :DefaultItem = "{ }"
                :AddFields = "[]"
                :EditedFields = "[]"
                EndPoint=""
                :ParentParams="{ client: this.clientID }"
                :GroupPanelVisible = "false"
                :HeaderFilterVisible = "false"
                :FilterRowVisible = "false"
                :SearchVisible = "true"
                :SearchWidth = "300"
                :SummaryVisible = "true"
                :BtnRefresh="true"
                :BtnAdd="true"
                :BtnEdit="false"
                :BtnDelete="false"
                :BtnShipper="false"
                :MaxWidth="800"
                :MaxGridWidth="'max-width: 100%'"
                :DataReturnF="this.$store.getters.TransactionsList"
                :WordWrapEnabled="true"
                :UsingRules="'loadTransactionListF'"
                :AllCard="this.allCard"
                :ColumnHidingEnabled="true"
        ></dxDataGrid>
    </div>
    </v-tab-item>
    <v-tab-item value="tab-2">
     <v-container class="mainGridConteiner" :style="'max-width: 70%'">
        <v-layout justify-center >
            <v-card class="pa-3">
                <v-chip outlined color="primary">
                    <v-icon left>mdi-credit-card-settings</v-icon>Общая информация
                </v-chip>
                <div>
                <p style="padding: 0 15em 0em 0;"></p>
                </div>
                <div>
                    <v-row class="pa-0 ma-0">
                        <v-col cols="12" sm="6" class="pa-0 ma-0">
                            <v-text-field class="pa-0 pr-2 ma-0"
                            value="Номер карты"
                            disabled
                            ></v-text-field>
                        </v-col>
                
                        <v-col cols="12" sm="6" class="pa-0 ma-0">
                            <v-text-field class="pa-0 pl-2  ma-0"
                            :value="selectedCard !== null ? selectedCard.number : ''"
                            readonly
                            ></v-text-field>
                        </v-col>
                        
                 
                        <v-col cols="12" sm="6" class="pa-0 ma-0">
                            <v-text-field class="pa-0 pr-2 ma-0"
                            value="Тип карты"
                            disabled
                            ></v-text-field>
                        </v-col>
                
                        <v-col cols="12" sm="6" class="pa-0 ma-0">
                            <v-text-field class="pa-0 pl-2 ma-0"
                            :value="selectedCard !== null ? selectedCard.product : ''"
                            readonly
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" class="pa-0 ma-0">
                            <v-text-field class="pa-0 pr-2 ma-0"
                            value="Состояние"
                            disabled
                            ></v-text-field>
                        </v-col>
                
                        <v-col cols="12" sm="6" class="pa-0 ma-0">
                            <v-text-field class="pa-0 pl-2 ma-0"
                            :value="selectedCard !== null ? selectedCard.status : ''"
                            readonly
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" class="pa-0 ma-0">
                            <v-text-field class="pa-0 pr-2 ma-0"
                            value="Дата последнего использования"
                            disabled
                            ></v-text-field>
                        </v-col>
                
                        <v-col cols="12" sm="6" class="pa-0 ma-0">
                            <v-text-field class="pa-0 pl-2 ma-0"
                            :value="selectedCard !== null ? selectedCard.date_last_usage : ''"
                            readonly
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" class="pa-0 ma-0">
                            <v-text-field class="pa-0 pr-2 ma-0"
                            value="Дата выпуска"
                            disabled
                            ></v-text-field>
                        </v-col>
                
                        <v-col cols="12" sm="6" class="pa-0 ma-0">
                            <v-text-field class="pa-0 pl-2 ma-0"
                            :value="selectedCard !== null ? selectedCard.date_released : ''"
                            readonly
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" class="pa-0 ma-0">
                            <v-text-field class="pa-0 pr-2 ma-0"
                            value="Тип карты"
                            disabled
                            ></v-text-field>
                        </v-col>
                
                        <v-col cols="12" sm="6" class="pa-0 ma-0">
                            <v-text-field class="pa-0 pl-2 ma-0"
                            :value="selectedCard !== null ? selectedCard.carrier : ''"
                            readonly
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
       </v-layout>
     </v-container>
    </v-tab-item>
    <v-tab-item value="tab-3">
    <div>
        
        <dxDataGridL
                :PageSize = "25"
                :ShowPageSizeSelector = "true"
                :AllowedPageSizes = "[ 25, 50, 100 ]"
                :ColumnSettingsIN = "[
                        { colDataField: 'position', colCaption: 'Позиция', sortOrder: 'asc'},
                        { colDataField: 'period', colCaption: 'Период', sortOrder: undefined },
                        { colDataField: 'value', colCaption: 'Количество', sortOrder: undefined },
                        { colDataField: 'used', colCaption: 'Использовано', sortOrder: undefined },
                        { colDataField: 'rest', colCaption: 'Остаток', sortOrder: undefined },
                        { colDataField: 'transactions_count', colCaption: 'Транзакции', sortOrder: undefined },
                        { colDataField: 'day_about', colCaption: 'Дни/Время', sortOrder: undefined }
                ]"

                HeaderText = "Лимиты"
                HeaderIcon = "mdi-directions-fork"
                :EditedItem = "{ }"
                :DefaultItem = "{ }"
                :AddFields = "[]"
                :EditedFields = "[]"
                EndPoint=""
                :ParentParams="{ client: this.clientID }"
                :GroupPanelVisible = "false"
                :HeaderFilterVisible = "false"
                :FilterRowVisible = "false"
                :SearchVisible = "false"
                :SearchWidth = "300"
                :SummaryVisible = "false"
                :BtnRefresh="true"
                :BtnAdd="false"
                :BtnEdit="false"
                :BtnDelete="false"
                :BtnShipper="false"
                :MaxWidth="800"
                :MaxGridWidth="'max-width: 80%'"
                :DataReturnF="this.$store.getters.TransactionsList"
                :WordWrapEnabled="true"
                :UsingRules="'limits'"
                :AllCard="this.allCard"
                :ColumnHidingEnabled="false"
        ></dxDataGridL>
    </div>
    </v-tab-item>
    <v-tab-item value="tab-4">
    <div>
        
        <dxDataGridL
                :PageSize = "25"
                :ShowPageSizeSelector = "true"
                :AllowedPageSizes = "[ 25, 50, 100 ]"
                :ColumnSettingsIN = "[
                        { colDataField: 'restriction_type', colCaption: 'Тип ограничения', sortOrder: undefined},
                        { colDataField: 'productTypeName', colCaption: 'Категория', sortOrder: 'asc' },
                        { colDataField: 'productGroupName', colCaption: 'Группа', sortOrder: undefined },
                        { colDataField: 'productType', colCaption: 'Группа', sortOrder: undefined, visible: false },
                        { colDataField: 'productGroup', colCaption: 'Группа', sortOrder: undefined, visible: false },
                        { colDataField: 'contract_id', colCaption: 'Группа', sortOrder: undefined, visible: false },
                        { colDataField: 'id', colCaption: 'Группа', sortOrder: undefined, visible: false }
                ]"

                HeaderText = "Товарные ограничения"
                HeaderIcon = "mdi-directions-fork"
                :EditedItem = "{ }"
                :DefaultItem = "{ }"
                :AddFields = "[]"
                :EditedFields = "[]"
                EndPoint=""
                :ParentParams="{ client: this.clientID }"
                :GroupPanelVisible = "false"
                :HeaderFilterVisible = "false"
                :FilterRowVisible = "false"
                :SearchVisible = "false"
                :SearchWidth = "300"
                :SummaryVisible = "false"
                :BtnRefresh="true"
                :BtnAdd="true"
                :BtnEdit="true"
                :BtnDelete="true"
                :BtnShipper="false"
                :MaxWidth="800"
                :MaxGridWidth="'max-width: 80%'"
                :DataReturnF="this.$store.getters.TransactionsList"
                :WordWrapEnabled="true"
                :UsingRules="'restrictions'"
                :AllCard="this.allCard"
                :ColumnHidingEnabled="false"
        ></dxDataGridL>
    </div>
    </v-tab-item>
    <v-tab-item value="tab-5">
    <div>
        
        <dxDataGridL
                :PageSize = "25"
                :ShowPageSizeSelector = "true"
                :AllowedPageSizes = "[ 25, 50, 100 ]"
                :ColumnSettingsIN = "[
                        { colDataField: 'RestStr', colCaption: 'Тип ограничения', sortOrder: 'desc'},
                        { colDataField: 'country_name', colCaption: 'Страна', sortOrder: undefined },
                        { colDataField: 'region_name', colCaption: 'Регион', sortOrder: undefined },
                        { colDataField: 'azs_name', colCaption: 'АЗС', sortOrder: undefined },
                        { colDataField: 'id', colCaption: 'Группа', sortOrder: undefined, visible: false },
                        { colDataField: 'card_id', colCaption: 'Группа', sortOrder: undefined, visible: false },
                        { colDataField: 'group_id', colCaption: 'Группа', sortOrder: undefined, visible: false },
                        { colDataField: 'contract_id', colCaption: 'Группа', sortOrder: undefined, visible: false },
                        { colDataField: 'country', colCaption: 'Группа', sortOrder: undefined, visible: false },
                        { colDataField: 'region', colCaption: 'Группа', sortOrder: undefined, visible: false },
                        { colDataField: 'service_center', colCaption: 'Группа', sortOrder: undefined, visible: false },
                        { colDataField: 'date', colCaption: 'Группа', sortOrder: undefined, visible: false },
                        { colDataField: 'limit_type', colCaption: 'Группа', sortOrder: undefined, visible: false },
                ]"

                HeaderText = "Товарные ограничения"
                HeaderIcon = "mdi-directions-fork"
                :EditedItem = "{ }"
                :DefaultItem = "{ }"
                :AddFields = "[]"
                :EditedFields = "[]"
                EndPoint=""
                :ParentParams="{ client: this.clientID }"
                :GroupPanelVisible = "false"
                :HeaderFilterVisible = "false"
                :FilterRowVisible = "false"
                :SearchVisible = "false"
                :SearchWidth = "300"
                :SummaryVisible = "false"
                :BtnRefresh="true"
                :BtnAdd="true"
                :BtnEdit="true"
                :BtnDelete="true"
                :BtnShipper="false"
                :MaxWidth="800"
                :MaxGridWidth="'max-width: 80%'"
                :DataReturnF="this.$store.getters.TransactionsList"
                :WordWrapEnabled="true"
                :UsingRules="'RegionLimits'"
                :AllCard="this.allCard"
                :ColumnHidingEnabled="false"
        ></dxDataGridL>
    </div>
    </v-tab-item>
    </v-tabs>
</div>
</template>

<script>
import dxDataGrid from '@/components/dxDataGridDF.vue'
import dxDataGridL from '@/components/dxDataGridLimit.vue'

    export default {
        name: "deteailInfo",
        data() {
            return {
                selectedItemC: null,
                menu2: false,
                menu3: false,
                dateStart: '',
                dateEnd: '',
                selectedItemTo: undefined,
                selectedCard: null,
                clientID: this.$store.getters.UserId,
                allCard: false
            }
        },
        async beforeMount() {
            //console.log(this.selectedItemC)
            var d = new Date();
            //console.log(d)
            var tmpDate = new Date(d.getTime());
            //console.log(tmpDate)
            var y = tmpDate.getFullYear();
            //console.log(y)
            var m = tmpDate.getMonth();
            var m0
            if(m <= 8)
            {
                m0 = '0' + (m+1)
            }
            if(m >= 9)
            {
                m0 = (m + 1)
            }
            //console.log(m)
            //var dateGet = tmpDate.getFullYear() + '-' + m0 + '-01'
            //console.log(dateGet)
            
            
            //console.log(this.dateStart + ' ' + this.dateEnd)
            //console.log(firstDay + ' - ' + lastDay)
            //console.log(this.$store.getters.dateStart )
            if(this.$store.getters.dateStart != ''){
                this.dateStart = this.$store.getters.dateStart
            } else {
                this.dateStart = new Date(tmpDate.getFullYear() + '-' + m0 + '-01').toISOString().substr(0, 10);
            }
            if(this.$store.getters.dateEnd != ''){
                this.dateEnd = this.$store.getters.dateEnd
            } else {
                this.dateEnd = new Date(y, m + 1, 1).toISOString().substr(0, 10);
            }
            await this.$store.dispatch('loadUserProcSettings',this.$store.getters.idSettings)
            await this.$store.dispatch('loadUserProductSettings',this.$store.getters.idSettings)
            //console.log(this.$store.getters.userProcSettings)
        },
        async mounted() {
            this.$store.commit('SetCardID', undefined)
            this.$store.commit('SetCardNumber', undefined)
            //console.log(this.$store.getters.CardID)
            const setting = {
                    cardid: undefined,
                }
            await this.$store.dispatch('loadTransactionListF', setting)
            .then(async() => {
                    const setting1 = {
                        gpnapi: this.$store.state.gpn24,
                        sessionid: this.$store.getters.SessionId,
                        contractid: this.$store.getters.LastContract,
                        cardid: undefined,
                        gpnurl: this.$store.state.url24,
                    }
                    //console.log(setting2)
                    await this.$store.dispatch('loadCardLimits', setting1)
                    .then(async() => {
                        await this.$store.dispatch('loadCardRestrictions', setting1)
                        .then(async() => {
                            //console.log(1)
                            await this.$store.dispatch('loadCardRegionLimitList', setting1)
                        })
                    })
                })
        },
        watch: {
            async selectedItemC(){
                //console.log(this.selectedItemC)
                this.selectedCard = this.selectedItemC
                if(this.selectedItemC.id != null){
                    this.allCard = false
                    this.$store.commit('SetGetAllCard', false)
                    //console.log(this.allCard)
                    //console.log(this.selectedItemC.number.replace(/\s/g, ''))
                    this.$store.commit('SetCardNumber', this.selectedItemC.number.replace(/\s/g, ''))
                    this.$store.commit('SetCardID', this.selectedItemC.id)
                    //console.log(this.dateStart + ' ' + this.dateEnd)
                    if(this.$store.getters.dateStart != ''){
                        this.dateStart = this.$store.getters.dateStart
                    }
                    if(this.$store.getters.dateEnd != ''){
                        this.dateEnd = this.$store.getters.dateEnd
                    }
                    const setting = {
                        cardid: this.selectedItemC.number.replace(/\s/g, ''),
                        dateStart: this.dateStart,
                        dateEnd: this.dateEnd
                    }
                    this.$store.dispatch('loadTransactionListF', setting)
                    .then(() => {
                        /* const setting1 = {
                            gpnapi: this.$store.state.gpn24,
                            sessionid: this.$store.getters.SessionId,
                            contractid: this.$store.getters.LastContract,
                            cardid: this.selectedItemC.id,
                            gpnurl: this.$store.state.url24,
                        }
                        //console.log(setting2)
                        this.$store.dispatch('loadCardLimits', setting1)
                        .then(() => {
                            this.$store.dispatch('loadCardRestrictions', setting1)
                            .then(() => {
                                //console.log(1)
                                this.$store.dispatch('loadCardRegionLimitList', setting1)
                            })
                        }) */
                    })
                }
                if(this.selectedItemC.id == null){
                    this.allCard = true
                    this.$store.commit('SetGetAllCard', true)
                    this.$store.commit('SetCardID', undefined)
                    //console.log(this.allCard)
                    if(this.$store.getters.dateStart != ''){
                        this.dateStart = this.$store.getters.dateStart
                    }
                    if(this.$store.getters.dateEnd != ''){
                        this.dateEnd = this.$store.getters.dateEnd
                    }
                    const setting = {
                        cardid: this.$store.getters.cardsList,
                        dateStart: this.dateStart,
                        dateEnd: this.dateEnd
                    }
                    //console.log(setting)
                    await this.$store.dispatch('loadTransactionListFAll', setting)
                    .then(async() => {
                            const setting1 = {
                                gpnapi: this.$store.state.gpn24,
                                sessionid: this.$store.getters.SessionId,
                                contractid: this.$store.getters.LastContract,
                                cardid: undefined,
                                gpnurl: this.$store.state.url24,
                            }
                            //console.log(setting2)
                            await this.$store.dispatch('loadCardLimits', setting1)
                            .then(async() => {
                                await this.$store.dispatch('loadCardRestrictions', setting1)
                                .then(async() => {
                                    //console.log(1)
                                    await this.$store.dispatch('loadCardRegionLimitList', setting1)
                                })
                            })
                        })
                }
                
            },
        },
        methods: {
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
            },
        },
        components:{
            dxDataGrid,
            dxDataGridL
        },
        computed: {
            CardReturn() {
                return this.$store.getters.cardsListAll
            },
            loadingF(){
                return this.$store.getters.loading
            },
            computedDateFormatted () {
                //console.log(this.dateStart)
                this.$store.commit('SetDateStart', this.dateStart)
                //console.log(this.$store.getters.dateStart)
                return this.formatDate(this.dateStart)
            },
            computedDateFormatted1 () {
                this.$store.commit('SetDateEnd', this.dateEnd)
                return this.formatDate(this.dateEnd)
            },
        }
    }
</script>


