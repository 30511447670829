
//import router from '../router/router.js'
import TransactionsList from './transactionsListF_help'
import store from './store.js'
import StringMask from 'string-mask'
import axios from 'axios'
//import moment from 'moment';

export default {
    state: {
        transactionslistF: [],
        transactionslistFAnalitica: [],
        transactionslistFAnaliticaLast: [],
        costAll: 0,
        inputFinance: 0
    },
    mutations: {
        loadTransactionsListF (state, payload) {
            state.transactionslistF = payload
        },
        loadTransactionsListFAllAnalitica (state, payload) {
            state.transactionslistFAnalitica = payload
        },
        loadTransactionsListFAllAnaliticaLast (state, payload) {
            state.transactionslistFAnaliticaLast = payload
        },
        loadCostAll (state, payload) {
            state.costAll = payload
        },
        loadInputFinance (state, payload) {
            state.inputFinance = payload
        },
    },
    actions: {
        async loadTransactionListF ({commit}, { cardid, dateStart, dateEnd }) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading2', true)

                const TransactionsListArr = []
                //console.log(cardid)
                if (typeof cardid !== "undefined") {
                    
                    //try {
                        //var settings = store.getters.settings
                        //console.log(settings)
                        //var NumProc = settings.find(o => o.user === store.getters.user.id);
                        //console.log(NumProc.numProcPrice)
                        //var NewPrice = (NumProc.numProcPrice/100) + 1
                        //console.log(NewPrice)
                        //var ref = firebase.database().ref("tblTransactions/" + store.getters.CardGroupID);
                        /* var transactions
                        console.log(cardid)
                        ref
                        .orderByChild('time').startAt('2018-11-05').endAt('2018-11-07')
                        .on("value", function(snapshot) {
                            //console.log(snapshot.val());
                            transactions = snapshot.val()

                            Object.keys(transactions).forEach(key => {
                                const s = transactions[key]
                                //console.log(s)
                                if(s.card_number == '7005830010286291') {
                                    TransactionsListArr.push(
                                        new TransactionsList(
                                            s.id,
                                            s.product,
                                            s.amount,
                                            s.cost,
                                            s.discount_price,
                                            s.discount_cost,
                                            s.transaction,
                                            s.price,
                                            s.card_number,
                                            s.service_center,
                                            s.currency,
                                            s.time,
                                            s.type,
                                            s.name
                                            
                                        )
                                    )
                                }
                                
                            })
                            //console.log(cardid)
                            console.log(TransactionsListArr) */
                        let datestarttm = dateStart + ' 00:00:01'
                        let dateendtm = dateEnd + ' 23:59:59'
                        let dataTr = [
                            {"proc":'loadDictionary_TransactionListFCardAllPG', "uid": store.getters.userUID, "uid2": store.getters.user.id, "CardGroupID": store.getters.CardGroupID, "datestarttm": datestarttm, "dateendtm":dateendtm,"cardid":cardid}]
                        axios({
                            method: 'POST',
                            headers: {
                                'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                                'Content-Type': 'application/json'
                            },
                            url: store.state.url24sr,
                            //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                            data: dataTr
        
                        })
                        .then((res) => {
                            var transactions
                            /* ref
                            .orderByChild('time').startAt(dateStart + ' 00:00:01').endAt(dateEnd + ' 23:59:59')
                            .on("child_added", function(snapshot) { */
                            //if(res.data.length == 0){ console.log(res.data); }
                            transactions = res.data
                            Object.keys(transactions).forEach(key => {
                                if(transactions[key].card_number == cardid) {
                                    var NumFixPrice = Object.assign({},store.getters.userProductSettings.find(o => o.dtDateStart <= transactions[key].time.substr(0, 10) && o.dtDateEnd >= transactions[key].time.substr(0, 10) && o.strName.trim() == transactions[key].product.trim()));
                                    var NewFixPriceGet = NumFixPrice.numPrice
                                    if(NumFixPrice.numPrice !== undefined) {
                                        TransactionsListArr.push(
                                            new TransactionsList(
                                                transactions[key].id,
                                                transactions[key].product,
                                                transactions[key].amount,
                                                transactions[key].cost,
                                                NewFixPriceGet,
                                                (NewFixPriceGet * transactions[key].amount).toFixed(2),
                                                transactions[key].transaction,
                                                transactions[key].price,
                                                transactions[key].card_number,
                                                transactions[key].service_center,
                                                transactions[key].currency,
                                                transactions[key].time.substr(0,19),
                                                transactions[key].type,
                                                transactions[key].name
                                                
                                            )
                                        )
                                    }
                                    else
                                    {
                                        var NumProc = Object.assign({},store.getters.userProcSettings.find(o => o.dtDateStart <= transactions[key].time.substr(0, 10) && o.dtDateEnd >= transactions[key].time.substr(0, 10)));
                                        var Proc = 0
                                        if(NumProc.numProcPrice !== undefined) {
                                            Proc = NumProc.numProcPrice
                                        }
                                        var NewPrice = (Proc/100) + 1
                                        TransactionsListArr.push(
                                            new TransactionsList(
                                                transactions[key].id,
                                                transactions[key].product,
                                                transactions[key].amount,
                                                transactions[key].cost,
                                                (transactions[key].price * NewPrice).toFixed(2),
                                                (transactions[key].cost * NewPrice).toFixed(2),
                                                transactions[key].transaction,
                                                transactions[key].price,
                                                transactions[key].card_number,
                                                transactions[key].service_center,
                                                transactions[key].currency,
                                                transactions[key].time.substr(0,19),
                                                transactions[key].type,
                                                transactions[key].name
                                                
                                            )
                                        )
                                    }
                                }
                            
                            })

                            
                            commit('loadTransactionsListF', TransactionsListArr)
                            
                            commit('setLoading2', false)
                            resolve();
                        })
                            .catch((response) => {
                            commit('loadTransactionsListF', [])
                            commit('setLoading2', false)
                            commit('setError', response.message)
                            //throw error
                            reject(response)
                        })
                    
            }
            else {
                //console.log('88')
                commit('loadTransactionsListF', [])
                commit('setLoading2', false)
            }

        })    

        },
        async loadTransactionListFAll ({commit}, { cardid, dateStart, dateEnd }) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading2', true)

                const TransactionsListArr = []
                //console.log(cardid)
                var CardNumber = []
                
                Object.keys(cardid).forEach(key => {
                    var s = cardid[key].number.replace(/\s/g, '')
                    //console.log(s)
                    CardNumber.push(s)
                })
                //console.log(CardNumber)
                
                //try {
                    //var settings = store.getters.settings
                    //console.log(settings)
                    //var NumProc = settings.find(o => o.user === store.getters.user.id);
                    //console.log(NumProc)
                    //var NewPrice = (NumProc.numProcPrice/100) + 1
                    //console.log(store.getters.CardGroupID)
                    //var ref = firebase.database().ref("tblTransactions/" + store.getters.CardGroupID);
                    //var ref = firebase.database().ref("tblTransactions/1-OPKTR4D/")
                    /* var transactions
                    console.log(cardid)
                    ref
                    .orderByChild('time').startAt('2018-11-05').endAt('2018-11-07')
                    .on("value", function(snapshot) {
                        //console.log(snapshot.val());
                        transactions = snapshot.val()

                        Object.keys(transactions).forEach(key => {
                            const s = transactions[key]
                            //console.log(s)
                            if(s.card_number == '7005830010286291') {
                                TransactionsListArr.push(
                                    new TransactionsList(
                                        s.id,
                                        s.product,
                                        s.amount,
                                        s.cost,
                                        s.discount_price,
                                        s.discount_cost,
                                        s.transaction,
                                        s.price,
                                        s.card_number,
                                        s.service_center,
                                        s.currency,
                                        s.time,
                                        s.type,
                                        s.name
                                        
                                    )
                                )
                            }
                            
                        })
                        //console.log(cardid)
                        console.log(TransactionsListArr) */
                let datestarttm = dateStart + ' 00:00:01'
                let dateendtm = dateEnd + ' 23:59:59'
                let dataTr = [
                    {"proc":'loadDictionary_TransactionListFAllPG', "uid": store.getters.userUID, "uid2": store.getters.user.id, "CardGroupID": store.getters.CardGroupID, "datestarttm": datestarttm, "dateendtm":dateendtm}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    //console.log(dateStart)
                    //console.log(dateEnd)
                    var transactions
                    //ref
                    //.orderByChild('time').startAt(dateStart + ' 00:00:01').endAt(dateEnd + ' 23:59:59')
                    //.on("child_added", function(snapshot) {
                    //console.log(snapshot.val());
                    transactions = res.data
                    //console.log(store.getters.userProductSettings)
                    //console.log(transactions)
                    //console.log(CardNumber.includes(transactions.card_number))
                    Object.keys(transactions).forEach(key => {
                        //const s = transactions[key]
                        if(CardNumber.includes(transactions[key].card_number)) {
                            var NumFixPrice = Object.assign({},store.getters.userProductSettings.find(o => o.dtDateStart <= transactions[key].time.substr(0, 10) && o.dtDateEnd >= transactions[key].time.substr(0, 10) && o.strName.trim() == transactions[key].product.trim()));
                            var NewFixPriceGet = NumFixPrice.numPrice
                            if(NumFixPrice.numPrice !== undefined) {
                                TransactionsListArr.push(
                                    new TransactionsList(
                                        transactions[key].id,
                                        transactions[key].product,
                                        transactions[key].amount,
                                        transactions[key].cost,
                                        NewFixPriceGet,
                                        (NewFixPriceGet * transactions[key].amount).toFixed(2),
                                        transactions[key].transaction,
                                        transactions[key].price,
                                        transactions[key].card_number,
                                        transactions[key].service_center,
                                        transactions[key].currency,
                                        transactions[key].time.substr(0,19),
                                        transactions[key].type,
                                        transactions[key].name
                                        
                                    )
                                )
                            }
                            else
                            {
                                //console.log(transactions[key].time.substr(0, 10))
                                var NumProc = Object.assign({},store.getters.userProcSettings.find(o => o.dtDateStart <= transactions[key].time.substr(0, 10) && o.dtDateEnd >= transactions[key].time.substr(0, 10)));
                                var Proc = 0
                                if(NumProc.numProcPrice !== undefined) {
                                    Proc = NumProc.numProcPrice
                                }
                                var NewPrice = (Proc/100) + 1
                                //console.log(Proc)
                                TransactionsListArr.push(
                                    new TransactionsList(
                                        transactions[key].id,
                                        transactions[key].product,
                                        transactions[key].amount,
                                        transactions[key].cost,
                                        (transactions[key].price * NewPrice).toFixed(2),
                                        (transactions[key].cost * NewPrice).toFixed(2),
                                        transactions[key].transaction,
                                        transactions[key].price,
                                        transactions[key].card_number,
                                        transactions[key].service_center,
                                        transactions[key].currency,
                                        transactions[key].time.substr(0,19),
                                        transactions[key].type,
                                        transactions[key].name
                                        
                                    )
                                )
                            }
                        }
                    })
                        
                    console.log(TransactionsListArr)
                    //var FindeType = TransactionsListArr.find(o => o.time >= '2020-04-26 00:00:01' && o.time <= '2020-04-26 24:00:00');
                    //console.log(FindeType)
                    commit('loadTransactionsListF', TransactionsListArr)
                    commit('setLoading2', false)
                    resolve();
                })
                .catch((response) => {
                
                    //const transaction = await firebase.database().ref('tblTransactions').once('value')
                    //const transactions = transaction.val()
                    //console.log(transactions)
                    console.log(2)
                    commit('loadTransactionsListF', [])
                    commit('setLoading2', false)
                    commit('setError', response.message)
                    //throw error
                    reject(response)
                    //throw error
                })
            
        
            });
            

        },
        async loadTransactionsListFAllAnalitica ({commit}, { cardid, dateStart, dateEnd }) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading2', true)
                commit('loadTransactionsListFAllAnalitica', [])
                const TransactionsListArr = []
                //console.log(cardid)
                var CardNumber = []
                
                Object.keys(cardid).forEach(key => {
                    var s = cardid[key].number.replace(/\s/g, '')
                    //console.log(s)
                    CardNumber.push(s)
                })
                //console.log(CardNumber)
                
                //try {
                    //var settings = store.getters.settings
                    //console.log(settings)
                    //var NumProc = settings.find(o => o.user === store.getters.user.id);
                    //console.log(NumProc.numProcPrice)
                    //var NewPrice = (NumProc.numProcPrice/100) + 1
                    //console.log(NewPrice)
                    //var ref = firebase.database().ref("tblTransactions/" + store.getters.CardGroupID);
                    /* var transactions
                    console.log(cardid)
                    ref
                    .orderByChild('time').startAt('2018-11-05').endAt('2018-11-07')
                    .on("value", function(snapshot) {
                        //console.log(snapshot.val());
                        transactions = snapshot.val()

                        Object.keys(transactions).forEach(key => {
                            const s = transactions[key]
                            //console.log(s)
                            if(s.card_number == '7005830010286291') {
                                TransactionsListArr.push(
                                    new TransactionsList(
                                        s.id,
                                        s.product,
                                        s.amount,
                                        s.cost,
                                        s.discount_price,
                                        s.discount_cost,
                                        s.transaction,
                                        s.price,
                                        s.card_number,
                                        s.service_center,
                                        s.currency,
                                        s.time,
                                        s.type,
                                        s.name
                                        
                                    )
                                )
                            }
                            
                        })
                        //console.log(cardid)
                        console.log(TransactionsListArr) */
                    //console.log(dateStart)
                    //console.log(dateEnd)
                let datestarttm = dateStart + ' 00:00:01'
                let dateendtm = dateEnd + ' 23:59:59'
                let dataTr = [
                    {"proc":'loadDictionary_TransactionListFAllPG', "uid": store.getters.userUID, "uid2": store.getters.user.id, "CardGroupID": store.getters.CardGroupID, "datestarttm": datestarttm, "dateendtm":dateendtm}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    var transactions
                    var CostAll = '0.00'
                    commit('loadCostAll', CostAll)
                    //ref
                    //.orderByChild('time').startAt(dateStart + ' 00:00:01').endAt(dateEnd + ' 23:59:59')
                    //.on("child_added", function(snapshot) {
                        //console.log(snapshot)
                        if (res.data.length == 0) {
                            //console.log(snapshot)
                            commit('loadTransactionsListFAllAnalitica', [])
                            commit('setLoading2', false)
                            resolve();
                        } 
                        //console.log(snapshot.val());
                        transactions = res.data
                        //console.log(CardNumber)
                        //console.log(transactions.card_number)
                        //console.log(CardNumber.includes(transactions.card_number))
                        Object.keys(transactions).forEach(key => {
                            if(CardNumber.includes(transactions[key].card_number)) {
                                var NumFixPrice = Object.assign({},store.getters.userProductSettings.find(o => o.dtDateStart <= transactions[key].time.substr(0, 10) && o.dtDateEnd >= transactions[key].time.substr(0, 10) && o.strName.trim() == transactions[key].product.trim()));
                                var NewFixPriceGet = NumFixPrice.numPrice
                                if(NumFixPrice.numPrice !== undefined) {
                                    var FindeType1 = TransactionsListArr.find(o => o.product === transactions[key].product);
                                    var NewCost1 = parseFloat((NewFixPriceGet * transactions[key].amount).toFixed(2))
                                    CostAll = (parseFloat(CostAll) + parseFloat(NewCost1))
                                    //console.log(FindeType)
                                    if(FindeType1 === undefined){
                                        //console.log((transactions.cost * NewPrice))
                                        TransactionsListArr.push(
                                            new TransactionsList(
                                                transactions[key].id,
                                                transactions[key].product,
                                                transactions[key].amount,
                                                transactions[key].cost,
                                                NewFixPriceGet,
                                                (NewFixPriceGet * transactions[key].amount).toFixed(2),
                                                transactions[key].transaction,
                                                transactions[key].price,
                                                transactions[key].card_number,
                                                transactions[key].service_center,
                                                transactions[key].currency,
                                                transactions[key].time.substr(0,19),
                                                transactions[key].type,
                                                transactions[key].name
                                                
                                            )
                                        )
                                    } else {
                                        //console.log(FindeType.id)
                                        const elementsIndex = TransactionsListArr.findIndex(element => element.id == FindeType1.id)
                                        //var index = TransactionsListArr.indexOf(FindeType.id, 0);
                                        //console.log(elementsIndex)
                                        //console.log('FindeType.amount ' + FindeType1.amount)
                                        //console.log('transactions.amount ' + transactions.amount)
                                        var amountNew1 = (parseFloat(FindeType1.amount) + parseFloat(transactions[key].amount)).toFixed(2)
                                        //console.log('amount ' + amount)
                                        var costMath1 = parseFloat((NewFixPriceGet * transactions[key].amount).toFixed(2))
                                        var costNew1 = parseFloat(FindeType1.discount_cost) + parseFloat(costMath1)
                                        //console.log(parseFloat(FindeType1.cost) + ' + ' + ((NewFixPriceGet * transactions.amount).toFixed(2)) + ' = ' + costNew1)
                                        //console.log(parseFloat(FindeType1.discount_cost))
                                        TransactionsListArr[elementsIndex].amount = amountNew1
                                        TransactionsListArr[elementsIndex].discount_cost = costNew1.toFixed(2)
                                        //console.log(TransactionsListArr[elementsIndex].discount_cost)
                                    }
                                }
                                else
                                {
                                    var NumProc = Object.assign({},store.getters.userProcSettings.find(o => o.dtDateStart <= transactions[key].time.substr(0, 10) && o.dtDateEnd >= transactions[key].time.substr(0, 10)));
                                    var Proc = 0
                                    if(NumProc.numProcPrice !== undefined) {
                                        Proc = NumProc.numProcPrice
                                    }
                                    var NewPrice = (Proc/100) + 1
                                    var NewCost = parseFloat((transactions[key].cost * NewPrice).toFixed(2))
                                    //console.log(NewPrice)
                                    CostAll = (parseFloat(CostAll) + parseFloat(NewCost))
                                    //console.log(CostAll)
                                    var FindeType = TransactionsListArr.find(o => o.product === transactions[key].product);
                                    //console.log(FindeType)
                                    if(FindeType === undefined){
                                        //console.log((transactions.cost * NewPrice))
                                        TransactionsListArr.push(
                                            new TransactionsList(
                                                transactions[key].id,
                                                transactions[key].product,
                                                transactions[key].amount,
                                                transactions[key].cost,
                                                (transactions[key].price * NewPrice).toFixed(2),
                                                (transactions[key].cost * NewPrice).toFixed(2),
                                                transactions[key].transaction,
                                                transactions[key].price,
                                                transactions[key].card_number,
                                                transactions[key].service_center,
                                                transactions[key].currency,
                                                transactions[key].time.substr(0,19),
                                                transactions[key].type,
                                                transactions[key].name
                                                
                                            )
                                        )
                                    } else {
                                        //console.log(FindeType.id)
                                        const elementsIndex = TransactionsListArr.findIndex(element => element.id == FindeType.id )
                                        //var index = TransactionsListArr.indexOf(FindeType.id, 0);
                                        //console.log(elementsIndex)
                                        //console.log('FindeType.amount ' + FindeType.amount)
                                        //console.log('transactions.amount ' + transactions.amount)
                                        var amountNew = (parseFloat(FindeType.amount) + parseFloat(transactions[key].amount)).toFixed(2)
                                        //console.log('amount ' + amount)
                                        var costMath = parseFloat((transactions[key].cost * NewPrice).toFixed(2))
                                        var costNew = parseFloat(FindeType.discount_cost) + parseFloat(costMath)
                                        //console.log(parseFloat(FindeType.cost) + ' + ' + (parseFloat(transactions.cost) * NewPrice) + ' = ' costNew)
                                        //console.log(parseFloat(FindeType.discount_cost))
                                        TransactionsListArr[elementsIndex].amount = amountNew
                                        TransactionsListArr[elementsIndex].discount_cost = costNew.toFixed(2)
                                    }
                                }
                            }
                        })

                        
                        //console.log(TransactionsListArr)
                        commit('loadTransactionsListFAllAnalitica', TransactionsListArr)
                        //console.log(CostAll.toFixed(2))
                        commit('loadCostAll', CostAll)
                        commit('setLoading2', false)
                        resolve();
                    })
                    .catch((response) => {
                    
                        commit('loadTransactionsListFAllAnalitica', [])
                        //console.log(1)
                        //commit('loadCostAll', '0.00')
                        commit('setLoading2', false)
                        commit('setError', response.message)
                        reject(response)
                        //throw error
                    })
                
        

            })

        },
        async loadTransactionsListFAllAnaliticaLast ({commit}, { cardid, dateStart, dateEnd }) {
            return new Promise((resolve, reject) => {
            commit('clearError')
            commit('setLoading2', true)
            commit('loadTransactionsListFAllAnaliticaLast', [])
            const TransactionsListArr = []
            //console.log(cardid)
            var CardNumber = []
            
            Object.keys(cardid).forEach(key => {
                var s = cardid[key].number.replace(/\s/g, '')
                //console.log(s)
                CardNumber.push(s)
            })
            //console.log(CardNumber)
                
                //try {
                    //var settings = store.getters.settings
                    //console.log(settings)
                    //var NumProc = settings.find(o => o.user === store.getters.user.id);
                    //console.log(NumProc.numProcPrice)
                    //var NewPrice = (NumProc.numProcPrice/100) + 1
                    //console.log(NewPrice)
                    //var ref = firebase.database().ref("tblTransactions/" + store.getters.CardGroupID);
                    /* var transactions
                    console.log(cardid)
                    ref
                    .orderByChild('time').startAt('2018-11-05').endAt('2018-11-07')
                    .on("value", function(snapshot) {
                        //console.log(snapshot.val());
                        transactions = snapshot.val()

                        Object.keys(transactions).forEach(key => {
                            const s = transactions[key]
                            //console.log(s)
                            if(s.card_number == '7005830010286291') {
                                TransactionsListArr.push(
                                    new TransactionsList(
                                        s.id,
                                        s.product,
                                        s.amount,
                                        s.cost,
                                        s.discount_price,
                                        s.discount_cost,
                                        s.transaction,
                                        s.price,
                                        s.card_number,
                                        s.service_center,
                                        s.currency,
                                        s.time,
                                        s.type,
                                        s.name
                                        
                                    )
                                )
                            }
                            
                        })
                        //console.log(cardid)
                        console.log(TransactionsListArr) */
                    //console.log(dateStart)
                    //console.log(dateEnd)
                let datestarttm = dateStart + ' 00:00:01'
                let dateendtm = dateEnd + ' 23:59:59'
                let dataTr = [
                    {"proc":'loadDictionary_TransactionListFAllPG', "uid": store.getters.userUID, "uid2": store.getters.user.id, "CardGroupID": store.getters.CardGroupID, "datestarttm": datestarttm, "dateendtm":dateendtm}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    var transactions
                    //var CostAll = '0.00'
                    //ref
                    //.orderByChild('time').startAt(dateStart + ' 00:00:01').endAt(dateEnd + ' 23:59:59')
                    //.on("child_added", function(snapshot) {
                    //console.log(snapshot)
                    if (res.data.length == 0) {
                        //console.log(snapshot)
                        commit('loadTransactionsListFAllAnaliticaLast', [])
                        commit('setLoading2', false)
                        resolve();
                    } 
                    //console.log(snapshot.val());
                    transactions = res.data
                    //console.log(CardNumber)
                    //console.log(transactions.card_number)
                    //console.log(CardNumber.includes(transactions.card_number))
                    Object.keys(transactions).forEach(key => {
                        if(CardNumber.includes(transactions[key].card_number)) {
                            var NumFixPrice = Object.assign({},store.getters.userProductSettings.find(o => o.dtDateStart <= transactions[key].time.substr(0, 10) && o.dtDateEnd >= transactions[key].time.substr(0, 10) && o.strName.trim() == transactions[key].product.trim()));
                            var NewFixPriceGet = NumFixPrice.numPrice
                            if(NumFixPrice.numPrice !== undefined) {
                                var FindeType1 = TransactionsListArr.find(o => o.product === transactions[key].product);
                                //var NewCost1 = parseFloat((NewFixPriceGet * transactions.amount).toFixed(2))
                                //CostAll = (parseFloat(CostAll) + parseFloat(NewCost1))
                                //console.log(FindeType)
                                if(FindeType1 === undefined){
                                    //console.log((transactions.cost * NewPrice))
                                    TransactionsListArr.push(
                                        new TransactionsList(
                                            transactions[key].id,
                                            transactions[key].product,
                                            transactions[key].amount,
                                            transactions[key].cost,
                                            NewFixPriceGet,
                                            (NewFixPriceGet * transactions[key].amount).toFixed(2),
                                            transactions[key].transaction,
                                            transactions[key].price,
                                            transactions[key].card_number,
                                            transactions[key].service_center,
                                            transactions[key].currency,
                                            transactions[key].time.substr(0,19),
                                            transactions[key].type,
                                            transactions[key].name
                                            
                                        )
                                    )
                                } else {
                                    //console.log(FindeType.id)
                                    const elementsIndex = TransactionsListArr.findIndex(element => element.id == FindeType1.id)
                                    //var index = TransactionsListArr.indexOf(FindeType.id, 0);
                                    //console.log(elementsIndex)
                                    //console.log('FindeType.amount ' + FindeType1.amount)
                                    //console.log('transactions.amount ' + transactions.amount)
                                    var amountNew1 = (parseFloat(FindeType1.amount) + parseFloat(transactions[key].amount)).toFixed(2)
                                    //console.log('amount ' + amount)
                                    var costMath1 = parseFloat((NewFixPriceGet * transactions[key].amount).toFixed(2))
                                    var costNew1 = parseFloat(FindeType1.discount_cost) + parseFloat(costMath1)
                                    //console.log(parseFloat(FindeType1.cost) + ' + ' + ((NewFixPriceGet * transactions.amount).toFixed(2)) + ' = ' + costNew1)
                                    //console.log(parseFloat(FindeType1.discount_cost))
                                    TransactionsListArr[elementsIndex].amount = amountNew1
                                    TransactionsListArr[elementsIndex].discount_cost = costNew1.toFixed(2)
                                    //console.log(TransactionsListArr[elementsIndex].discount_cost)
                                }
                            }
                            else
                            {
                                var NumProc = Object.assign({},store.getters.userProcSettings.find(o => o.dtDateStart <= transactions[key].time.substr(0, 10) && o.dtDateEnd >= transactions[key].time.substr(0, 10)));
                                var Proc = 0
                                if(NumProc.numProcPrice !== undefined) {
                                    Proc = NumProc.numProcPrice
                                }
                                var NewPrice = (Proc/100) + 1
                                //var NewCost = parseFloat((transactions.cost * NewPrice).toFixed(2))
                                //console.log(NewPrice)
                                //CostAll = (parseFloat(CostAll) + parseFloat(NewCost))
                                //console.log(CostAll)
                                var FindeType = TransactionsListArr.find(o => o.product === transactions[key].product);
                                //console.log(FindeType)
                                if(FindeType === undefined){
                                    //console.log((transactions.cost * NewPrice))
                                    TransactionsListArr.push(
                                        new TransactionsList(
                                            transactions[key].id,
                                            transactions[key].product,
                                            transactions[key].amount,
                                            transactions[key].cost,
                                            (transactions[key].price * NewPrice).toFixed(2),
                                            (transactions[key].cost * NewPrice).toFixed(2),
                                            transactions[key].transaction,
                                            transactions[key].price,
                                            transactions[key].card_number,
                                            transactions[key].service_center,
                                            transactions[key].currency,
                                            transactions[key].time.substr(0,19),
                                            transactions[key].type,
                                            transactions[key].name
                                            
                                        )
                                    )
                                } else {
                                    //console.log(FindeType.id)
                                    const elementsIndex = TransactionsListArr.findIndex(element => element.id == FindeType.id )
                                    //var index = TransactionsListArr.indexOf(FindeType.id, 0);
                                    //console.log(elementsIndex)
                                    //console.log('FindeType.amount ' + FindeType.amount)
                                    //console.log('transactions.amount ' + transactions.amount)
                                    var amountNew = (parseFloat(FindeType.amount) + parseFloat(transactions[key].amount)).toFixed(2)
                                    //console.log('amount ' + amount)
                                    var costMath = parseFloat((transactions[key].cost * NewPrice).toFixed(2))
                                    var costNew = parseFloat(FindeType.discount_cost) + parseFloat(costMath)
                                    //console.log(parseFloat(FindeType.cost) + ' + ' + (parseFloat(transactions.cost) * NewPrice) + ' = ' costNew)
                                    //console.log(parseFloat(FindeType.discount_cost))
                                    TransactionsListArr[elementsIndex].amount = amountNew
                                    TransactionsListArr[elementsIndex].discount_cost = costNew.toFixed(2)
                                }
                            }
                        }
                    })


                    
                    //console.log(TransactionsListArr)
                    commit('loadTransactionsListFAllAnaliticaLast', TransactionsListArr)
                    //console.log(CostAll.toFixed(2))
                    //commit('loadCostAll', CostAll)
                    commit('setLoading2', false)
                    resolve();
                })
                .catch((response) => {
                    
                    commit('loadTransactionsListFAllAnaliticaLast', [])
                    commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                        //throw error
                })
                
        
            })
            

        },
        async loadFinanceByInnDB ({commit}, { inn }) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                //var inputFinance = 0

                //try {
                        //console.log(inn)
                        /* await firebase.database().ref('tblInnSumma') //root reference to your data
                        .orderByChild('INN').equalTo(inn)
                        .once('value')
                        .then(function(snapshot) {
                            var exists = (snapshot.val() !== null);
                            //console.log(snapshot.val())
                            if(exists){
                                var val = snapshot.val()

                                let nodes1 = Object.keys(val)
                                for(let key=0; key < nodes1.length; key++) {
                                    const Keystr = nodes1[key]
                                    const s = val[Keystr]
                                    //console.log(s.SUM)
                                    //console.log(val)
                                    commit('loadInputFinance', s.SUM)
                                }
                                
                                
                            } else {
                                //console.log(snapshot.val())
                                commit('loadInputFinance', 0)
                            }
                        })
                        
                    } catch (error) {
                        commit('loadInputFinance', 0)
                        commit('setError', error.message)
                        //throw error
                    } */
                
                    let dataTr = [
                        {"proc":'loadDictionary_InnSummaPG', "uid": store.getters.userUID, "uid2": store.getters.user.id, "inn": inn}]
                    axios({
                        method: 'POST',
                        headers: {
                            'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                            'Content-Type': 'application/json'
                        },
                        url: store.state.url24sr,
                        //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                        data: dataTr
    
                    })
                    .then((res) => {
                        var val = res.data
                        if(res.data.length !== 0){
                            let nodes1 = Object.keys(val)
                            for(let key=0; key < nodes1.length; key++) {
                                const Keystr = nodes1[key]
                                const s = val[Keystr]
                                //console.log(s.SUM)
                                //console.log(val)
                                commit('loadInputFinance', s.SUM)
                            }
                        } else {
                            //console.log(snapshot.val())
                            commit('loadInputFinance', 0)
                        }
                        resolve();
                    })
                    .catch((response) => {
                        commit('loadInputFinance', 0)
                        commit('setError', response.message)
                        reject(response)
                    })

            })

        }
        
    },

    
    getters: {
        TransactionsListFAnalitica (state) {
            return state.transactionslistFAnalitica
        },
        TransactionsListFAnaliticaLast (state) {
            return state.transactionslistFAnaliticaLast
        },
        TransactionsListF (state) {
            return state.transactionslistF
        },
        CostAllF (state) {
            var formatter = new StringMask('# ##0.00', {reverse: true});
            var s1 = parseFloat(state.costAll)
            //console.log(parseFloat(state.costAll))
            var s = s1.toFixed(2).toString()
            if (s.indexOf('.') > -1)
            {
                s = s.replace('.','') 
            } else {
                s = s + '00'
            }
            //console.log(s)
            var result = formatter.apply(s); // 1.001.234,56
            //var result = formatter.apply(s)
            return result
        },
        InputFinance (state) {
            var formatter = new StringMask('# ##0.00', {reverse: true});
            //console.log(state.inputFinance.toString().replace('.',''))
            var s1 = parseFloat(state.inputFinance)
            var s = s1.toFixed(2).toString()
            if (s.indexOf('.') > -1)
            {
                s = s.replace('.','') 
            } else {
                s = s + '00'
            }
            
            //console.log(s)
            var result = formatter.apply(s); // 1.001.234,56
            //var result = formatter.apply(s)
            return result
        }
    }
}

