export default class RegionList {
    constructor (
        id,
        name,
        country_id,
        type,
        last_update,
        udt_flag
    ) {
      this.id = id
      this.name = name
      this.country_id = country_id
      this.type = type
      this.last_update = last_update
      this.udt_flag = udt_flag
    }
  }