<template>
<v-dialog v-model="dialogMsg" persistent max-width="500px">
    <v-card>
        <v-card-title >
            <v-icon large :color="dialogImgColor">{{ dialogImg }}</v-icon>
            <span class="headline" style="color:#1976d2;margin-left:0.2em;">{{ dialogHeaderText }}</span>
        </v-card-title>
        <hr />
        <v-card-text>
            <span>{{dialogText}}</span>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="primary " @click.native="closeAlertForm()">OK</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: "alertMessage",
    props: {
        DialogMsg: {
            default () {
                return false
            }
        },
        DialogImgColor: {
            default () {
                return false
            }
        },
        DialogImg: {
            default () {
                return false
            }
        },
        DialogHeaderText: {
            default () {
                return false
            }
        },
        DialogText: {
            default () {
                return false
            }
        }
    },
    data() {
        return {
            dialogMsg: this.DialogMsg,
            dialogImgColor: this.DialogImgColor,
            dialogImg: this.DialogImg,
            dialogHeaderText: this.DialogHeaderText,
            dialogText: this.DialogText
        }
    },
    methods: {
        closeAlertForm() {
            this.$root.$emit('closeAlertMessage', false)
        }
    },
    watch: {
        DialogMsg() {
            this.dialogMsg = this.DialogMsg
        },
        DialogImgColor() {
            this.dialogImgColor = this.DialogImgColor
        },
        DialogImg() {
            this.dialogImg = this.DialogImg
        },
        DialogHeaderText() {
            this.dialogHeaderText = this.DialogHeaderText
        },
        DialogText() {
            this.dialogText = this.DialogText
        }
    }
}
</script>

<style >
</style>
