
import axios from 'axios'
import User from './user_help'
import date from 'date-and-time';
import store from './store.js'
import crypto from 'crypto'

export default {
    state: {
        user: null,
        userUID: null
    },
    mutations: {
        setUser (state, payload) {
            state.user = payload
        },
        setUserUID (state, payload) {
            state.userUID = payload
        }
    },
    actions: {
        async registerUser ({commit}, {email, password}){
            commit('clearError')
            commit('setLoading', false)

            try {
                // Logic for using
                //const user = await firebase.auth().createUserWithEmailAndPassword(email,password)
                const user = [{email, password}]
                commit('setUser', new User(user.user.uid))
                commit('SetUserId', user.user.uid)
                commit('setLoading', false)
            } catch (error) {
                // error logic here
        
                commit('setLoading', false)
                commit('setError', error.message)
                throw error
            }
            
            //console.log(user)
        },
        async loginUser ({commit}, {email, password, recaptchaToken}){
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)

                /* await axios.get('http://localhost:5000/opti24-335bb/us-central1/checkRecaptcha?response=2' + recaptchaToken + '&key=OPTI24.RECA.db8a8f7584f14879a6e282a534427844', {
                    
                }) */
                axios({
                    method: 'POST',
                    headers: {
                        'key': 'OPTI24.RECA.db8a8f7584f14879a6e282a534427844',
                        'recaptchaToken': recaptchaToken,
                        'Content-Type': 'application/json'
                    },
                    //url: 'https://us-central1-opti24-335bb.cloudfunctions.net/opti24Recaptcha',
                    url: 'https://api.analyticsnode.ru/apiNode/opti24recaptcha',
                    data: []

                })
                .then(async (response) => {
                    
                    if(response.status === 200){
                        try {
                            // Logic for using
                            //const user = await firebase.auth().signInWithEmailAndPassword(email,password)
                            //firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                                //console.log(user.user)
                            /*     commit('setUser', new User(user.user.uid))
                                commit('setUserUID', idToken)
                                commit('setLoading', false)

                            }).catch(function(error) {
                                commit('setLoading', false)
                                commit('setError', error.message)
                                
                                throw error
                            }); */
                            let dataTr = [
                                {"proc":'Opti24Login', "user": email, "ps": crypto.createHash('md5').update(password).digest("hex")}]
                            axios({
                                method: 'POST',
                                headers: {
                                    'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                                    'Content-Type': 'application/json'
                                },
                                url: 'https://api.analyticsnode.ru/apiNode/opti24login',
                                //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                                data: dataTr
            
                            })
                            .then((res) => {

                                //console.log(user.user)
                                if(res.status === 200){
                                    //console.log('Check OK')
                                    //console.log(res.data[0].userid)
                                    //console.log(res.data[0].strINN)
                                    //console.log(res.data[0].tk)
                                    commit('setUser', new User(res.data[0].userid))
                                    commit('setUserUID', res.data[0].tk)
                                    commit('setLoading', false)
                                    resolve();
                                }
                                else
                                {
                                    commit('setLoading', false)
                                    commit('setError', 'Ошибка проверки пользователя')
                                    //console.log(1)
                                    resolve('Ошибка проверки пользователя');
                                    //throw 'Ошибка проверки пользователя'
                                }

                            })
                            .catch((error) => {
                                commit('setLoading', false)
                                commit('setError', error.message)
                                //console.log(error.message)
                                reject(error)
                            });

                            
                        } catch (error) {
                            // error logic here
                    
                            commit('setLoading', false)
                            commit('setError', error.message)
                            
                            reject(error)
                        }
                    } else {
                        reject('Capcha check ERROR')
                        //throw 'Capcha check ERROR'
                    }
                    
                })
            
            //console.log(user)
            })
        },
        // Logged
        /* loggedUser ({commit}, payload) {
          // Send mutation new uid used helped Class
          commit('setUser', new User(payload.uid))
        }, */
        // Logout
        async logoutUser ({commit}, {gpnurl, gpnapi, sessionid}) {
          
          const now = new Date()
          const dt = date.format(now, 'YYYY-MM-DD HH:mm:ss')
          /* axios.get(gpnurl + 'vip/v1/logoff', {
            headers: {
                'api_key': gpnapi,
                'session_id': sessionid,
                'date_time': dt
            }
          }) */
          let dataTr = [
            {"proc":'logoff', "gpnapi":gpnapi, "dt":dt, "gpnurl":gpnurl, "sessionid":sessionid, "uid": store.getters.userUID, "uid2": store.getters.user.id}]
          await axios({
            method: 'POST',
            headers: {
                'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                'Content-Type': 'application/json'
            },
            url: store.state.url24sr,
            //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
            data: dataTr

          })
          .then(() => {
            commit('setUser', null)
            // Send mutation null
            commit('setUser', null)
          })


        }
    },
    getters: {
        user (state) {
            return state.user
        },
        userUID (state) {
            return state.userUID
        },
        checkUser (state) {
            return state.user !== null
        }
    }
}