export default class TransactionsList {
    constructor (
        id,
        product,
        amount,
        cost,
        discount_price,
        discount_cost,
        transaction,
        price,
        card_number,
        service_center,
        currency,
        time,
        type,
        name
    ) {
      this.id = id
      this.product = product
      this.amount = amount
      this.cost = cost
      this.discount_price = discount_price
      this.discount_cost = discount_cost
      this.transaction = transaction
      this.price = price
      this.card_number = card_number
      this.service_center = service_center
      this.currency = currency
      this.time = time
      this.type = type
      this.name = name
    }
  }