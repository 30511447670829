import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import vuetify from './plugins/vuetify'

import StorageLS from 'vue-ls';

let options = {
  namespace: 'vuejs__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
};

Vue.use(StorageLS, options);

Vue.component('auto-logout', require('./components/AutoLogout.vue').default);

/* import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/messaging'
import 'firebase/storage' */

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  created(){
    // Your web app's Firebase configuration
    /* var config = {
      apiKey: 'AIzaSyCjFio0y1ijrhDaG9p6au5IGtwjmIOu31c',
      authDomain: 'opti24-335bb.firebaseapp.com',
      databaseURL: 'https://opti24-335bb.firebaseio.com',
      projectId: 'opti24-335bb',
      storageBucket: 'opti24-335bb.appspot.com',
      messagingSenderId: '212871906000',
      appId: '1:212871906000:web:fcaf723b48daf6d6e3d4d5'
    };
    // Initialize Firebase
    firebase.initializeApp(config); */

    // Auth Check
    /* firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // Check Logged
        //console.log(user)
        this.$store.dispatch('loggedUser', user)
        
      }
    }) */
  },
  render: h => h(App)
}).$mount('#app')
