export default class CardGroup {
    constructor (
        id,
        name,
        contract_id,
        cards_count,
        status
    ) {
      this.id = id
      this.name = name
      this.contract_id = contract_id
      this.cards_count = cards_count
      this.status = status
    }
  }