import axios from 'axios'
import date from 'date-and-time';

import router from '../router/router.js'
import store from './store.js'
import ProductTypeList from './productTypeList_help.js'

export default {
    state: {
        productTypeList: [],
    },
    mutations: {
        loadProductTypeList (state, payload) {
            state.productTypeList = payload
        },
    },
    actions: {
        async loadProductTypeList ({commit}, { gpnapi, sessionid, gpnurl }) {
            commit('clearError')
            commit('setLoading', true)

            const now = new Date();
            const dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
            //1-CK231
            //1-35FMHF7
            axios.get(gpnurl + 'vip/v1/getDictionary?name=ProductType', {
                headers: {
                    'api_key': gpnapi,
                    'session_id': sessionid,
                    'date_time': dt
                }
            })
            .then((response) => {
                console.log(response.data.data.result)
                var prod = response.data.data.result
                //var picked = prod.find(o => o.id === '1-35FMHF7');
                //console.log(picked)
                commit('loadProductTypeList', prod)
                commit('setLoading', false)
            })
            .catch((response) => {
                var err = Object.assign({}, response)
                console.log('error', err);

                commit('loadProductTypeList', [])
                commit('setLoading', false)
                commit('setError', response)

                if(err.response.status == '403')
                {
                    commit('setUser', null)
                    router.push('/')
                }
                if(err.response.status == '401')
                {
                    commit('setUser', null)
                    router.push('/')
                }
            });

            

        },
        async loadProductTypeListDB ({commit}) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)
    
                let dataTr = [
                    {"proc":'loadDictionary_ProductTypePG', "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    //const product = await firebase.database().ref('productTypeList').once('value')
                    const products = res.data
                    
                    const productArray = []
                    Object.keys(products).forEach(key => {
                        const s = products[key]
                        productArray.push(
                            new ProductTypeList(
                                s.id,
                                s.code,
                                s.name,
                                s.unit,
                                s.last_update,
                                
                            )
                        )
                    })
                    //console.log(productArray)
                    commit('loadProductTypeList', productArray)
                    commit('setLoading', false)
                    resolve();
                })
                .catch((response) => {
                    var err = Object.assign({}, response)
                    console.log('error', err.response);

                    commit('loadProductTypeList', [])
                    commit('setLoading', false)
                    commit('setError', response)
                    if(err.response.status == '400')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '403')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '401')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    reject(response)
                });

            })

        },
        async deleteProductTypeListDB ({commit}) {
            return new Promise((resolve) => {
                commit('clearError')
                commit('setLoading', false)
                    resolve();
                /* firebase.database().ref('productTypeList').remove()
                .then(() => {
                    // http success, call the mutator and change something in state
                    commit('setLoading', false)
                    resolve();  // Let the calling function know that http is done. You may send some data back
                }, error => {
                    // http failed, let the calling function know that action did not work out
                    commit('setLoading', false)
                    commit('setError', error)
                    reject(error);
                }) */
            })

            

        },
        async insertProductTypeList ({commit}, { gpnapi, sessionid, gpnurl }) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)

                const now = new Date();
                const dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
                
                /* axios.get(gpnurl + 'vip/v1/getDictionary?name=ProductType', {
                    headers: {
                        'api_key': gpnapi,
                        'session_id': sessionid,
                        'date_time': dt
                    }
                }) */
                let dataTr = [
                    {"proc":'getDictionary_ProductTypePG', "gpnapi":gpnapi, "dt":dt, "gpnurl":gpnurl, "sessionid":sessionid, "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then(() => {
                    //console.log(response.data.data.result)

                    //const productstGet = response.data.data.result
                    //const cardSatatusEmpty = []
                    //firebase.database().ref('cardStatus').remove()
                    
                   /*  Object.keys(productstGet).forEach(key => {
                        const s = productstGet[key]
                        const productArray = []
                        //console.log(s)
                        productArray.push(
                            new ProductTypeList(
                                s.id,
                                s.code,
                                s.name,
                                s.unit,
                                s.last_update,
                                
                            )
                        )
                        firebase.database().ref('productTypeList').push(productArray[0])
                    }) */
                    
                    //firebase.database().ref('cardStatus').push(response.data.data.result)
                    //commit('loadCardStatus', response.data.data.result)
                    commit('setLoading', false)
                    resolve()
                    //reject(response)
                })
                .catch((response) => {
                    var err = Object.assign({}, response)
                    console.log(err.response);

                    //commit('loadCardStatus', [])
                    commit('setLoading', false)
                    commit('setError', response)
                    if(err.response.status == '400')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '403')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '401')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    reject(response)
                });

            })

        },
    },
    getters: {
        productTypeList (state) {
            return state.productTypeList
        },
        productTipeListFiltr (state) {
            //console.log(state.productTypeList)
            return typeID => state.productTypeList.filter(name => {
                return name.id == typeID
            })
            
        },
    }
}