export default class UserProcSettings {
    constructor (
      IDSettings,
      strName,
      numPrice,
      dtDateStart,
      dtDateEnd = '2222-12-12',
      id = null
    ) {
      this.IDSettings = IDSettings
      this.strName = strName
      this.numPrice = numPrice
      this.dtDateStart = dtDateStart
      this.dtDateEnd = dtDateEnd
      this.id = id
    }
  }