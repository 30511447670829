import axios from 'axios'
import date from 'date-and-time';

import router from '../router/router.js'
import CardsStatus from './cardStatus_help'
import store from './store.js'

export default {
    state: {
        cardStatus: [],
        cardStatusVal: ''
    },
    mutations: {
        loadCardStatus (state, payload) {
            state.cardStatus = payload
        },
        loadCardStatusVal (state, payload) {
            state.cardStatusVal = payload
        }
    },
    actions: {
        async loadCardStatus ({commit}, { gpnapi, sessionid, gpnurl }) {
            commit('clearError')
            commit('setLoading', true)

            const now = new Date();
            const dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
            
            axios.get(gpnurl + 'vip/v1/getDictionary?name=CardStatus', {
                headers: {
                    'api_key': gpnapi,
                    'session_id': sessionid,
                    'date_time': dt
                }
            })
            .then((response) => {
                //console.log(response.data.data.result)
                commit('loadCardStatus', response.data.data.result)
                commit('setLoading', false)
            })
            .catch((response) => {
                var err = Object.assign({}, response)
                console.log('error', err.response);

                commit('loadCardStatus', [])
                commit('setLoading', false)
                commit('setError', response)

                if(err.response.status == '403')
                {
                    commit('setUser', null)
                    router.push('/')
                }
                if(err.response.status == '401')
                {
                    commit('setUser', null)
                    router.push('/')
                }
            });

            

        },
        async loadCardStatusDB ({commit}) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)

                let dataTr = [
                    {"proc":'loadDictionary_CardStatusPG', "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    //console.log(res.data)
                    const statusAll = res.data
                    
                    const statusArray = []
                    Object.keys(statusAll).forEach(key => {
                        const s = statusAll[key]
                        statusArray.push(
                            new CardsStatus(
                                s.id,
                                s.value,
                                s.sid,
                                s.last_update,
                                s.udt_flag
                            )
                        )
                    })
                    //console.log(statusArray)
                    commit('loadCardStatus', statusArray)
                    commit('setLoading', false)
                    resolve();
                })
                .catch((response) => {
                    var err = Object.assign({}, response)
                    console.log('error', err.response);

                    commit('loadCardStatus', [])
                    commit('setLoading', false)
                    commit('setError', response)
                    if(err.response.status == '400')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '403')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '401')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    reject(response)
                });
            })

            

        },
        async deleteCardStatusDB ({commit}) {
            return new Promise((resolve) => {
                commit('clearError')
                commit('setLoading', false)
                resolve();
                /* firebase.database().ref('cardStatus').remove()
                .then(() => {
                    // http success, call the mutator and change something in state
                    commit('setLoading', false)
                    resolve();  // Let the calling function know that http is done. You may send some data back
                }, error => {
                    // http failed, let the calling function know that action did not work out
                    commit('setLoading', false)
                    commit('setError', error)
                    reject(error);
                })*/
            }) 

            

        },
        async insertCardStatus ({commit}, { gpnapi, sessionid, gpnurl }) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)

                const now = new Date();
                const dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
                
                /* axios.get(gpnurl + 'vip/v1/getDictionary?name=CardStatus', {
                    headers: {
                        'api_key': gpnapi,
                        'session_id': sessionid,
                        'date_time': dt
                    }
                }) */
                let dataTr = [
                    {"proc":'getDictionary_CardStatusPG', "gpnapi":gpnapi, "dt":dt, "gpnurl":gpnurl, "sessionid":sessionid, "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then(() => {
                    //console.log(response.data.data.result)
                    
                    //const cardsStatusGet = response.data.data.result
                    //const cardSatatusEmpty = []
                    //firebase.database().ref('cardStatus').remove()
                    /* Object.keys(cardsStatusGet).forEach(key => {
                        const s = cardsStatusGet[key]
                        console.log(s)
                        //firebase.database().ref('cardStatus').push(s)
                    }) */
                    //firebase.database().ref('cardStatus').push(response.data.data.result)
                    //commit('loadCardStatus', response.data.data.result)
                    commit('setLoading', false)
                    resolve()
                })
                .catch((response) => {
                    var err = Object.assign({}, response)
                    console.log('error', err.response);

                    //commit('loadCardStatus', [])
                    commit('setLoading', false)
                    commit('setError', response)
                    if(err.response.status == '400')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '403')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '401')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    reject(response)
                });

            })

        },
        async selectCardStatus ({commit}, status) {
            return new Promise((resolve, reject) => {
                try {
                    commit('clearError')
                    commit('setLoading', true)
                    console.log(status)
                    //var ref = firebase.database().ref("cardStatus");
                    var val =''
                    /* ref.orderByChild("id").equalTo(status).on("child_added", function(snapshot) {
                            //console.log(snapshot.val());
                            console.log(snapshot.val())
                            val = snapshot.val().value
                            //commit('loadCardStatusVal',snapshot.val().value)
                    })*/
                    /* var ref = firebase.database().ref('cardStatus'); //root reference to your data
                    ref.orderByChild('id').equalTo('Active').once('value')
                    .then(function(snapshot) {
                        var exists = (snapshot.val() !== null);
                        if(exists){
                            snapshot.forEach(function(child) {
                                console.log(child.val()) // NOW THE CHILDREN PRINT IN ORDER
                                commit('loadCardStatus', response.data.data.result)
                            })
                        }                
                    }) */

                    commit('setLoading', false)
                    resolve(val)
                } catch {
                    reject()
                }
            })
        },


        

    },
    getters: {
        cardStatus (state) {
            return state.cardStatus
        },
        cardStatusVal (state) {
            return state.cardStatusVal
        }
    }
}