import axios from 'axios'
import date from 'date-and-time';

import router from '../router/router.js'
import store from './store.js'
import CardsRestrictions from './cardRestrictionsList_help.js'
export default {
    state: {
        cardRestrictions: [],
    },
    mutations: {
        loadCardRestrictions (state, payload) {
            state.cardRestrictions = payload
        },
    },
    actions: {
        async loadCardRestrictions ({commit}, { gpnapi, sessionid, contractid, cardid, gpnurl }) {
            commit('clearError')
            commit('setLoadingRestrict', true)
            if(cardid !== undefined) {
                const cardsRestrictions = []

                const now = new Date();
                const dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
                
                /* axios.get(gpnurl + 'vip/v1/restriction?contract_id='+ contractid + '&card_id=' + cardid, {
                    headers: {
                        'api_key': gpnapi,
                        'session_id': sessionid,
                        'date_time': dt
                    }
                }) */
                let dataTr = [
                    {"proc":'restriction', "gpnapi":gpnapi, "dt":dt, "gpnurl":gpnurl, "contractid":contractid, "cardid":cardid, "sessionid":sessionid, "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                await axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((response) => {
                    //console.log(response.data.data.result)
                    const cardsRestrictionsGet = response.data.data.result
                    Object.keys(cardsRestrictionsGet).forEach(key => {
                        const s = cardsRestrictionsGet[key]
                        var RestStr = ''
                        if(s.restriction_type == 1) {
                            RestStr = 'Разрешено'
                        }
                        if(s.restriction_type == 2) {
                            RestStr = 'Запрещено'
                        }
                        if(s.restriction_type == 3) {
                            RestStr = 'Тип R'
                        }
                        //console.log(picked);
                        cardsRestrictions.push(
                            new CardsRestrictions(
                                RestStr,
                                s.productTypeName,
                                s.productGroupName,
                                s.productType,
                                s.productGroup,
                                s.contract_id,
                                s.id
                            )
                        )
                    })

                    commit('loadCardRestrictions', cardsRestrictions)
                    commit('setLoadingRestrict', false)
                })
                .catch((response) => {
                    var err = Object.assign({}, response)
                    console.log('error', err.response);

                    commit('loadCardRestrictions', [])
                    commit('setLoadingRestrict', false)
                    commit('setError', response)
                    if(err.response.status == '400')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '403')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '401')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                });
            } else {
                commit('loadCardRestrictions', [])
                commit('setLoadingRestrict', false)
            }
            

        },
        deleteRestrictions ({getters}, Restrictionsid) {
            var GetRestrictions = getters.cardRestrictions
            //console.log(ff)
            for (var i = GetRestrictions.length; i--;) {
                if (GetRestrictions[i].id === Restrictionsid) {
                    console.log(GetRestrictions[i].id)
                    GetRestrictions.splice(i, 1);
                }
            }
        }
    },
    getters: {
        cardRestrictions(state) {
            return state.cardRestrictions
        }
    }
}