export default class CardsStatus {
    constructor (
        id,
        value,
        sid,
        last_update,
        udt_flag
    ) {
      this.id = id
      this.value = value
      this.sid = sid
      this.last_update = last_update
      this.udt_flag = udt_flag
    }
  }