export default class ProductTypeList {
    constructor (
        id,
        code,
        name,
        unit,
        last_update
    ) {
      this.id = id
      this.code = code
      this.name = name
      this.unit = unit
      this.last_update = last_update
    }
  }