import axios from 'axios'
import date from 'date-and-time';

import router from '../router/router.js'
import CardGroup from './cardGroupList_help.js'
import store from './store.js'
export default {
    state: {
        cardgrouplist: [],
    },
    mutations: {
        loadCardGroups (state, payload) {
            state.cardgrouplist = payload
        },
    },
    actions: {
        async loadCardGroup ({commit}, { gpnapi, sessionid, contractid, gpnurl }) {
            return new Promise((resolve, reject) => {
            commit('clearError')
            commit('setLoading', true)

            const now = new Date();
            const dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
            
            axios.get(gpnurl + 'vip/v1/cardGroups?contract_id=+' + contractid, {
                headers: {
                    'api_key': gpnapi,
                    'session_id': sessionid,
                    'date_time': dt
                }
            })
            .then((response) => {
                //console.log(response.data.data.result)
                commit('loadCardGroups', response.data.data.result)
                commit('setLoading', false)
                resolve()
            })
            .catch((response) => {
                var err = Object.assign({}, response)
                console.log('error', err.response);

                commit('loadCardGroups', [])
                commit('setLoading', false)
                commit('setError', response)

                if(err.response.status == '403')
                {
                    commit('setUser', null)
                    router.push('/')
                }
                if(err.response.status == '401')
                {
                    commit('setUser', null)
                    router.push('/')
                }
                reject()
            });
        })
            

        },
        async loadCardGroupDB ({commit}) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)

                let dataTr = [
                    {"proc":'loadDictionary_cardGroupsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    //const CardGroupGet = await firebase.database().ref('СardGroupList').once('value')
                    const CardGroups = res.data
                    
                    const CardGroupArray = []
                    Object.keys(CardGroups).forEach(key => {
                        const s = CardGroups[key]
                        CardGroupArray.push(
                            new CardGroup(
                                s.id,
                                s.name,
                                s.contract_id,
                                s.cards_count,
                                s.status
                            )
                        )
                    })
                    //console.log(countrysArray)
                    commit('loadCardGroups', CardGroupArray)
                    //commit('setLoading', false)
                    resolve();
                })
                .catch((response) => {
                    var err = Object.assign({}, response)
                    console.log('error', err.response);

                    commit('loadCardGroups', [])
                    commit('setLoading', false)
                    commit('setError', response)
                    if(err.response.status == '400')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '403')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '401')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    reject(response)
                });

            })

        },
        async deleteCardGroup ({commit}) {
            return new Promise((resolve) => {
                commit('clearError')
                commit('setLoading', false)
                resolve();
                /* firebase.database().ref('СardGroupList').remove()
                .then(() => {
                    // http success, call the mutator and change something in state
                    commit('setLoading', false)
                    resolve();  // Let the calling function know that http is done. You may send some data back
                }, error => {
                    // http failed, let the calling function know that action did not work out
                    commit('setLoading', false)
                    commit('setError', error)
                    reject(error);
                }) */
            })

            

        },
        async insertCardGroup ({commit}, { gpnapi, sessionid, contractid, gpnurl }) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)

                const now = new Date();
                const dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
                
                /* axios.get(gpnurl + 'vip/v1/cardGroups?contract_id=' + contractid, {
                    headers: {
                        'api_key': gpnapi,
                        'session_id': sessionid,
                        'date_time': dt
                    }
                }) */
                let dataTr = [
                    {"proc":'cardGroupsPG', "gpnapi":gpnapi, "dt":dt, "gpnurl":gpnurl, "contractid":contractid, "sessionid":sessionid, "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then(() => {
                    //console.log(response.data.data.result)
                    
                    /* const CardGroupListGet = response.data.data.result
                    //const cardSatatusEmpty = []
                    //firebase.database().ref('cardStatus').remove()
                    Object.keys(CardGroupListGet).forEach(key => {
                        const s = CardGroupListGet[key] 
                        firebase.database().ref('СardGroupList').push(s)
                    }) */
                    //firebase.database().ref('cardStatus').push(response.data.data.result)
                    //commit('loadCardStatus', response.data.data.result)
                    commit('setLoading', false)
                    resolve()
                })
                .catch((response) => {
                    var err = Object.assign({}, response)
                    console.log('error', err.response);

                    //commit('loadCardStatus', [])
                    commit('setLoading', false)
                    commit('setError', response)
                    if(err.response.status == '400')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '403')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '401')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    reject(response)
                });

            })

        },
    },
    getters: {
        cardGroups (state) {
            return state.cardgrouplist
        },
        cardGroupsFilter (state, getters) {
            //console.log(getters.groupSettings)
            return state.cardgrouplist.filter(name => {
                return name.name == getters.groupSettings
              })
        },
    }
}