export default class CardsRestrictions {
    constructor (
        restriction_type,
        productTypeName,
        productGroupName,
        productType,
        productGroup,
        contract_id,
        id
    ) {
      this.restriction_type = restriction_type
      this.productTypeName = productTypeName
      this.productGroupName = productGroupName
      this.productType = productType
      this.productGroup = productGroup
      this.contract_id = contract_id
      this.id = id
    }
  }