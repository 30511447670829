import axios from 'axios'
import date from 'date-and-time';

import router from '../router/router.js'
import CardsList from './cardsList_help'
import CardsListDB from './cardsListDB_help'
import store from './store.js'


export default {
    state: {
        cardslist: [],
        cardslistAll: [],
    },
    mutations: {
        loadCardList (state, payload) {
            state.cardslist = payload
        },
        loadCardListAll (state, payload) {
            state.cardslistAll = payload
        },
    },
    actions: {
        async loadCardList ({commit}, { gpnapi, sessionid, contractid, groupid, gpnurl }) {
            commit('clearError')
            commit('setLoading', true)

            var now = new Date();
            var dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
            const cardsArray = []
            var cardsStatus = store.getters.cardStatus
            //console.log(cardsStatus)
                            
            axios.get(gpnurl + 'vip/v1/cards?contract_id=+' + contractid + '&group_id=' + groupid, {
                headers: {
                    'api_key': gpnapi,
                    'session_id': sessionid,
                    'date_time': dt
                }
            })
            .then((response) => {
                //console.log(response);

                const cards = response.data.data.result
                
                Object.keys(cards).forEach(key => {
                    const s = cards[key]
                    var picked = cardsStatus.find(o => o.id === s.status);
                    //console.log(picked);
                    cardsArray.push(
                        new CardsList(
                            s.id,
                            s.group,
                            s.contract_id,
                            s.number.substr(0,4) + ' ' + s.number.substr(4,4) + ' ' + s.number.substr(8,4) + ' ' + s.number.substr(12),
                            picked.value,
                            s.comment,
                            s.product == "limit" ? "Лимитная карта" : "Электронный кошелек",
                            s.payment_of_tolls,
                            s.sync_group_state,
                            s.date_last_usage,
                            s.date_released,
                            s.carrier == "Plastic" ? "физическая карта" : "виртуальная карта"
                        )
                    )
                })


                commit('loadCardList', cardsArray)
                //console.log(cardsArray);
                commit('setLoading', false)
            })
            .catch((response) => {
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('loadCardList', [])
                commit('setLoading', false)
                commit('setError', response)

                if(err.response.status == '403')
                {
                    commit('setUser', null)
                    router.push('/')
                }
                if(err.response.status == '401')
                {
                    commit('setUser', null)
                    router.push('/')
                }
            });
            
            

        },
        async loadCardListAll ({commit}, { gpnapi, sessionid, contractid, groupid, gpnurl }) {
            commit('clearError')
            commit('setLoading', true)

            var now = new Date();
            var dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
            const cardsArray = []
            var cardsStatus = store.getters.cardStatus
            //console.log(cardsStatus)
                            
            axios.get(gpnurl + 'vip/v1/cards?contract_id=' + contractid + '&group_id=' + groupid, {
                headers: {
                    'api_key': gpnapi,
                    'session_id': sessionid,
                    'date_time': dt
                }
            })
            .then((response) => {
                //console.log(response);

                const cards = response.data.data.result
                
                Object.keys(cards).forEach(key => {
                    const s = cards[key]
                    var picked = cardsStatus.find(o => o.id === s.status);
                    //console.log(picked);
                    cardsArray.push(
                        new CardsList(
                            s.id,
                            s.group,
                            s.contract_id,
                            s.number.substr(0,4) + ' ' + s.number.substr(4,4) + ' ' + s.number.substr(8,4) + ' ' + s.number.substr(12),
                            picked.value,
                            s.comment,
                            s.product == "limit" ? "Лимитная карта" : "Электронный кошелек",
                            s.payment_of_tolls,
                            s.sync_group_state,
                            s.date_last_usage,
                            s.date_released,
                            s.carrier == "Plastic" ? "физическая карта" : "виртуальная карта"
                        )
                    )
                })

                cardsArray.push(
                    new CardsList(
                        null,
                        null,
                        null,
                        'Все карты',
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null
                    )
                )

                commit('loadCardListAll', cardsArray)
                //console.log(cardsArray);
                commit('setLoading', false)
            })
            .catch((response) => {
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('loadCardListAll', [])
                commit('setLoading', false)
                commit('setError', response)

                if(err.response.status == '403')
                {
                    commit('setUser', null)
                    router.push('/')
                }
                if(err.response.status == '401')
                {
                    commit('setUser', null)
                    router.push('/')
                }
            });
            
            

        },
        async deleteCardList ({commit}) {
            return new Promise((resolve) => {
                commit('clearError')
                commit('setLoading', false)
                    resolve();
                /* firebase.database().ref('CardList').remove()
                .then(() => {
                    // http success, call the mutator and change something in state
                    commit('setLoading', false)
                    resolve();  // Let the calling function know that http is done. You may send some data back
                }, error => {
                    // http failed, let the calling function know that action did not work out
                    commit('setLoading', false)
                    commit('setError', error)
                    reject(error);
                }) */
            })

            

        },
        async loadCardListDB ({commit}, { groupid }) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)

                if(groupid == -1) {
                    commit('loadCardList', [])
                    commit('setLoading', false)
                    return
                }

                //await firebase.database().ref('CardList').orderByChild("group").equalTo(groupid).once('value')

                let dataTr = [
                    {"proc":'loadDictionary_cardsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id, "groupid": groupid}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    const cardsArray = []
                    var cardsStatus = store.getters.cardStatus
                    
                    

                        const cards = res.data
                    
                        Object.keys(cards).forEach(key => {
                            const s = cards[key]
                            var picked = cardsStatus.find(o => o.id === s.status);
                            //console.log(picked);
                            cardsArray.push(
                                new CardsListDB(
                                    s.id,
                                    s.group,
                                    s.contract_id,
                                    s.number.substr(0,4) + ' ' + s.number.substr(4,4) + ' ' + s.number.substr(8,4) + ' ' + s.number.substr(12),
                                    picked.value,
                                    s.comment,
                                    s.product == "limit" ? "Лимитная карта" : "Электронный кошелек",
                                    s.carrier == "Plastic" ? "физическая карта" : "виртуальная карта",
                                    s.payment_of_tolls,
                                    s.sync_group_state
                                )
                            )
                        })
                        //console.log(1)
                        commit('loadCardList', cardsArray)
                        //console.log(cardsArray);
                        commit('setLoading', false)

                    
                    
                    
                        resolve();
                    })
                    .catch((response) => {
                        var err = Object.assign({}, response)
                        console.log('error', err.response);
    
                        commit('loadCardList', [])
                        commit('setLoading', false)
                        commit('setError', response)
                        if(err.response.status == '400')
                        {
                            commit('setUser', null)
                            router.push('/')
                        }
                        if(err.response.status == '403')
                        {
                            commit('setUser', null)
                            router.push('/')
                        }
                        if(err.response.status == '401')
                        {
                            commit('setUser', null)
                            router.push('/')
                        }
                        reject(response)
                });

            })

        },
        async loadCardListAllDB ({commit}, { groupid }) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                //commit('setLoading', true)

                if(groupid == -1) {
                    commit('loadCardListAll', [])
                    return
                }

                let dataTr = [
                    {"proc":'loadDictionary_cardsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id, "groupid": groupid}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    const cardsArray = []
                    var cardsStatus = store.getters.cardStatus
                    //console.log(groupid)
                    //const cardsGet = await firebase.database().ref('CardList').orderByChild("group").equalTo(groupid).once('value')
                    //const cardsGet = await firebase.database().ref('CardList').once('value')
                    const cards = res.data
                    
                    Object.keys(cards).forEach(key => {
                        const s = cards[key]
                        var picked = cardsStatus.find(o => o.id === s.status);
                        //console.log(picked);
                        cardsArray.push(
                            new CardsListDB(
                                s.id,
                                s.group,
                                s.contract_id,
                                s.number.substr(0,4) + ' ' + s.number.substr(4,4) + ' ' + s.number.substr(8,4) + ' ' + s.number.substr(12),
                                picked.value,
                                s.comment,
                                s.product == "limit" ? "Лимитная карта" : "Электронный кошелек",
                                s.carrier == "Plastic" ? "физическая карта" : "виртуальная карта",
                                s.payment_of_tolls,
                                s.sync_group_state
                            )
                        )
                    })
                    cardsArray.push(
                        new CardsList(
                            null,
                            null,
                            null,
                            'Все карты',
                            null,
                            null,
                            null,
                            null,
                            null,
                            null
                        )
                    )

                    commit('loadCardListAll', cardsArray)
                    //console.log(cardsArray);
                    //commit('setLoading', false)
                    resolve();
                })
                .catch((response) => {
                    var err = Object.assign({}, response)
                    console.log('error', err.response);

                    commit('loadCardListAll', [])
                    commit('setLoading', false)
                    commit('setError', response)
                    if(err.response.status == '400')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '403')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '401')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    reject(response)
                });

            })

        },
        async insertCardList ({commit}, { gpnapi, sessionid, contractid, gpnurl }) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)

                const now = new Date();
                const dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
                
                /* axios.get(gpnurl + 'vip/v1/cards?contract_id=' + contractid + '&cache=true', {
                    headers: {
                        'api_key': gpnapi,
                        'session_id': sessionid,
                        'date_time': dt
                    }
                }) */
                let dataTr = [
                    {"proc":'cardsPG', "gpnapi":gpnapi, "dt":dt, "gpnurl":gpnurl, "contractid":contractid, "sessionid":sessionid, "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then(() => {
                    //console.log(response.data.data.result)
                    
                    /* const cards = response.data.data.result
                    //const cardSatatusEmpty = []
                    //firebase.database().ref('cardStatus').remove()
                    Object.keys(cards).forEach(key => {
                        const s = cards[key] 
                        if(s.group !== null){
                            firebase.database().ref('CardList').push(s)
                        }
                        
                    }) */
                    //firebase.database().ref('cardStatus').push(response.data.data.result)
                    //commit('loadCardStatus', response.data.data.result)
                    commit('setLoading', false)
                    resolve()
                })
                .catch((response) => {
                    var err = Object.assign({}, response)
                    console.log('error', err.response);

                    //commit('loadCardStatus', [])
                    commit('setLoading', false)
                    commit('setError', response)
                    if(err.response.status == '400')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '403')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '401')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    reject(response)
                });

            })

        },
    },
    getters: {
        cardsList (state) {
            return state.cardslist
        },
        cardsListAll (state) {
            return state.cardslistAll
        },
    }
}