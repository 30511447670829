export default class CountryList {
    constructor (
        id,
        value,
        used,
        numeric,
        char,
        last_update,
        udt_flag
    ) {
      this.id = id
      this.value = value
      this.used = used
      this.numeric = numeric
      this.char = char
      this.last_update = last_update
      this.udt_flag = udt_flag
    }
  }