export default class UserSettings {
    constructor (
      strINN,
      strShortName,
      boolIsAdmin = false,
      strCardGroupName,
      numProcPrice = 0,
      username = null,
      userps = null,
      idfr = null,
      id = null
    ) {
      this.strINN = strINN
      this.strShortName = strShortName
      this.boolIsAdmin = boolIsAdmin
      this.strCardGroupName = strCardGroupName
      this.numProcPrice = numProcPrice
      this.username = username
      this.userps = userps
      this.idfr = idfr
      this.id = id
    }
  }