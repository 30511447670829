import store from './store.js'
import axios from 'axios'
import UserProductSettings from './userProductSettings_help'


export default {
    state: {
        ProductSettings: [],
        userProductSettings: [],
    },
    mutations: {
        loadProductSettings (state, payload) {
            state.productSettings = payload
        },
        loadUserProductSettings (state, payload) {
            state.userProductSettings = payload
        },
        editProductSettings (state, {IDSettings, strName, numPrice, dtDateStart, dtDateEnd, id}) {
          const prodSetting = state.productSettings.find(t => {
            return t.id === id
          })
          //console.log(prodSetting)
          prodSetting.IDSettings = IDSettings
          prodSetting.strName = strName
          prodSetting.numPrice = numPrice
          prodSetting.dtDateStart = dtDateStart
          prodSetting.dtDateEnd = dtDateEnd
        },
        newProductSettings (state, payload) {
            state.productSettings.push(payload)
        }
    },
    actions: {
        async loadProductSettings ({commit}, IDSettings) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading2', true)
                /* try {
                    const prodSetting = await firebase.database().ref('userProductSettings').orderByChild("IDSettings").equalTo(IDSettings).once('value')
                    const ProductSettings = prodSetting.val()
                    //console.log(ProductSettings)
                    const ProductSettingsArray = []
                    Object.keys(ProductSettings).forEach(key => {
                        const s = ProductSettings[key]
                        ProductSettingsArray.push(
                            new UserProductSettings(
                                s.IDSettings,
                                s.strName,
                                s.numPrice,
                                s.dtDateStart,
                                s.dtDateEnd,
                                key
                            )
                        )
                    })
                    //console.log(ProductSettingsArray)
                    commit('loadProductSettings', ProductSettingsArray)
                    commit('setLoading2', false)
                } catch (error) {
                    commit('loadProductSettings', [])
                    commit('setLoading2', false)
                    commit('setError', error.message)
                    //throw error
                } */
                let dataTr = [
                    {"proc":'loadDictionary_ProductSettingsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id, "IDSettings":IDSettings}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    const ProductSettings = res.data
                    //console.log(ProductSettings)
                    const ProductSettingsArray = []
                    Object.keys(ProductSettings).forEach(key => {
                        const s = ProductSettings[key]
                        ProductSettingsArray.push(
                            new UserProductSettings(
                                s.IDSettings,
                                s.strName,
                                s.numPrice,
                                s.dtDateStart,
                                s.dtDateEnd,
                                s.idfr
                            )
                        )
                    })
                    //console.log(ProductSettingsArray)
                    commit('loadProductSettings', ProductSettingsArray)
                    commit('setLoading2', false)
                    resolve();
                })
                .catch((response) => {
                    commit('loadProductSettings', [])
                    commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                });
            })

        },
        async loadUserProductSettings ({commit}, IDSettings) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                //commit('setLoading2', true)
                /* try {
                    const prodSetting = await firebase.database().ref('userProductSettings').orderByChild("IDSettings").equalTo(IDSettings).once('value')
                    const ProductSettings = prodSetting.val()
                    //console.log(ProductSettings)
                    const ProductSettingsArray = []
                    Object.keys(ProductSettings).forEach(key => {
                        const s = ProductSettings[key]
                        ProductSettingsArray.push(
                            new UserProductSettings(
                                s.IDSettings,
                                s.strName,
                                s.numPrice,
                                s.dtDateStart,
                                s.dtDateEnd,
                                key
                            )
                        )
                    })
                    //console.log(ProductSettingsArray)
                    commit('loadUserProductSettings', ProductSettingsArray)
                    //commit('setLoading2', false)
                } catch (error) {
                    commit('loadUserProductSettings', [])
                    //commit('setLoading2', false)
                    commit('setError', error.message)
                    //throw error
                } */
                let dataTr = [
                    {"proc":'loadDictionary_ProductSettingsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id, "IDSettings":IDSettings}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    const ProductSettings = res.data
                    //console.log(ProductSettings)
                    const ProductSettingsArray = []
                    Object.keys(ProductSettings).forEach(key => {
                        const s = ProductSettings[key]
                        ProductSettingsArray.push(
                            new UserProductSettings(
                                s.IDSettings,
                                s.strName,
                                s.numPrice,
                                s.dtDateStart,
                                s.dtDateEnd,
                                s.idfr
                            )
                        )
                    })
                    //console.log(ProductSettingsArray)
                    commit('loadUserProductSettings', ProductSettingsArray)
                    //commit('setLoading2', false)
                    resolve();
                })
                .catch((response) => {
                    commit('loadUserProductSettings', [])
                    //commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                });

            })

        },
        async editProductSettings ({commit}, {IDSettings, strName, numPrice, dtDateStart, dtDateEnd, id}) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading2', true)
                /* try {
                
                    await firebase.database().ref('userProductSettings').child(id).update({
                        IDSettings,
                        strName,
                        numPrice,
                        dtDateStart,
                        dtDateEnd
                    })
                    // Send mutation
                    commit('editProductSettings', {IDSettings, strName, numPrice, dtDateStart, dtDateEnd, id})
            
                    commit('setLoading2', false)
                        
                } catch (error) {
                commit('setLoading2', false)
                commit('setError', error.message)
                throw error
                } */
                let dataTr = [
                    {"proc":'updateDictionary_userProductSettingsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id,
                    "IDSettings": IDSettings,"strName": strName,"numPrice": numPrice,"dtDateStart": dtDateStart,"dtDateEnd": dtDateEnd,
                    "idfr": id}]
                //console.log(dataTr)
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then(() => {
                    //console.log(res)
                    // Send mutation
                    commit('editProductSettings', {IDSettings, strName, numPrice, dtDateStart, dtDateEnd, id})
                    commit('setLoading2', false)
                    resolve();
                })
                .catch((response) => {
                    commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                    //throw error
                })
            })
        },
        async deleteProductSettings ({commit}, id) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading2', true)
                //console.log(id)
                /* try {
                await firebase.database().ref('userProductSettings').child(id).remove()
        
                commit('setLoading2', false)
                } catch (error) {
                commit('setLoading2', false)
                commit('setError', error.message)
                throw error
                } */
                let dataTr = [
                    {"proc":'deleteDictionary_userProductSettingsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id,
                    "idfr": id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    // Send mutation
                   /*  commit('newSettings', {
                        ...newUserSettings
                    }) */

                    
            
                    commit('setLoading2', false)
                    resolve(res);
                })
                .catch((response) => {
                    commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                    //throw error
                })
            })
        },
        async newProductSettings ({commit}, payload) {
            //console.log('13 ' + getters.UserId)
            return new Promise((resolve, reject) => {
            commit('clearError')
            commit('setLoading2', true)
            
            /* try {
                // Used helped class
                
                const newProductSettings = new UserProductSettings(
                    payload.IDSettings,
                    payload.strName,
                    payload.numPrice,
                    payload.dtDateStart,
                    payload.dtDateEnd
                )
                //console.log(newProductSettings)
                // Push newTask
                var FoundName = false
                var FoundStart = false
                var FoundEnd = false
                var ref = firebase.database().ref("userProductSettings");
                await ref.orderByChild("strName").equalTo(payload.strName).once("value")
                .then(async function(snapshot) {
                    //console.log(snapshot.val());
                    if(snapshot.val() !== null){
                        
                        const foundAll = snapshot.val()
                        
                        const foundArray = []
                        Object.keys(foundAll).forEach(key => {
                            const s = foundAll[key]
                            foundArray.push(
                                new UserProductSettings(
                                    s.IDSettings,
                                    s.strName,
                                    s.numPrice,
                                    s.dtDateStart,
                                    s.dtDateEnd
                                )
                            )
                        })
                        
                        var FindeType = foundArray.find(o => o.IDSettings == payload.IDSettings);
                        //console.log(FindeType)
                        if(FindeType !== undefined)
                        {
                            FoundName = false
                        }
                    }
                    await ref.orderByChild("dtDateStart").equalTo(payload.dtDateStart).once("value")
                    .then(async function(snapshot) {
                        //console.log(snapshot.val());
                        if(snapshot.val() !== null){
                            
                            const foundAll = snapshot.val()
                            
                            const foundArray = []
                            Object.keys(foundAll).forEach(key => {
                                const s = foundAll[key]
                                foundArray.push(
                                    new UserProductSettings(
                                        s.IDSettings,
                                        s.strName,
                                        s.numPrice,
                                        s.dtDateStart,
                                        s.dtDateEnd
                                    )
                                )
                            })
                            
                            var FindeType = foundArray.find(o => o.IDSettings == payload.IDSettings && o.strName == payload.strName);
                            //console.log(FindeType)
                            if(FindeType !== undefined)
                            {
                                FoundStart = true
                            }
                        }
                    
                        //console.log(FoundStart)

                        await ref.orderByChild("dtDateEnd").equalTo(payload.dtDateEnd).once("value")
                        .then(async function(snapshot) {
                            //console.log(snapshot.val());
                            if(snapshot.val() !== null){
                                const foundAll = snapshot.val()
                            
                                const foundArray = []
                                Object.keys(foundAll).forEach(key => {
                                    const s = foundAll[key]
                                    foundArray.push(
                                        new UserProductSettings(
                                            s.IDSettings,
                                            s.strName,
                                            s.numPrice,
                                            s.dtDateStart,
                                            s.dtDateEnd
                                        )
                                    )
                                })
                                
                                var FindeType = foundArray.find(o => o.IDSettings == payload.IDSettings && o.strName == payload.strName);
                                //console.log(FindeType)
                                if(FindeType !== undefined)
                                {
                                    FoundEnd = true
                                }
                            }

                            if(FoundName === false && FoundStart === false && FoundEnd === false)
                            {
                                const setting = await firebase.database().ref('userProductSettings').push(newProductSettings)
                        
                                // Send mutation
                                commit('newProductSettings', {
                                ...newProductSettings,
                                id: setting.key
                                })
                        
                                commit('setLoading2', false)
                            } else {
                                commit('setLoading2', false)
                                commit('setError', 'Настройка с указанными параметрами уже существует!')
                                var error = []
                                error.message = 'Настройка с указанными параметрами уже существует!'
                                throw error
                            }
                        });    
                    
                    });
                    
                });
                

                
               } catch (error) {
                commit('setLoading2', false)
                commit('setError', error.message)
                throw error
              }  */
                const newProductSettings = new UserProductSettings(
                    payload.IDSettings,
                    payload.strName,
                    payload.numPrice,
                    payload.dtDateStart,
                    payload.dtDateEnd
                )
                let dataTr = [
                {"proc":'insertDictionary_userProductSettingsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id,
                "newProductSettings": newProductSettings}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    // Send mutation
                   /*  commit('newProductSettings', {
                        ...newProductSettings,
                        id: setting.key
                    }) */

                    
            
                    commit('setLoading2', false)
                    resolve(res);
                })
                .catch((response) => {
                    commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                    //throw error
                })
            })
        }
    },
    getters: {
        productSettings (state) {
            return state.productSettings
        },
        userProductSettings (state) {
            return state.userProductSettings
        }
    }
}