import axios from 'axios'
import date from 'date-and-time';

import router from '../router/router.js'
import store from './store.js'
import CardsRegionLimits from './cardRegionLimitList_help.js'

export default {
    state: {
        cardRegionLimitList: [],
    },
    mutations: {
        loadCardRegionLimitList (state, payload) {
            state.cardRegionLimitList = payload
        },
    },
    actions: {
        async loadCardRegionLimitList ({commit}, { gpnapi, sessionid, contractid, cardid, gpnurl }) {
            commit('clearError')
            commit('setLoadingRegionLimit', true)
            if(cardid !== undefined) {
                var countrys = store.getters.countryList
                var regions = store.getters.RegionList
                //console.log(regions)
                const cardsRegionLimitList = []

                const now = new Date();
                const dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');

                //console.log(cardid)
                
                /* await axios.get(gpnurl + 'vip/v1/regionLimit?contract_id='+ contractid + '&card_id=' + cardid, {
                    headers: {
                        'api_key': gpnapi,
                        'session_id': sessionid,
                        'date_time': dt
                    }
                }) */
                let dataTr = [
                    {"proc":'regionLimit', "gpnapi":gpnapi, "dt":dt, "gpnurl":gpnurl, "contractid":contractid, "cardid":cardid, "sessionid":sessionid, "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                await axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then(async(response) => {
                    //console.log(response.data.data.result)
                    const cardsRegionLimitListGet = response.data.data.result
                    let nodes = Object.keys(cardsRegionLimitListGet)
                    for(let key=0; key < nodes.length; key++) {
                        const s = cardsRegionLimitListGet[key]
                        //console.log(picked);
                        var RestStr = ''
                        if(s.limit_type == 1) {
                            RestStr = 'Разрешено'
                        }
                        if(s.limit_type == 2) {
                            RestStr = 'Запрещено'
                        }
                        var country_name = countrys.find(o => o.id === s.country);
                        var region_name = 'Все регионы'
                        if(s.region !== null) {
                            //console.log(s.region)
                            var region_nameArr = regions.find(o => o.id === s.region);
                            region_name = region_nameArr.name
                        }
                        
                        
                        if (typeof this.service_centerid !== "undefined") {
                            console.log(s.service_centerid)
                            
                                await axios.get(gpnurl + 'vip/v1/AZS?id='+ s.service_centerid, {
                                    headers: {
                                        'api_key': gpnapi,
                                        'session_id': sessionid,
                                        'date_time': dt
                                    }
                                })
                                .then(async (response) => {
                                    console.log(response)
                                    cardsRegionLimitList.push(
                                        new CardsRegionLimits(
                                            s.id,
                                            s.card_id,
                                            s.group_id,
                                            s.contract_id,
                                            s.country,
                                            s.region,
                                            s.service_centerid,
                                            s.date,
                                            s.limit_type,
                                            RestStr,
                                            country_name.value,
                                            region_name,
                                            response.data.data.result.searchTxt
                                        )
                                    )
                                })
                                .catch((response) => {
                                    var err = Object.assign({}, response)
                                    console.log('error', err.response);
                    
                                    commit('loadCardRegionLimitList', [])
                                    commit('setLoading2', false)
                                    commit('setError', response)
                    
                                    if(err.response.status == '403')
                                    {
                                        commit('setUser', null)
                                        router.push('/')
                                    }
                                    if(err.response.status == '401')
                                    {
                                        commit('setUser', null)
                                        router.push('/')
                                    }
                                });
                            
                            
                        } else {
                            //console.log('No AZS')
                            cardsRegionLimitList.push(
                                new CardsRegionLimits(
                                    s.id,
                                    s.card_id,
                                    s.group_id,
                                    s.contract_id,
                                    s.country,
                                    s.region,
                                    s.service_centerid,
                                    s.date,
                                    s.limit_type,
                                    RestStr,
                                    country_name.value,
                                    region_name,
                                    'Все АЗС'
                                )
                            )
                        }
                        
                    }
                    //console.log(cardsRegionLimitList)
                    commit('loadCardRegionLimitList', cardsRegionLimitList)
                    commit('setLoadingRegionLimit', false)
                })
                .catch((response) => {
                    var err = Object.assign({}, response)
                    console.log('error', err.response);

                    commit('loadCardRegionLimitList', [])
                    commit('setLoadingRegionLimit', false)
                    commit('setError', response)
                    if(err.response.status == '400')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '403')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '401')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                });

            } else {
                commit('loadCardRegionLimitList', [])
                commit('setLoadingRegionLimit', false)
            }

        },
        deleteCardRegionLimit({getters}, CardRegionLimitid) {
            var GetCardRegionLimit = getters.cardRegionLimitList
            //console.log(ff)
            for (var i = GetCardRegionLimit.length; i--;) {
                if (GetCardRegionLimit[i].id === CardRegionLimitid) {
                    console.log(GetCardRegionLimit[i].id)
                    GetCardRegionLimit.splice(i, 1);
                }
            }
        }
    },
    getters: {
        cardRegionLimitList(state) {
            return state.cardRegionLimitList
        }
    }
}