export default class CurrencyList {
    constructor (
        id,
        code,
        name,
        last_update,
        udt_flag
    ) {
      this.id = id
      this.code = code
      this.name = name
      this.last_update = last_update
      this.udt_flag = udt_flag
    }
  }