<template>
    <div id="app">
        <v-app id="inspire">
            <v-card class="elevation-0 pa-2">
                
                <v-navigation-drawer
                    :mini-variant.sync="mini"
                    clipped
                    fixed
                    v-model="drawer"
                    app
                >
                
                    <v-list>
                        <v-list-item-group>
                            <!-- <v-list-item class="px-2">
                            <v-list-item-content class="d-flex justify-center">
                                <v-img src="../img/logo_2.svg" 
                                max-width="120"
                                max-height="80">
                                </v-img>
                            </v-list-item-content>
                            </v-list-item> -->
                            <v-list-item-content class="d-flex justify-center pb-0">
                               <v-list  two-line align="center" justify="center" class="pl-2 pr-2 pb-0 pt-0 ma-0">{{ this.$store.getters.UserName }}</v-list>
                            </v-list-item-content>
                            <v-list-item-content class="d-flex justify-center">
                                <div class="d-flex justify-center">
                                    <v-btn small color="primary" @click="GoOut()">Выход</v-btn>
                                </div>
                            </v-list-item-content>
                        </v-list-item-group>
                    </v-list>
                                
                    <v-divider></v-divider>
            
                    <v-list dense>
                    <v-list-item
                        v-for="item in MenuItems"
                        :key="item.title"
                        @click="insideSettings=true"
                        :to="item.path"
                        
                    >
                        <v-list-item-icon >
                        <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
            
                        <v-list-item-content > 
                        <v-list-item-title >{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list>
                </v-navigation-drawer>
            <v-app-bar color="primary" dark app fixed clipped-left >
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                <v-toolbar-title>НОВОТЭК</v-toolbar-title>
            </v-app-bar>
            
            <v-content class="elevation-0">
                <auto-logout></auto-logout>
                <v-alert dense  text   type="error"  dismissible v-model="alertOpti24" class="pa-1 ma-1">
                                Внимание! Ошибка подключения к сервису API Opti24. Требуется выполнить корректную настройку подключения!
                </v-alert>
                <router-view/>
            </v-content>
            
            </v-card>
        </v-app>
    </div>
</template>

<script>
    import AutoLogout from '../components/AutoLogout.vue'
    import Vue from 'vue'
    //import axios from 'axios'

    import Vuetify from 'vuetify'
    import 'vuetify/dist/vuetify.min.css'
    import 'material-design-icons-iconfont/dist/material-design-icons.css'
    import ru from 'vuetify/es5/locale/ru'

    Vue.use(Vuetify, {
        lang: {
            locales: { 'ru': ru },
            current: 'ru'
        }
    })

    export default {
        components: { AutoLogout },
        name: "mainScreen",
        data() {
            return {
                insideSettings: false,
                drawer: true,
                mini: false,
                items: [
                    { title: 'Администрирование', icon: 'mdi-account-cog', path: "/mainScreen/adminForm", if: this.$store.getters.IsAdmin },
                    { title: 'Справочники', icon: 'mdi-ballot-recount-outline', path: "/mainScreen/catalogForm", if: this.$store.getters.IsAdmin },
                    { title: 'Главная', icon: 'dashboard', path: "/mainScreen/analitikaForm", if: true },
                    { title: 'Список карт', icon: 'mdi-credit-card-multiple', path: "/mainScreen/cardListForm", if: true },
                    { title: 'Детальная информация', icon: 'mdi-book-information-variant', path: "/mainScreen/detailInfo", if: true },
                    { title: 'Пользователь', icon: 'mdi-account-cog', path: "/mainScreen/userChangePass", if: true },
                    
                ]
            }
        },
        methods: {
            loginUserCheck() {
                
                console.log('CLICK')
                
            },
            GoOut() {
                const setting2 = {
                    gpnapi: this.$store.state.gpn24,
                    sessionid: this.$store.getters.SessionId,
                    gpnurl: this.$store.state.url24,
                }
                Vue.ls.remove(this.$store.getters.UserMail)
                this.$store.dispatch('logoutUser', setting2)
                this.$router.push('/')
            }
        },
        computed: {
            MenuItems: function() {
                return this.items.filter(function (u) {
                    return u.if
                })
            },
            alertOpti24() {
                return this.$store.getters.AlertOpti24False
            }
        }
    }
</script>

