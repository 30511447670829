import axios from 'axios'
import date from 'date-and-time';

import router from '../router/router.js'
import store from './store.js'
import CardsLimit from './cardLimitsList_help'

export default {
    state: {
        cardlimitlist: [],
    },
    mutations: {
        loadCardLimits (state, payload) {
            state.cardlimitlist = payload
        },
    },
    actions: {
        async loadCardLimits ({commit}, { gpnapi, sessionid, contractid, cardid, gpnurl }) {
            commit('clearError')
            commit('setLoadingLimits', true)
            if(cardid !== undefined) {
                const now = new Date();
                const dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');

                const cardsLimits = []
                var cardsProductGroup = store.getters.productGroupList
                var cardsProductType = store.getters.productTypeList
                var Units = store.getters.UnitList
                var Currency = store.getters.CurrencyList
                //console.log(cardid)

                /* axios.get(gpnurl + 'vip/v1/limit?contract_id=' + contractid + '&card_id=' + cardid, {
                    headers: {
                        'api_key': gpnapi,
                        'session_id': sessionid,
                        'date_time': dt
                    }
                }) */
                let dataTr = [
                    {"proc":'limit', "gpnapi":gpnapi, "dt":dt, "gpnurl":gpnurl, "contractid":contractid, "cardid":cardid, "sessionid":sessionid, "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                await axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((response) => {
                    //console.log(cardid)
                    //console.log(response.data.data.result)

                    const cardsLimitsGet = response.data.data.result
                    
                    Object.keys(cardsLimitsGet).forEach(key => {
                        const s = cardsLimitsGet[key]
                        var picked = []
                        var nameGroup = ''
                        var nameType = ''
                        var positionOut = ''
                        if(s.productType !== null) {
                            picked = cardsProductType.find(o => o.id === s.productType);
                            //console.log(picked)
                            nameType = picked.name
                        }
                        if(s.productGroup !== null) {
                            picked = cardsProductGroup.find(o => o.id === s.productGroup);
                            //console.log(picked)
                            nameGroup = picked.name
                        }

                        //console.log(nameType)
                        //console.log(nameGroup)
                        positionOut = nameGroup
                        if(nameGroup == '')
                        {
                            positionOut = nameType
                        }
                        //console.log(positionOut)

                        var period = 'На транзакцию'
                        if(s.time.number !== 0 && s.time.type == 5) {
                            period = s.time.number + ' мес.'
                        }
                        if(s.time.number !== 0 && s.time.type == 6) {
                            period = s.time.number + ' кварт.'
                        }
                        if(s.time.number !== 0 && s.time.type == 7) {
                            period = s.time.number + ' год.'
                        }
                        if(s.time.number !== 0 && s.time.type == 2) {
                            period = s.time.number + ' разовый'
                        }
                        if(s.time.number !== 0 && s.time.type == 3) {
                            period = s.time.number + ' сут.'
                        }
                        if(s.time.number !== 0 && s.time.type == 4) {
                            period = s.time.number + ' нед.'
                        }

                        var value = 0
                        var used = 0
                        var rest = 0
                        var unitstr = ''
                        var amount = false
                        var sum = false
                        if(s.amount !== null) {
                            picked = Units.find(o => o.id === s.amount.unit);
                            //console.log(picked)
                            unitstr = ' ' + picked.value.substr(0,1)
                            console.log(unitstr)
                            value = s.amount.value
                            used = s.amount.used
                            rest = value - used
                            amount = true
                        } else {
                            picked = Currency.find(o => o.id === s.sum.currency);
                            //console.log(picked)
                            unitstr = ' ' + picked.code
                            value = s.sum.value
                            used = s.sum.used
                            rest = (value - used).toFixed(2)
                            sum = true
                        }

                        var term = false
                        var term_time = false
                        var day_about = ''
                        if(s.term.days != null)
                        {
                            term = true
                            if(s.term.days.substr(0,1) == '1')
                            {
                                day_about = day_about + 'ПН,'
                            }
                            if(s.term.days.substr(1,1) == '1')
                            {
                                day_about = day_about + 'ВТ,'
                            }
                            if(s.term.days.substr(2,1) == '1')
                            {
                                day_about = day_about + 'СР,'
                            }
                            if(s.term.days.substr(3,1) == '1')
                            {
                                day_about = day_about + 'ЧТ,'
                            }
                            if(s.term.days.substr(4,1) == '1')
                            {
                                day_about = day_about + 'ПТ,'
                            }
                            if(s.term.days.substr(5,1) == '1')
                            {
                                day_about = day_about + 'СБ,'
                            }
                            if(s.term.days.substr(6,1) == '1')
                            {
                                day_about = day_about + 'ВС'
                            }
                            if(s.term.type == 1)
                            {
                                day_about = day_about + ' Все дни '
                            }
                            if(s.term.type == 2)
                            {
                                day_about = day_about + ' Рабочие дни '
                            }
                            if(s.term.type == 3)
                            {
                                day_about = day_about + ' /gВыходные дни/g'
                            }
                            if(s.term.time != null)
                            {
                                term_time = true
                                day_about = day_about + s.term.time.from + ' - ' + s.term.time.to
                            }
                        }
                        if(day_about == '')
                        {
                            day_about = 'Без ограничений'
                        }

                        var tran = false
                        if(s.transactions.count != null)
                        {
                            tran = true
                        }

                        //console.log(picked);
                        cardsLimits.push(
                            new CardsLimit(
                                s.id,
                                s.card_id,
                                s.group_id,
                                s.contract_id,
                                amount,
                                amount == true ? s.amount.value : 0,
                                amount == true ? s.amount.used : 0,
                                amount == true ? s.amount.unit : 0,
                                s.productType,
                                s.productGroup,
                                sum,
                                sum == true ? s.sum.value : 0,
                                sum == true ? s.sum.used : 0,
                                sum == true ? s.sum.currency : 0,
                                term,
                                s.term.days,
                                s.term.type,
                                term_time,
                                term_time == true ? s.term.time.from : 0,
                                term_time == true ? s.term.time.to : 0,
                                tran,
                                s.transactions.count,
                                s.transactions.occured,
                                true,
                                s.time.number,
                                s.time.type,
                                s.date,
                                day_about,
                                positionOut,
                                period,
                                value + ' ' + unitstr,
                                used + ' ' + unitstr,
                                rest + ' ' + unitstr
                                
                            )
                        )
                    })
                    //console.log(cardsLimits)
                    
                    commit('loadCardLimits', cardsLimits)
                    commit('setLoadingLimits', false)
                })
                .catch((response) => {
                    var err = Object.assign({}, response)
                    console.log('error', err.response);

                    commit('loadCardLimits', [])
                    commit('setLoadingLimits', false)
                    commit('setError', response)
                    if(err.response.status == '400')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '403')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '401')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                });
            } else {
                commit('loadCardLimits', [])
                commit('setLoadingLimits', false)
            }
            

        },
        deleteCardLimits ({getters}, limitid) {
            var GetLimits = getters.cardLimits
            //console.log(ff)
            for (var i = GetLimits.length; i--;) {
                if (GetLimits[i].id === limitid) {
                    console.log(GetLimits[i].id)
                    GetLimits.splice(i, 1);
                }
            }
        }
    },
    getters: {
        cardLimits (state) {
            //console.log(1)
            return state.cardlimitlist
        }
    }
}