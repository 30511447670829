export default class ProductTypeList {
    constructor (
        id,
        last_update,
        name
    ) {
      this.id = id
      this.last_update = last_update
      this.name = name
    }
  }