export default class CardsListDB {
    constructor (
        id,
        group,
        contract_id,
        number,
        status,
        comment,
        product,
        carrier,
        payment_of_tolls,
        sync_group_state
    ) {
      this.id = id
      this.group = group
      this.contract_id = contract_id
      this.number = number
      this.status = status
      this.comment = comment
      this.product = product
      this.carrier = carrier
      this.payment_of_tolls = payment_of_tolls
      this.sync_group_state = sync_group_state

    }
  }