export default class UserProcSettings {
    constructor (
      IDSettings,
      numProcPrice,
      dtDateStart,
      dtDateEnd = '2222-12-12',
      id = null
    ) {
      this.IDSettings = IDSettings
      this.numProcPrice = numProcPrice
      this.dtDateStart = dtDateStart
      this.dtDateEnd = dtDateEnd
      this.id = id
    }
  }