export default {
    state: {
      loading: false,
      loading2: false,
      loadingAzs: false,
      loadingLimits: false,
      loadingRestrict: false,
      loadingRegionLimit: false,
      error: null
    },
    mutations: {
      setLoading (state, payload) {
        state.loading = payload
      },
      setLoading2 (state, payload) {
        state.loading2 = payload
      },
      setLoadingAzs (state, payload) {
        state.loadingAzs = payload
      },
      setLoadingLimits (state, payload) {
        state.loadingLimits = payload
      },
      setLoadingRestrict (state, payload) {
        state.loadingRestrict = payload
      },
      setLoadingRegionLimit (state, payload) {
        state.loadingRegionLimit = payload
      },
      setError (state, payload) {
        state.error = payload
      },
      clearError (state) {
        state.error = null
      }
    },
    actions: {
      // Show loading
      setLoading ({commit}, payload) {
        commit('setLoading', payload)
      },
      setLoading2 ({commit}, payload) {
        commit('setLoading2', payload)
      },
      setLoadingAzs ({commit}, payload) {
        commit('setLoadingAzs', payload)
      },
      setLoadingLimits ({commit}, payload) {
        commit('setLoadingLimits', payload)
      },
      setLoadingRestrict ({commit}, payload) {
        commit('setLoadingRestrict', payload)
      },
      setLoadingRegionLimit ({commit}, payload) {
        commit('setLoadingRegionLimit', payload)
      },
      // Show error
      setError ({commit}, payload) {
        commit('setError', payload)
      },
      // Clear error
      clearError ({commit}) {
        commit('clearError')
      }
    },
    getters: {
      // Get load status
      loading (state) {
        //console.log(state.loading)
        return state.loading
      },
      loading2 (state) {
        //console.log(state.loading)
        return state.loading2
      },
      loadingAzs (state) {
        //console.log(state.loading)
        return state.loadingAzs
      },
      loadingLimits (state) {
        //console.log(state.loading)
        return state.loadingLimits
      },
      loadingRestrict (state) {
        //console.log(state.loading)
        return state.loadingRestrict
      },
      loadingRegionLimit (state) {
        //console.log(state.loading)
        return state.loadingRegionLimit
      },
      // Get error message
      error (state) {
        return state.error
      }
    }
  }