import axios from 'axios'
import router from '../router/router.js'
import store from './store.js'
import Opti24Settings from './opti24settings_help'

var algorithm = 'aes-256-ctr'
var password = '33cb43f88578beff4f4cfd87'

export default {
    state: {
        opti24settings: []
    },
    mutations: {
        loadOpti24Settings (state, payload) {
            state.opti24settings = payload
        },
        editOpti24Settings (state, {ids, strUserName, strUserPassword, strKey, strKontract, id}) {
          //console.log(id)
          const setting = state.opti24settings.find(t => {
            return t.key === id
          })
          setting.id = ids
          setting.strUserName = strUserName
          setting.strUserPassword = strUserPassword
          setting.strKey = strKey
          setting.strKontract = strKontract
          setting.key = id
        },
        newOpti24Settings (state, payload) {
            state.opti24settings.push(payload)
        }  
    },
    actions: {
        async loadOpti24Settings ({commit}) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)
                let dataTr = [
                    {"proc":'loadDictionary_opti24SettingsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    //const setting24 = await firebase.database().ref('opti24Settings').once('value')
                    const settings24 = res.data
                    //console.log(settings)
                    const settings24Array = []
                    Object.keys(settings24).forEach(key => {
                        const s = settings24[key]
                        settings24Array.push(
                            new Opti24Settings(
                                s.id,
                                s.strUserName,
                                decrypt(s.strUserPassword),
                                decrypt(s.strKey),
                                s.strKontract,
                                key
                            )
                        )
                    })

                    commit('loadOpti24Settings', settings24Array)
                    commit('setLoading', false)
                    resolve();
                })
                .catch((response) => {
                    var err = Object.assign({}, response)
                    console.log('error', err.response);

                    commit('loadOpti24Settings', [])
                    commit('setLoading', false)
                    commit('setError', response)
                    //throw error
                    if(err.response.status == '400')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '403')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '401')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    reject(response)
                });
            })

        },
        async editOpti24Settings ({commit}, {ids, strUserName, strUserPassword, strKey, strKontract, id}) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)
                /* try {
                // Update title & descr
                var val = encrypt(strUserPassword)
                var val1 = encrypt(strKey)

                const newOpti24Settings = new Opti24Settings(
                    ids,
                    strUserName,
                    val,
                    val1,
                    strKontract
                )

                //console.log(newOpti24Settings)

                await firebase.database().ref('opti24Settings').child(id).update({
                    ...newOpti24Settings
                })
                // Send mutation
                commit('editOpti24Settings', {ids, strUserName, strUserPassword, strKey, strKontract, id})
        
                commit('setLoading', false)
                } catch (error) {
                commit('setLoading', false)
                commit('setError', error.message)
                throw error
                } */
                var val = encrypt(strUserPassword)
                var val1 = encrypt(strKey)
                //console.log(id)
                const newOpti24Settings = new Opti24Settings(
                    ids,
                    strUserName,
                    val,
                    val1,
                    strKontract
                )
                //console.log(newOpti24Settings)
                let dataTr = [
                    {"proc":'updateDictionary_Opti24SettingsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id,
                    "newOpti24Settings": newOpti24Settings}]
                //console.log(dataTr)
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then(() => {
                    //console.log(res)
                    // Send mutation
                    commit('editOpti24Settings', {ids, strUserName, strUserPassword, strKey, strKontract, id})
                    commit('setLoading', false)
                    resolve();
                })
                .catch((response) => {
                    commit('setLoading', false)
                    commit('setError', response.message)
                    reject(response)
                    //throw error
                })
            })
        },
        async newOpti24Settings ({commit}, payload) {
            //console.log('13 ' + getters.UserId)
            commit('clearError')
            commit('setLoading', true)
            
            try {
                // Used helped class
                
                /* const newOpti24Settings = new Opti24Settings(
                    payload.id,
                    payload.strUserName,
                    encrypt(payload.strUserPassword),
                    encrypt(payload.strKey),
                    payload.strKontract
                ) */
                const newOpti24Settings1 = new Opti24Settings(
                    payload.id,
                    payload.strUserName,
                    payload.strUserPassword,
                    payload.strKey,
                    payload.strKontract
                )
                //console.log(newUserSettings)
                // Push newTask
                //const setting = await firebase.database().ref('opti24Settings').push(newOpti24Settings)
        
                // Send mutation
                commit('newOpti24Settings', {
                  ...newOpti24Settings1,
                  key: 0
                })
        
                commit('setLoading', false)
              } catch (error) {
                commit('setLoading', false)
                commit('setError', error.message)
                throw error
              }
        }
    },
    getters: {
        opti24settings (state) {
            return state.opti24settings
        }
    }
}

function encrypt(text){
    const crypto = require("crypto");
    var cipher = crypto.createCipher(algorithm,password)
    var crypted = cipher.update(text,'utf8','hex')
    crypted += cipher.final('hex');
    return crypted;
}


function decrypt(text){
   const crypto = require("crypto");
   var decipher = crypto.createDecipher(algorithm,password)
   var dec = decipher.update(text,'hex','utf8')
   dec += decipher.final('utf8');
   return dec;
}