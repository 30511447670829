<template>
    <div>
    <v-container class="mainGridConteiner" :style="'max-width: 80%'">
        <v-layout justify-center >
            <v-card class="pa-3">
                <v-chip outlined color="primary">
                    <v-icon left>mdi-ballot-recount-outline</v-icon>Подгрузка справочников
                </v-chip>
                <div>
                <p style="padding: 0 15em 0em 0;"></p>
                </div>
                <div>
                    <v-row class="pa-0 ma-0">
                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-text-field class="pa-0 pr-2 ma-0"
                            value="Статусы карт"
                            disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <div class="my-2">
                                <v-btn small color="primary" @click="insertCardStatus()">Загрузить</v-btn>
                            </div>
                        </v-col>
                         <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-alert dense  text   type="success"  dismissible v-model="alert1" class="pa-1 ma-1">
                                Загрузка выполнена!
                            </v-alert>
                        </v-col>

                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-text-field class="pa-0 pr-2 ma-0"
                            value="Список стран"
                            disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <div class="my-2">
                                <v-btn small color="primary" @click="insertCountryList()">Загрузить</v-btn>
                            </div>
                        </v-col>
                         <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-alert dense  text   type="success"  dismissible v-model="alert2" class="pa-1 ma-1">
                                Загрузка выполнена!
                            </v-alert>
                        </v-col>

                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-text-field class="pa-0 pr-2 ma-0"
                            value="Список единиц измерений"
                            disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <div class="my-2">
                                <v-btn small color="primary" @click="insertUnitList()">Загрузить</v-btn>
                            </div>
                        </v-col>
                         <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-alert dense  text   type="success"  dismissible v-model="alert3" class="pa-1 ma-1">
                                Загрузка выполнена!
                            </v-alert>
                        </v-col>

                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-text-field class="pa-0 pr-2 ma-0"
                            value="Список валют"
                            disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <div class="my-2">
                                <v-btn small color="primary" @click="insertCurrencyList()">Загрузить</v-btn>
                            </div>
                        </v-col>
                         <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-alert dense  text   type="success"  dismissible v-model="alert4" class="pa-1 ma-1">
                                Загрузка выполнена!
                            </v-alert>
                        </v-col>

                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-text-field class="pa-0 pr-2 ma-0"
                            value="Список групп продукта"
                            disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <div class="my-2">
                                <v-btn small color="primary" @click="insertProductGroupList()">Загрузить</v-btn>
                            </div>
                        </v-col>
                         <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-alert dense  text   type="success"  dismissible v-model="alert5" class="pa-1 ma-1">
                                Загрузка выполнена!
                            </v-alert>
                        </v-col>

                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-text-field class="pa-0 pr-2 ma-0"
                            value="Список типов продукта"
                            disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <div class="my-2">
                                <v-btn small color="primary" @click="insertProductTypeList()">Загрузить</v-btn>
                            </div>
                        </v-col>
                         <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-alert dense  text   type="success"  dismissible v-model="alert6" class="pa-1 ma-1">
                                Загрузка выполнена!
                            </v-alert>
                        </v-col>

                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-text-field class="pa-0 pr-2 ma-0"
                            value="Список регионов"
                            disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <div class="my-2">
                                <v-btn small color="primary" @click="insertRegionList()">Загрузить</v-btn>
                            </div>
                        </v-col>
                         <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-alert dense  text   type="success"  dismissible v-model="alert7" class="pa-1 ma-1">
                                Загрузка выполнена!
                            </v-alert>
                        </v-col>

                        <!-- <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-text-field class="pa-0 pr-2 ma-0"
                            value="Тест POST"
                            disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <div class="my-2">
                                <v-btn small color="primary" @click="checkPOST()">Test POST</v-btn>
                            </div>
                        </v-col>
                         <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-alert dense  text   type="success"  dismissible v-model="alert9" class="pa-1 ma-1">
                                Загрузка выполнена!
                            </v-alert>
                        </v-col> -->
                        
                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-text-field class="pa-0 pr-2 ma-0"
                            value="Список групп карт"
                            disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <div class="my-2">
                                <v-btn small color="primary" @click="insertCardGroupList()">Загрузить</v-btn>
                            </div>
                        </v-col>
                         <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-alert dense  text   type="success"  dismissible v-model="alert10" class="pa-1 ma-1">
                                Загрузка выполнена!
                            </v-alert>
                        </v-col>

                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-text-field class="pa-0 pr-2 ma-0"
                            value="Список карт"
                            disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <div class="my-2">
                                <v-btn small color="primary" @click="insertCardList()">Загрузить</v-btn>
                            </div>
                        </v-col>
                         <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-alert dense  text   type="success"  dismissible v-model="alert11" class="pa-1 ma-1">
                                Загрузка выполнена!
                            </v-alert>
                        </v-col>

                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-text-field class="pa-0 pr-2 ma-0"
                            value="Список продуктов"
                            disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <div class="my-2">
                                <v-btn small color="primary" @click="insertProductList()">Загрузить</v-btn>
                            </div>
                        </v-col>
                         <v-col cols="12" sm="6" class="pa-0 ma-0" md="4">
                            <v-alert dense  text   type="success"  dismissible v-model="alert12" class="pa-1 ma-1">
                                Загрузка выполнена!
                            </v-alert>
                        </v-col>
                 
                        
                    </v-row>
                </div>
            </v-card>
       </v-layout>
     </v-container>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "catalogForm",
        data() {
            return {
                clientID: this.$store.getters.UserId,
                alert1: false,
                alert2: false,
                alert3: false,
                alert4: false,
                alert5: false,
                alert6: false,
                alert7: false,
                alert8: false,
                alert9: false,
                alert10: false,
                alert11: false,
                alert12: false,
                value: null
            }
        },
        methods: {
            async insertCardStatus() {
                //await this.$store.dispatch('deleteCardStatusDB')
                //.then(async () => {
                    //console.log('1')
                    const setting = {
                        gpnapi: this.$store.state.gpn24,
                        sessionid: this.$store.getters.SessionId,
                        gpnurl: this.$store.state.url24,
                    }
                    await this.$store.dispatch('insertCardStatus', setting)                                                                                           
                    .then(async() => {
                        this.alert1 = true
                        await this.$store.dispatch('loadCardStatusDB')
                    })
                //})
                
            },
            selectCardStatus() {
                this.$store.dispatch('selectCardStatus')
            },
            async insertCountryList() {
                //await this.$store.dispatch('deleteCountryListDB')
                //.then(async () => {
                    //console.log('1')
                    const setting = {
                        gpnapi: this.$store.state.gpn24,
                        sessionid: this.$store.getters.SessionId,
                        gpnurl: this.$store.state.url24,
                    }
                    await this.$store.dispatch('insertCountryList', setting)                                                                                           
                    .then(async() => {
                        this.alert2 = true
                        await this.$store.dispatch('loadCountryListDB')
                    })
                //})
            },
            async insertUnitList() {
                //await this.$store.dispatch('deleteUnitListDB')
                //.then(async () => {
                    //console.log('1')
                    const setting = {
                        gpnapi: this.$store.state.gpn24,
                        sessionid: this.$store.getters.SessionId,
                        gpnurl: this.$store.state.url24,
                    }
                    await this.$store.dispatch('insertUnitList', setting)                                                                                           
                    .then(async () => {
                        this.alert3 = true
                        await this.$store.dispatch('loadUnitListDB')
                    })
                //})
            },
            async insertCurrencyList() {
                //await this.$store.dispatch('deleteCurrencyListDB')
                //.then(async () => {
                    //console.log('1')
                    const setting = {
                        gpnapi: this.$store.state.gpn24,
                        sessionid: this.$store.getters.SessionId,
                        gpnurl: this.$store.state.url24,
                    }
                    await this.$store.dispatch('insertCurrencyList', setting)                                                                                           
                    .then(async () => {
                        this.alert4 = true
                        await this.$store.dispatch('loadCurrencyListDB')
                    })
                //})
            },
            async insertProductGroupList() {
                //await this.$store.dispatch('deleteProductGroupListDB')
                //.then(async () => {
                    //console.log('1')
                    const setting = {
                        gpnapi: this.$store.state.gpn24,
                        sessionid: this.$store.getters.SessionId,
                        gpnurl: this.$store.state.url24,
                    }
                    await this.$store.dispatch('insertProductGroupList', setting)                                                                                           
                    .then(async () => {
                        this.alert5 = true
                        await this.$store.dispatch('loadProductGroupListDB')
                    })
                //})
            },
            async insertProductTypeList() {
                //await this.$store.dispatch('deleteProductTypeListDB')
                //.then(async () => {
                    //console.log('1')
                    const setting = {
                        gpnapi: this.$store.state.gpn24,
                        sessionid: this.$store.getters.SessionId,
                        gpnurl: this.$store.state.url24,
                    }
                    await this.$store.dispatch('insertProductTypeList', setting)                                                                                           
                    .then(async () => {
                        this.alert6 = true
                        await this.$store.dispatch('loadProductTypeListDB')
                    })
                //})
            },
            async insertProductList() {
                /* await this.$store.dispatch('deleteProductListDB')
                .then(async () => {
                    //console.log('1')*/
                    const setting = {
                        gpnapi: this.$store.state.gpn24,
                        sessionid: this.$store.getters.SessionId,
                        gpnurl: this.$store.state.url24,
                    } 
                    await this.$store.dispatch('insertProductList', setting)                                                                                           
                    .then(async () => {
                        this.alert12 = true
                        //await this.$store.dispatch('loadProductListDB')
                    })
                //})
            },
            async insertRegionList() {
                //await this.$store.dispatch('deleteRegionListDB')
                //.then(async () => {
                    //console.log('1')
                    const setting = {
                        gpnapi: this.$store.state.gpn24,
                        sessionid: this.$store.getters.SessionId,
                        gpnurl: this.$store.state.url24,
                    }
                    await this.$store.dispatch('insertRegionList', setting)                                                                                           
                    .then(async () => {
                        this.alert7 = true
                        await this.$store.dispatch('loadRegionListDB')
                    })
                //})
            },
            checkPOST() {
                let data = [
                    {"INN":"4205286037","SUM":92205151.49},
                    {"INN":"4207046591","SUM":139513.44},
                    {"INN":"7702549732","SUM":17149078.56},
                    {"INN":"4205025388","SUM":33273865},
                    {"INN":"4205321620","SUM":35947894.33}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd86',
                        'Content-Type': 'application/json'
                    },
                    url: 'https://api.analyticsnode.ru/apiNode/LoadOpti241CFuncPG',
                    data: data

                })
                .then((response) => {
                    console.log(response)
                })
            },
            async insertCardGroupList() {
                //await this.$store.dispatch('deleteCardGroup')
                //.then(async () => {
                    //console.log('1')
                    const setting = {
                        gpnapi: this.$store.state.gpn24,
                        sessionid: this.$store.getters.SessionId,
                        contractid: this.$store.getters.LastContract,
                        gpnurl: this.$store.state.url24,
                    }
                    await this.$store.dispatch('insertCardGroup', setting)                                                                                           
                    .then(async () => {
                        this.alert10 = true
                        await this.$store.dispatch('loadCardGroupDB')
                    })
                //})
            },
            async insertCardList() {
                //await this.$store.dispatch('deleteCardList')
                //.then(async () => {
                    //console.log('1')
                    const setting = {
                        gpnapi: this.$store.state.gpn24,
                        sessionid: this.$store.getters.SessionId,
                        contractid: this.$store.getters.LastContract,
                        gpnurl: this.$store.state.url24,
                    }
                    await this.$store.dispatch('insertCardList', setting)                                                                                           
                    .then(async () => {
                        this.alert11 = true
                        //await this.$store.dispatch('loadCardListDB')
                    })
                //})
            }
            
        },
        mounted() {

        },
        components:{
            
        },
        computed: {
            
        }
    }
</script>


