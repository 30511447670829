export default class Opti24Settings {
    constructor (
      id,
      strUserName,
      strUserPassword,
      strKey,
      strKontract,
      key = null
    ) {
      this.id = id
      this.strUserName = strUserName
      this.strUserPassword = strUserPassword
      this.strKey = strKey
      this.strKontract = strKontract
      this.key = key
    }
  }