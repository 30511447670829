import axios from 'axios'
import date from 'date-and-time';

import router from '../router/router.js'
import RegionList from './regionList_help'
import store from './store.js'

export default {
    state: {
        regionList: [],
    },
    mutations: {
        loadRegionList (state, payload) {
            state.regionList = payload
        },
    },
    actions: {
        async loadRegionList ({commit}, { gpnapi, sessionid, gpnurl }) {
            commit('clearError')
            commit('setLoading', true)

            const now = new Date();
            const dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
            
            axios.get(gpnurl + 'vip/v1/getDictionary?name=Region', {
                headers: {
                    'api_key': gpnapi,
                    'session_id': sessionid,
                    'date_time': dt
                }
            })
            .then((response) => {
                console.log(response.data.data.result)
                commit('loadRegionList', response.data.data.result)
                commit('setLoading', false)
            })
            .catch((response) => {
                var err = Object.assign({}, response)
                console.log('error', err.response);

                commit('loadRegionList', [])
                commit('setLoading', false)
                commit('setError', response)

                if(err.response.status == '403')
                {
                    commit('setUser', null)
                    router.push('/')
                }
                if(err.response.status == '401')
                {
                    commit('setUser', null)
                    router.push('/')
                }
            });

            

        },
        async loadRegionListDB ({commit}) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)

                let dataTr = [
                    {"proc":'loadDictionary_RegionPG', "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    //const region = await firebase.database().ref('regionList').once('value')
                    const regions = res.data
                    
                    const regionsArray = []
                    Object.keys(regions).forEach(key => {
                        const s = regions[key]
                        regionsArray.push(
                            new RegionList(
                                s.id,
                                s.name,
                                s.country_id,
                                s.type,
                                s.last_update,
                                s.udt_flag
                            )
                        )
                    })
                    //console.log(regionsArray)
                    commit('loadRegionList', regionsArray)
                    commit('setLoading', false)
                    resolve();
                })
                .catch((response) => {
                    var err = Object.assign({}, response)
                    console.log('error', err.response);

                    commit('loadRegionList', [])
                    commit('setLoading', false)
                    commit('setError', response)
                    if(err.response.status == '400')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '403')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '401')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    reject(response)
                });

            })

        },
        async deleteRegionListDB ({commit}) {
            return new Promise((resolve) => {
                commit('clearError')
                commit('setLoading', false)
                    resolve();
                /* firebase.database().ref('regionList').remove()
                .then(() => {
                    // http success, call the mutator and change something in state
                    commit('setLoading', false)
                    resolve();  // Let the calling function know that http is done. You may send some data back
                }, error => {
                    // http failed, let the calling function know that action did not work out
                    commit('setLoading', false)
                    commit('setError', error)
                    reject(error);
                }) */
            })

            

        },
        async insertRegionList ({commit}, { gpnapi, sessionid, gpnurl }) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)

                const now = new Date();
                const dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
                
                /* axios.get(gpnurl + 'vip/v1/getDictionary?name=Region', {
                    headers: {
                        'api_key': gpnapi,
                        'session_id': sessionid,
                        'date_time': dt
                    }
                }) */
                let dataTr = [
                    {"proc":'getDictionary_RegionPG', "gpnapi":gpnapi, "dt":dt, "gpnurl":gpnurl, "sessionid":sessionid, "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then(() => {
                    //console.log(response.data.data.result)
                    
                    /* const regionListGet = response.data.data.result
                    //const cardSatatusEmpty = []
                    //firebase.database().ref('cardStatus').remove()
                    Object.keys(regionListGet).forEach(key => {
                        const s = regionListGet[key]
                        if(s.id == 65) {
                            console.log(s.name)
                        }
                        if(s.country_id == 'BLR' || s.country_id == 'KAZ' || s.country_id == 'RUS' || s.country_id == 'UKR')
                        firebase.database().ref('regionList').push(s)
                    }) */
                    //firebase.database().ref('cardStatus').push(response.data.data.result)
                    //commit('loadCardStatus', response.data.data.result)
                    commit('setLoading', false)
                    resolve()
                })
                .catch((response) => {
                    var err = Object.assign({}, response)
                    console.log('error', err.response);

                    //commit('loadCardStatus', [])
                    commit('setLoading', false)
                    commit('setError', response)
                    if(err.response.status == '400')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '403')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '401')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    reject(response)
                });

            })

        },
    },
    getters: {
        RegionList (state) {
            return state.regionList
        },
        RegionListByCountry (state) {
            //console.log(getters.groupSettings)
            return countryid => state.regionList.filter(name => {
                return name.country_id == countryid
            })
            
        },
    }
}