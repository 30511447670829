import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'

//import mainScreen from './views/mainScreen.vue'
import loginForm from '../views/loginForm.vue'
import mainScreen from '../views/mainScreen.vue'
import adminForm from '../views/adminForm.vue'
//import sample from '../views/sample.vue'
import cardListForm from '../views/cardListForm.vue'
import detailInfo from '../views/detailInfo.vue'
import catalogForm from '../views/catalogForm.vue'
import userChangePass from '../views/userChangePass.vue'
import analitikaForm from '../views/analitikaForm.vue'

Vue.use(Router)

export default new Router({
  //mode: 'history',
  routes: [
      {
        path: '/',
        name: 'loginForm',
        component: loginForm
      },
      {
          path: '/mainScreen',
          name: 'mainScreen',
          component: mainScreen,
          beforeEnter (to, from, next) {
            //console.log('11 ' + store.getters.checkUser)
            store.getters.checkUser ? next() : next('/')
          },
          children: [
              { 
                  path: 'adminForm',
                  component: adminForm,
                  beforeEnter (to, from, next) {
                    //console.log('12 ' + store.getters.checkUser)
                    store.getters.checkUser ? next() : next('/')
                  },
              },
              { 
                  path: 'cardListForm',
                  component: cardListForm,
                  beforeEnter (to, from, next) {
                    //console.log('13 ' + store.getters.checkUser)
                    store.getters.checkUser ? next() : next('/')
                  },
              },
              { 
                  path: 'detailInfo',
                  component: detailInfo,
                  beforeEnter (to, from, next) {
                    //console.log('13 ' + store.getters.checkUser)
                    store.getters.checkUser ? next() : next('/')
                  },
              },
              { 
                  path: 'catalogForm',
                  component: catalogForm,
                  beforeEnter (to, from, next) {
                    //console.log('13 ' + store.getters.checkUser)
                    store.getters.checkUser ? next() : next('/')
                  },
              },
              { 
                  path: 'userChangePass',
                  component: userChangePass,
                  beforeEnter (to, from, next) {
                    //console.log('13 ' + store.getters.checkUser)
                    store.getters.checkUser ? next() : next('/')
                  },
              },
              { 
                  path: 'analitikaForm',
                  component: analitikaForm,
                  beforeEnter (to, from, next) {
                    //console.log('13 ' + store.getters.checkUser)
                    store.getters.checkUser ? next() : next('/')
                  },
              }
          ]
      }
  ]
})

