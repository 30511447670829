export default class CardsRegionLimits {
    constructor (
        id,
        card_id,
        group_id,
        contract_id,
        country,
        region,
        service_center,
        date,
        limit_type,
        RestStr,
        country_name,
        region_name,
        azs_name
    ) {
      this.id = id
      this.card_id = card_id
      this.group_id = group_id
      this.contract_id = contract_id
      this.country = country
      this.region = region
      this.service_centerid = service_center
      this.date = date
      this.limit_type = limit_type
      this.RestStr = RestStr
      this.country_name = country_name
      this.region_name = region_name
      this.azs_name = azs_name
    }
  }