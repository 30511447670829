<template>
    
    <v-tabs  centered>
    
    <v-tab href="#tab-1" class="vtabsvuetyfy">
        Настройка клиентов
    </v-tab>
    <v-tab href="#tab-2" class="vtabsvuetyfy">
        Настройка доступа к OPTI24
    </v-tab>
    
    
    <v-tab-item value="tab-1">
    <div>
        
        <dxDataGrid
                :PageSize = "5"
                :ShowPageSizeSelector = "true"
                :AllowedPageSizes = "[ 5, 10, 20 ]"
                :ColumnSettingsIN = "[
                        { colDataField: 'strINN', colCaption: 'ИНН', sortOrder: 'asc' },
                        { colDataField: 'strShortName', colCaption: 'Нименование', sortOrder: undefined },
                        { colDataField: 'boolIsAdmin', colCaption: 'Админ', sortOrder: undefined },
                        { colDataField: 'strCardGroupName', colCaption: 'Группа Карт', sortOrder: undefined },
                        { colDataField: 'numProcPrice', colCaption: '% +', sortOrder: undefined, visible: false },
                        { colDataField: 'user', colCaption: 'Пользователь', sortOrder: undefined, visible: false },
                        { colDataField: 'id', colCaption: 'ИД', sortOrder: undefined, visible: false }

                ]"

                HeaderText = "Предприятия"
                HeaderIcon = "location_city"
                :EditedItem = "{ strINN: '', strShortName: '', boolIsAdmin: '', strCardGroupName: '', numProcPrice: ''  }"
                :DefaultItem = "{ strINN: '', strShortName: '', boolIsAdmin: '', strCardGroupName: '', numProcPrice: '' }"
                :AddFields = "[
                                    { model: 'strEmail', label: 'Имя пользователя для входа', readonly: false, typeField: 'email', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' },
                                    { model: 'strPassword', label: 'Пароль пользователя', readonly: false, typeField: 'text', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' },
                                    { model: 'strINN', label: 'ИНН', readonly: false, typeField: 'number', dialog: 'Добавить, Редактировать', sizeField: 'xs6', counterField: '12', maskField: '############',
                                    rulesField: [
                                        (val) => val.length >= 10 || '10 символов для физ лица, 12 символов для юр лица'
                                        ] },
                                    { model: 'strShortName', label: 'Наименование', readonly: false, typeField: 'text', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' },
                                    { model: 'boolIsAdmin',  label: 'Администратор', readonly: false, typeField: 'check', dialog: 'Добавить, Редактировать', sizeField: 'xs12' },
                                    { model: 'strCardGroupName',  label: 'Группа карт', readonly: false, typeField: 'text', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' },
                                    
                                ]"
                :EditedFields = "[
                                    { model: 'strINN', label: 'ИНН', readonly: false, typeField: 'number', dialog: 'Добавить, Редактировать', sizeField: 'xs6', counterField: '12', maskField: '############',
                                    rulesField: [
                                        (val) => val.length >= 10 || '10 символов для физ лица, 12 символов для юр лица'
                                        ] },
                                    { model: 'strShortName', label: 'Наименование', readonly: false, typeField: 'text', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' },
                                    { model: 'boolIsAdmin',  label: 'Администратор', readonly: false, typeField: 'check', dialog: 'Добавить, Редактировать', sizeField: 'xs12' },
                                    { model: 'strCardGroupName',  label: 'Группа карт', readonly: false, typeField: 'text', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' },
                                    
                                ]"
                EndPoint="/contragent"
                :ParentParams="{ client: this.clientID }"
                :GroupPanelVisible = "false"
                :HeaderFilterVisible = "false"
                :FilterRowVisible = "false"
                :SearchVisible = "true"
                :SearchWidth = "300"
                :SummaryVisible = "false"
                :BtnRefresh="true"
                :BtnAdd="true"
                :BtnEdit="true"
                :BtnDelete="true"
                :BtnShipper="false"
                :MaxWidth="800"
                :MaxGridWidth="'max-width: 80%'"
        ></dxDataGrid>
        <v-tabs  centered>
            <v-tab href="#tab-3" class="vtabsvuetyfy">
                Таблица процентов
            </v-tab>
            <v-tab href="#tab-4" class="vtabsvuetyfy">
                Таблица фикс. цен
            </v-tab>
            <v-tab-item value="tab-3">
                <div>
                    <dxDataGridP
                            :PageSize = "5"
                            :ShowPageSizeSelector = "true"
                            :AllowedPageSizes = "[ 5, 10, 20 ]"
                            :ColumnSettingsIN = "[
                                    { colDataField: 'IDSettings', colCaption: 'ID', sortOrder: undefined, visible: false, datatype: '', format: '' },
                                    { colDataField: 'numProcPrice', colCaption: '%', sortOrder: undefined, datatype: '', format: '' },
                                    { colDataField: 'dtDateStart', colCaption: 'Начало действия', sortOrder: undefined, datatype: 'date', format: 'dd.MM.yyyy' },
                                    { colDataField: 'dtDateEnd', colCaption: 'Конец действия', sortOrder: 'asc', datatype: 'date', format: 'dd.MM.yyyy' }

                            ]"

                            HeaderText = "Табл. процентов"
                            HeaderIcon = "mdi-sack-percent"
                            :EditedItem = "{ numProcPrice: '', dtDateStart: '', dtDateEnd: '' }"
                            :DefaultItem = "{ numProcPrice: '', dtDateStart: '', dtDateEnd: '' }"
                            :EditedFields = "[
                                                { model: 'dtDateStart', label: 'Начало действия', readonly: false, typeField: 'date1', dialog: 'Добавить, Редактировать', sizeField: 'xs12' },
                                                { model: 'dtDateEnd', label: 'Конец действия', readonly: false, typeField: 'date2', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' },
                                                { model: 'boolDateEndFull',  label: 'Без срока действия', readonly: false, typeField: 'check', dialog: 'Добавить, Редактировать', sizeField: 'xs12' },
                                                { model: 'numProcPrice',  label: 'Процент наценки (5, 10 и т.п.)', readonly: false, typeField: 'number', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' }
                                            ]"
                            :AddFields = "[
                                                { model: 'dtDateStart', label: 'Начало действия', readonly: false, typeField: 'date1', dialog: 'Добавить, Редактировать', sizeField: 'xs12' },
                                                { model: 'dtDateEnd', label: 'Конец действия', readonly: false, typeField: 'date2', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' },
                                                { model: 'boolDateEndFull',  label: 'Без срока действия', readonly: false, typeField: 'check', dialog: 'Добавить, Редактировать', sizeField: 'xs12' },
                                                { model: 'numProcPrice',  label: 'Процент наценки (5, 10 и т.п.)', readonly: false, typeField: 'number', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' }
                                            ]"
                            EndPoint="/contragent"
                            :ParentParams="{ client: this.clientID }"
                            :GroupPanelVisible = "false"
                            :HeaderFilterVisible = "false"
                            :FilterRowVisible = "false"
                            :SearchVisible = "true"
                            :SearchWidth = "160"
                            :SummaryVisible = "false"
                            :BtnRefresh="true"
                            :BtnAdd="true"
                            :BtnEdit="true"
                            :BtnDelete="true"
                            :BtnShipper="false"
                            :MaxWidth="500"
                            :MaxGridWidth="'max-width: 80%'"
                    ></dxDataGridP>
                </div>
            </v-tab-item>
            <v-tab-item value="tab-4">
                <div>
                    <dxDataGridFC
                            :PageSize = "5"
                            :ShowPageSizeSelector = "true"
                            :AllowedPageSizes = "[ 5, 10, 20 ]"
                            :ColumnSettingsIN = "[
                                    { colDataField: 'IDSettings', colCaption: 'ID', sortOrder: undefined, visible: false, datatype: '', format: '' },
                                    { colDataField: 'strName', colCaption: 'Наименование', sortOrder: undefined, datatype: '', format: '' },
                                    { colDataField: 'numPrice', colCaption: 'Цена', sortOrder: undefined, datatype: 'number', format: '' },
                                    { colDataField: 'dtDateStart', colCaption: 'Начало действия', sortOrder: undefined, datatype: 'date', format: 'dd.MM.yyyy' },
                                    { colDataField: 'dtDateEnd', colCaption: 'Конец действия', sortOrder: 'asc', datatype: 'date', format: 'dd.MM.yyyy' }

                            ]"

                            HeaderText = "Табл. стоимости"
                            HeaderIcon = "mdi-sack-percent"
                            :EditedItem = "{ strName: '', numPrice: '', dtDateStart: '', dtDateEnd: '' }"
                            :DefaultItem = "{ strName: '', numPrice: '', dtDateStart: '', dtDateEnd: '' }"
                            :EditedFields = "[
                                                { model: 'dtDateStart', label: 'Начало действия', readonly: false, typeField: 'date1', dialog: 'Добавить, Редактировать', sizeField: 'xs12' },
                                                { model: 'dtDateEnd', label: 'Конец действия', readonly: false, typeField: 'date2', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' },
                                                { model: 'boolDateEndFull',  label: 'Без срока действия', readonly: false, typeField: 'check', dialog: 'Добавить, Редактировать', sizeField: 'xs12' },
                                                { model: 'numPrice', label: 'Цена', readonly: false, typeField: 'number', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' }
                                            ]"
                            :AddFields = "[
                                                { model: 'dtDateStart', label: 'Начало действия', readonly: false, typeField: 'date1', dialog: 'Добавить, Редактировать', sizeField: 'xs12' },
                                                { model: 'dtDateEnd', label: 'Конец действия', readonly: false, typeField: 'date2', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' },
                                                { model: 'boolDateEndFull',  label: 'Без срока действия', readonly: false, typeField: 'check', dialog: 'Добавить, Редактировать', sizeField: 'xs12' },
                                                { model: 'numPrice', label: 'Цена', readonly: false, typeField: 'number', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' }
                                            ]"
                            EndPoint="/contragent"
                            :ParentParams="{ client: this.clientID }"
                            :GroupPanelVisible = "false"
                            :HeaderFilterVisible = "false"
                            :FilterRowVisible = "false"
                            :SearchVisible = "true"
                            :SearchWidth = "160"
                            :SummaryVisible = "false"
                            :BtnRefresh="true"
                            :BtnAdd="true"
                            :BtnEdit="true"
                            :BtnDelete="true"
                            :BtnShipper="false"
                            :MaxWidth="500"
                            :MaxGridWidth="'max-width: 80%'"
                    ></dxDataGridFC>
                    
                </div>
            </v-tab-item>
            
        </v-tabs>
        <div class="text-center pa-2">
                <v-sheet color="teal lighten white--text">Внимание! Таблица фиксированные цены, имеет более высокий приоритет!</v-sheet>
            </div>
    </div>
    </v-tab-item>
    <v-tab-item value="tab-2">
        <v-container class="mainGridConteiner" :style="'max-width: 850px'">
        <v-layout justify-center >
            <v-card class="pa-3">
                <v-chip outlined color="primary">
                    <v-icon left>mdi-ballot-recount-outline</v-icon>Настройка доступа к API Opti24
                </v-chip>
                <div>
                <p style="padding: 0 15em 0em 0;"></p>
                </div>
                <br/>
                <div>
                    <v-text-field class="pa-0 pr-2 ma-0"
                            label="Имя пользователя"
                            v-model="Opti24Name"
                    ></v-text-field>
                </div>
                <div>
                    <v-text-field class="pa-0 pr-2 ma-0"
                            label="Пароль"
                            v-model="Opti24Password"
                    ></v-text-field>
                </div>
                <div>
                    <v-text-field class="pa-0 pr-2 ma-0"
                            label="API KEY"
                            v-model="Opti24Key"
                    ></v-text-field>
                </div>
                <div>
                    <v-select class="pr-2 ma-0"
                        v-model="selectedItemC"
                        :items="ContractReturn"
                        item-text="number"
                        item-value="id"
                        label="Контракт"
                        :value = "selectedItemC"
                        no-data-text="Нет данных для отображения"
                    ></v-select>  
                </div>
                <div>
                    <v-btn small color="primary" @click="CheckOpti24Connection()" class="mr-2" :disabled="disabled == 1">Выполнить проверку подключения</v-btn>
                    <v-progress-circular 
                        indeterminate
                        color="primary"
                        v-if="load1"
                    ></v-progress-circular>
                </div>
                <br/>
                <div>
                    <v-btn small color="primary" @click="UpdateOpti24Info()" class="mr-2" :disabled="disabled1 == 1">Сохранить данные</v-btn>
                    
                    <v-progress-circular 
                        indeterminate
                        color="primary"
                        v-if="load2"
                    ></v-progress-circular>
                </div>
                <br/>
                <div>
                    <v-alert dense  text   type="success"  dismissible v-model="alert1" class="pa-1 ma-1">
                                Проверка подключения выполнена!
                    </v-alert>
                    <v-alert dense  text   type="error"  dismissible v-model="alert2" class="pa-1 ma-1">
                                Ошибка проверки подключения!
                    </v-alert>
                    <v-alert dense  text   type="success"  dismissible v-model="alert3" class="pa-1 ma-1">
                                Сохранение выполнено!
                    </v-alert>
                    <v-alert dense  text   type="error"  dismissible v-model="alert4" class="pa-1 ma-1">
                                Не выбран Контракт!
                    </v-alert>
                    <div class="text-center pa-2">
                        <v-sheet color="teal lighten white--text">Внимание! Для корректной работы, после изменения текущих настроек, требуется перезайти в Личный кабинет!</v-sheet>
                    </div>
                </div>
            </v-card>
        </v-layout>
        </v-container>
    </v-tab-item>
    </v-tabs>
</template>

<script>
import dxDataGrid from '@/components/dxDataGridF.vue'
import dxDataGridP from '@/components/dxDataGridProcF.vue'
import dxDataGridFC from '@/components/dxDataGridProductF.vue'
import axios from 'axios'
import * as sha512 from 'js-sha512'
import date from 'date-and-time';

import router from '../router/router.js'

    export default {
        name: "contragentsForm",
        data() {
            return {
                clientID: this.$store.getters.UserId,
                alert1: false,
                alert2: false,
                alert3: false,
                alert4: false,
                Opti24Name: '',
                Opti24Password: '',
                Opti24Key: '',
                Opti24Kontract: '',
                disabled: 0,
                disabled1: 0,
                load1: false,
                load2: false,
                selectedItemC: null,
                selectedContract: ''
            }
        },
        watch: {
            selectedItemC(){
                this.selectedContract = this.selectedItemC
                //console.log(this.selectedContract);
            }
        },
        computed: {
            ContractReturn() {
                //console.log(this.$store.getters.ContractsList);
                return this.$store.getters.ContractsList
            }
        },
        beforeMount() {
            var opti24settings = this.$store.getters.opti24settings
            if(opti24settings != null){
                if(opti24settings.length > 0) { 
                    this.Opti24Name = opti24settings[0].strUserName
                    this.Opti24Password = opti24settings[0].strUserPassword
                    this.Opti24Key = opti24settings[0].strKey
                    //this.Opti24Kontract = opti24settings[0].strKontract
                    this.selectedItemC = opti24settings[0].strKontract
                }
            }
        },
        mounted() {

        },
        components:{
            dxDataGrid,
            dxDataGridP,
            dxDataGridFC
        },
        methods: {
            async CheckOpti24Connection() {
                this.alert1 = false
                this.alert2 = false

                this.disabled = 1
                this.load1 = true

                var sha = sha512.sha512(this.Opti24Password);
                const data = "login=" + this.Opti24Name + "&password=" + sha
                const now = new Date();
                this.dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
                
                let dataTr = [
                    {"proc":'authUser', "method":'POST', "apikey":this.Opti24Key, "date_time":this.dt, "url":this.$store.state.url24tr + 'vip/v1/authUser', "data":data, "uid": this.$store.getters.userUID, "uid2": this.$store.getters.user.id}]
                await axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: this.$store.state.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then(async (response) => {
                    this.$store.commit('SetSessionId', response.data.data.session_id)
                    this.$store.commit('SetLastContract', this.$store.state.contractId)
                    this.$store.commit('SetContractsList', response.data.data.contracts)

                    this.alert1 = true
                    this.disabled = 0
                    this.load1 = false
                })
                .catch((response) => {
                    this.$store.commit('SetSessionId', '')
                    this.$store.commit('SetLastContract', '')
                    //handle error
                    console.log('error', response);
                    console.log('errorType', typeof response);
                    console.log('error', Object.assign({}, response));
                    console.log('getOwnPropertyNames', Object.getOwnPropertyNames(response));
                    console.log('stackProperty', Object.getOwnPropertyDescriptor(response, 'stack'));
                    console.log('messageProperty', Object.getOwnPropertyDescriptor(response, 'message'));
                    //console.log('stackEnumerable', response.propertyIsEnumerable('stack'));
                    //console.log('messageEnumerable', response.propertyIsEnumerable('message'));
                    //console.log(response.response.data.error);
                    this.errorMsg = 'Ошибка подключения к Опти24!'
                    this.alert2 = true
                    //this.errorMsg = response.response.data.error
                    this.disabled = 0
                    this.disabled = 0
                    this.load1 = false
                });
            },
            async UpdateOpti24Info() {
                //console.log(this.$store.state.contractId)

                if(this.selectedItemC == '') {
                    this.alert4 = true
                    return
                }
                this.disabled1 = 1
                this.load2 = true
                await this.$store.dispatch('loadOpti24Settings')
                .then(async () => {
                    var opti24settings = this.$store.getters.opti24settings

                    this.alert1 = false
                    this.alert2 = false
                    this.alert3 = false
                    this.alert4 = false

                    var sha = sha512.sha512(this.Opti24Password);
                    const data = "login=" + this.Opti24Name + "&password=" + sha
                    const now = new Date();
                    this.dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
                    
                    let dataTr = [
                        {"proc":'authUser', "method":'POST', "apikey":this.Opti24Key, "date_time":this.dt, "url":this.$store.state.url24tr + 'vip/v1/authUser', "data":data, "uid": this.$store.getters.userUID, "uid2": this.$store.getters.user.id}]
                    await axios({
                        method: 'POST',
                        headers: {
                            'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                            'Content-Type': 'application/json'
                        },
                        url: this.$store.state.url24sr,
                        //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                        data: dataTr

                    })
                    .then(async (response) => {
                        if(opti24settings.length > 0) {
                            //console.log(this.$store.getters.opti24settings)
                            const setting = {
                                ids: this.$store.getters.opti24settings[0].id,
                                strUserName: this.Opti24Name,
                                strUserPassword: this.Opti24Password,
                                strKey: this.Opti24Key,
                                strKontract: this.selectedContract,
                                id: this.$store.getters.opti24settings[0].key
                                
                            }
                            //console.log(setting) 
                            await this.$store.dispatch('editOpti24Settings', setting)
                            .then(() => {
                                //console.log('OK')
                                this.alert3 = true
                                this.$store.commit('SetAlertOpti24False', false)
                                this.$store.commit('Setuser24', opti24settings[0].strUserName)
                                this.$store.commit('Setpass24', opti24settings[0].strUserPassword)
                                this.$store.commit('Setgpn24', opti24settings[0].strKey)
                                this.$store.commit('SetContractId', opti24settings[0].strKontract)

                                this.$store.commit('SetSessionId', response.data.data.session_id)
                                this.$store.commit('SetLastContract', this.$store.state.contractId)
                                this.$store.commit('SetContractsList', response.data.data.contracts)

                                //console.log(this.$store.state.contractId)
                                //console.log(this.$store.getters.opti24settings)
                                //this.refreshData()
                            }, error => {
                                //console.log('Error')
                                var errorRu = error.message
                                console.log(errorRu)
                            })
                        }
                        if(opti24settings.length == 0) {
                            const crypto = require("crypto");
                            const id = crypto.randomBytes(24).toString("hex");
                            const setting = {
                                id: id,
                                strUserName: this.Opti24Name,
                                strUserPassword: this.Opti24Password,
                                strKey: this.Opti24Key,
                                strKontract: this.selectedContract
                                
                            }
                            await this.$store.dispatch('newOpti24Settings', setting)
                            .then(() => {
                                //console.log('OK')
                                this.alert3 = true
                                this.$store.commit('SetAlertOpti24False', false)
                                this.$store.commit('Setuser24', opti24settings[0].strUserName)
                                this.$store.commit('Setpass24', opti24settings[0].strUserPassword)
                                this.$store.commit('Setgpn24', opti24settings[0].strKey)
                                this.$store.commit('SetContractId', opti24settings[0].strKontract)

                                this.$store.commit('SetSessionId', response.data.data.session_id)
                                this.$store.commit('SetLastContract', this.$store.state.contractId)
                                this.$store.commit('SetContractsList', response.data.data.contracts)

                                //console.log(this.$store.getters.opti24settings)
                                //this.refreshData()
                            }, error => {
                                //console.log('Error')
                                var errorRu = error.message
                                console.log(errorRu)
                            })
                        }
                        
                        this.alert1 = true
                        this.disabled1 = 0
                        this.load2 = false
                    })
                    .catch((response) => {
                        this.$store.commit('SetSessionId', '')
                        this.$store.commit('SetLastContract', '')
                        //handle error
                        console.log('error', response);
                        console.log('errorType', typeof response);
                        console.log('error', Object.assign({}, response));
                        console.log('getOwnPropertyNames', Object.getOwnPropertyNames(response));
                        console.log('stackProperty', Object.getOwnPropertyDescriptor(response, 'stack'));
                        console.log('messageProperty', Object.getOwnPropertyDescriptor(response, 'message'));
                        //console.log('stackEnumerable', response.propertyIsEnumerable('stack'));
                        //console.log('messageEnumerable', response.propertyIsEnumerable('message'));
                        //console.log(response.response.data.error);
                        this.errorMsg = 'Ошибка подключения к Опти24!'
                        this.alert2 = true
                        //this.errorMsg = response.response.data.error
                        this.disabled = 0
                        this.disabled1 = 0
                        this.load2 = false
                        //console.log(response.response.data.code)
                        if(response.response.data.code == 'auth/id-token-expired')
                        {
                            //commit('setUser', null)
                            router.push('/')
                        }
                    });
                })
            }
            
        }
    }
</script>


